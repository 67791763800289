import { useState, useEffect } from "react";
import { Auth, Hub } from "aws-amplify";
import * as AmazonCognitoIdentity from "amazon-cognito-identity-js";
import { Link } from "react-router-dom";

const getIsVerified = () => {
  const poolData = {
    UserPoolId: "eu-west-2_YV0bvToRp",
    ClientId: "3i3qcs3app8m1t89m1iniavpbu",
    // UserPoolId: "eu-west-2_UXJYfjlKr",
    // ClientId: "5k305rgnr1t5g9btchop69qfv8",
  };
  const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);

  const cognitoUser = userPool.getCurrentUser();

  // console.log(cognitoUser);

  // Code to Check if the user is verified

  for (var key in cognitoUser?.storage) {
    var tmp = key.split(".");
    var flag = 0;
    if (tmp[tmp.length - 1] !== "userData") continue;
    var res = JSON.parse(cognitoUser?.storage[key]);
    for (var i = 0; i < res?.UserAttributes?.length; i++) {
      if (res?.UserAttributes[i]["Name"] === "custom:isVerified") {
        // console.log("is ", res.UserAttributes[i]["Value"]);
        if (res?.UserAttributes[i]["Value"] === "true") {
          return true;
        }
      }
    }
  }

  return false;
};

export default getIsVerified;
