import React from "react";
import { useState, useCallback } from "react";
import a from "./a.png";
import b from "./b.png";
import c from "./c.png";
import d from "./d.png";
import e from "./e.png";
import f from "./f.png";
import g from "./g.png";
import h from "./h.png";
import i from "./i.png";
import j from "./j.png";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const How_It_Works = () => {
  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);
  const [isOpen4, setIsOpen4] = useState(false);
  const [isOpen5, setIsOpen5] = useState(false);
  const [isOpen6, setIsOpen6] = useState(false);
  const [isOpen7, setIsOpen7] = useState(false);

  return (
    <div className="pt-32 md:pt-36 pb-40 p-2 ">
      <div className="flex flex-col items-start md:max-w-xl mx-auto gap-2 ">
        {/* top section */}
        <div className="flex flex-col items-center gap-2 mb-2">
          <h3 className="">
            Frequently <span className="font-style: italic">Answered </span>
            Questions{" "}
          </h3>{" "}
          <p className="text-xs text-justify  ">
            We make subscription sharing simple and fast. To help you get on
            with this app quickly, here are some FAQs we prepared. In case you
            don't find the answer to a question you have, write to us using the
            support form.{" "}
          </p>
        </div>
        {/* firstItem */}
        <div className="w-full mb-2">
          <div className="flex items-start gap-4">
            <button onClick={() => setIsOpen1(!isOpen1)} className=" w-6 h-6">
              {isOpen1 ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  className="w-6 h-6"
                >
                  <path d="M5 11V13H19V11H5Z" fill="#ffcb00"></path>
                </svg>
              ) : (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path
                    d="M11 11V7H13V11H17V13H13V17H11V13H7V11H11ZM12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20Z"
                    fill="#ffcb00"
                  ></path>
                </svg>
              )}
            </button>
            <h3
              className={`  max-w-3/4 ${
                isOpen1 ? "text-gray-900" : "text-gray-500"
              }`}
            >
              What is the 'Home' section?
            </h3>
          </div>
          {isOpen1 && (
            <div className="flex flex-col items-start gap-3">
              <img
                alt=""
                src={a}
                className="w-52 md:max-w-md    mx-auto  border border-gray-400"
              ></img>
              <p className="text-xs md:w-3/5   ">
                A feed of all the subscriptions offered by our community that
                are available for uptake.{" "}
              </p>
            </div>
          )}
        </div>
        {/* second item */}
        <div className="w-full mb-2">
          <div className="flex items-start gap-4">
            <button onClick={() => setIsOpen2(!isOpen2)} className=" w-6 h-6">
              {isOpen2 ? (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path d="M5 11V13H19V11H5Z" fill="#ffcb00"></path>
                </svg>
              ) : (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path
                    d="M11 11V7H13V11H17V13H13V17H11V13H7V11H11ZM12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20Z"
                    fill="#ffcb00"
                  ></path>
                </svg>
              )}
            </button>
            <h3 className={`${isOpen2 ? "text-gray-900" : "text-gray-500"}`}>
              Can I share a tile with others?{" "}
            </h3>
          </div>
          {isOpen2 && (
            <div className="flex flex-col items-start gap-3">
              <p className="text-xs ">
                Absolutely. Just slick on ‘share’ and a link will be generated
                for the tile. You can easily share it with friends on whatsapp
                or email.{" "}
              </p>
              <img
                alt=""
                src={j}
                className=" w-52 md:max-w-md    mx-auto border border-gray-400"
              ></img>
              <p className="text-xs   ">
                They will need to sign-up to be able to complete the
                transaction. So don’t forget to share your referral code with
                them
              </p>
            </div>
          )}
        </div>
        {/* third item */}
        <div className="w-full mb-2">
          <div className="flex items-start gap-4">
            <button onClick={() => setIsOpen3(!isOpen3)} className=" w-6 h-6">
              {isOpen3 ? (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path d="M5 11V13H19V11H5Z" fill="#ffcb00"></path>
                </svg>
              ) : (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path
                    d="M11 11V7H13V11H17V13H13V17H11V13H7V11H11ZM12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20Z"
                    fill="#ffcb00"
                  ></path>
                </svg>
              )}
            </button>
            <h3 className={`${isOpen3 ? "text-gray-900" : "text-gray-500"}`}>
              How can I offer my subscription to others?
            </h3>
          </div>
          {isOpen3 && (
            <div className="flex flex-col items-start gap-3">
              <p className="text-xs   ">
                Click on 'Add New' and provide the details of the subscription
                you want to offer. Once you 'post' it, a new tile will appear on
                the feed which will show the subscription you have offered. The
                same can also be found in the 'Manage' area of your account.{" "}
              </p>
              <img
                alt=""
                src={d}
                className="w-52 md:max-w-md   mx-auto border border-gray-400"
              ></img>
              <p className="text-xs  ">
                Once someone subscribes to your tile, it will disappear from the
                main feed and you will receive an email. A credit will be added
                to your account which will be the price of your subscription
                less a small fee for our service.{" "}
              </p>
              <img
                alt=""
                src={e}
                className="w-52 md:max-w-md    mx-auto border border-gray-400"
              ></img>
              <p className="text-xs  flex justify-center  ">
                You will then need to go to the 'Shared' section of the 'Manage'
                page to add credentials (username and password) to the tile.
                This will then be shared with the subscriber. We care about your
                privacy and therefore we use encryption to ensure that the
                credentials are only visible to your subscriber and no one else.
              </p>
            </div>
          )}
        </div>
        {/* fourth item */}
        <div className="w-full mb-2">
          <div className="flex items-start gap-4">
            <button onClick={() => setIsOpen4(!isOpen4)} className=" w-6 h-6">
              {isOpen4 ? (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path d="M5 11V13H19V11H5Z" fill="#ffcb00"></path>
                </svg>
              ) : (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path
                    d="M11 11V7H13V11H17V13H13V17H11V13H7V11H11ZM12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20Z"
                    fill="#ffcb00"
                  ></path>
                </svg>
              )}
            </button>
            <h3 className={`${isOpen4 ? "text-gray-900" : "text-gray-500"}`}>
              What is the 'Manage' page?{" "}
            </h3>
          </div>

          {isOpen4 && (
            <div className="flex flex-col items-start gap-3">
              <p className="text-xs  md:w-3/5   ">
                {" "}
                This page allows you to manage all your subscriptions.{" "}
              </p>
              <img
                alt=""
                src={f}
                className="w-52 md:max-w-md    mx-auto   border border-gray-400"
              ></img>
              <p className="text-xs     ">
                {" "}
                'Open' subscriptions are the ones which you have offered and are
                currently visible to others on feed.{" "}
              </p>
              <img
                alt=""
                src={g}
                className="w-52 md:max-w-md    mx-auto   border border-gray-400"
              ></img>
              <p className="text-xs   ">
                'Shared' subscriptions are the ones which you have offered and
                are currently subscribed by others{" "}
              </p>
              <img
                alt=""
                src={h}
                className="w-52 md:max-w-md    mx-auto   border border-gray-400"
              ></img>
              <p className="text-xs  ">
                'My subs' are subscriptions which are subscribed by you.{" "}
              </p>
            </div>
          )}
        </div>
        {/* fifth item */}
        <div className="w-full mb-2">
          <div className="flex items-center gap-4">
            <button onClick={() => setIsOpen5(!isOpen5)} className=" w-6 h-6">
              {isOpen5 ? (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path d="M5 11V13H19V11H5Z" fill="#ffcb00"></path>
                </svg>
              ) : (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path
                    d="M11 11V7H13V11H17V13H13V17H11V13H7V11H11ZM12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20Z"
                    fill="#ffcb00"
                  ></path>
                </svg>
              )}
            </button>
            <h3 className={`${isOpen5 ? "text-gray-900" : "text-gray-500"}`}>
              Why do I have a 'cart'?{" "}
            </h3>
          </div>

          {isOpen5 && (
            <div className="flex flex-col items-start gap-3">
              <p className="text-xs  ">
                All the tiles that you wish to buy will be added here after you
                click on ‘get this now’ on a tile. You can then confirm your
                purchase by following the payment process. Please note that we
                only keep items in your cart for 24 hours. If you do not
                complete the transaction within 24 hours, the tiles will be
                removed from cart and become available on the general feed.{" "}
              </p>
            </div>
          )}
        </div>
        {/* sixth item */}
        <div className="">
          <div className="flex items-center gap-4  ">
            <button onClick={() => setIsOpen6(!isOpen6)} className=" w-6 h-6">
              {isOpen6 ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  className="w-6 h-6"
                >
                  <path d="M5 11V13H19V11H5Z" fill="#ffcb00"></path>
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  className="w-6 h-6"
                >
                  <path
                    d="M11 11V7H13V11H17V13H13V17H11V13H7V11H11ZM12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20Z"
                    fill="#ffcb00"
                  ></path>
                </svg>
              )}
            </button>
            <h3 className={`  ${isOpen6 ? "text-gray-900" : "text-gray-500"}`}>
              Where can I find a 'referral code' that I can share with my
              friends?{" "}
            </h3>
          </div>
          {isOpen6 && (
            <div className="flex flex-col items-start gap-3">
              <p className="text-xs   ">
                Our app is invitation only. This means that any new sign-ups
                only happen when an existing user will refer you. You can share
                your referral code with a friend who wants to sign up (they will
                need it).{" "}
              </p>
              <img
                alt=""
                src={i}
                className="w-52 md:max-w-md    mx-auto   border border-gray-400 "
              ></img>
              <p className="text-xs md:w-3/5   ">
                Your referral code can be found in the ‘My account’ section and
                it cannot be changed.{" "}
              </p>{" "}
            </div>
          )}
        </div>
        {/* seventh item */}
        <div className="w-full mb-2">
          <div className="flex items-start gap-4">
            <button onClick={() => setIsOpen7(!isOpen7)} className=" w-6 h-6">
              {isOpen7 ? (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path d="M5 11V13H19V11H5Z" fill="#ffcb00"></path>
                </svg>
              ) : (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path
                    d="M11 11V7H13V11H17V13H13V17H11V13H7V11H11ZM12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20Z"
                    fill="#ffcb00"
                  ></path>
                </svg>
              )}
            </button>
            <h3 className={`${isOpen7 ? "text-gray-900" : "text-gray-500"}`}>
              Can I share a tile with others?{" "}
            </h3>
          </div>
          {isOpen7 && (
            <div className="flex flex-col items-start gap-3">
              <p className="text-xs  ">
                Absolutely. Just slick on ‘share’ and a link will be generated
                for the tile. You can easily share it with friends on whatsapp
                or email.{" "}
              </p>
              <img
                alt=""
                src={j}
                className=" w-52 md:max-w-md   mx-auto border border-gray-400"
              ></img>
              <p className="text-xs  ">
                They will need to sign-up to be able to complete the
                transaction. So don’t forget to share your referral code with
                them
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default How_It_Works;
