import { useState, useEffect, Confirm, useContext } from "react";
import getToken from "../Token";
import { Auth } from "aws-amplify";
import getUsername from "../Account_Infomation/Username";

import getReferralCode from "../Account_Infomation/referralCode";
import IconComponent from "../Utility/IconComponent";
import { GlobalContext } from "../GlobalContext";
import React from "react";
import Popup from "reactjs-popup";
import Api from "../../config/Api.json";
import "reactjs-popup/dist/index.css";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useQuery, useQueryClient } from "react-query";
import Endpoints from "../../api/Endpoints";
import Cards from "../Cards";
import { Tooltip } from "@mui/material";
import Zoom from "@mui/material/Zoom";
const SavedMessages = (props) => {
  const queryClient = useQueryClient();
  var link = `https://omnistack.org${process.env.REACT_APP_LINK}`;
  const { notsocket } = useContext(GlobalContext);

  // fetch openbets
  const {
    isFetching: isBasketSubsDataFetching,
    isLoading: isBasketSubsDataLoading,
    data: basketSubs,
    isError: isBasketSubsError,
    error: basketSubsErr,
  } = useQuery("userBasketSubs", Endpoints.fetchUserBasketSubs, {
    // refetchOnMount: false,
    // refetchOnWindowFocus: false,
  });

  const [totalCost, setTotalCost] = useState(0);
  const [selectedSubsCount, setSelectedSubsCount] = useState(0);
  const [selectedSubs, setSelectedSubs] = useState([]);

  const handleSelect = (tile) => {
    const subscriptionCost = Number(tile?.body);

    const newSelectedSubs = [...selectedSubs];

    if (newSelectedSubs.includes(tile?.SaturnTileRef)) {
      newSelectedSubs.splice(newSelectedSubs.indexOf(tile?.SaturnTileRef), 1);
      setTotalCost(totalCost - subscriptionCost);
      setSelectedSubsCount(selectedSubsCount - 1);
    } else {
      newSelectedSubs.push(tile?.SaturnTileRef);
      setTotalCost(totalCost + subscriptionCost);
      setSelectedSubsCount(selectedSubsCount + 1);
    }
    setSelectedSubs(newSelectedSubs);
  };

  const handleRemove = async (tile) => {
    const accesstoken = getToken();
    const curUser = getUsername();
    try {
      const response = await fetch(`${link}savedmessage-delete/`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accesstoken,
        },
        body: JSON.stringify({
          user: curUser,
          subject: tile.subject,
          body: tile.body,
          author: tile.author,
          SaturnTileRef: tile.SaturnTileRef,
          currency: tile.currency,
          tileusername: tile.tileusername,
          tilepassword: tile.tilepassword,
        }),
      });

      const json = await response.json();

      if (json) {
        await queryClient.invalidateQueries("userDetails");
        await queryClient.invalidateQueries("spongeSubs");
        await queryClient.invalidateQueries("userBasketSubs");
        toast(json);
      }
    } catch (e) {
      console.log("err", e);
    }
  };

  const handlePayment = async () => {
    const accesstoken = getToken();
    const curUser = getUsername();
    try {
      const response = await fetch(
        `${link}create-checkout-session/`,

        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + accesstoken,
            Accept: "application/json",
          },
          body: JSON.stringify({
            user: curUser,
            SaturnTileRef: selectedSubs,
          }),
        }
      );

      const json = await response.json();
      if (json) {
        if (json.url) {
          if (json.url === "") {
            toast("Please try again!");
          }
          toast("Please wait...");
          window.location.replace(json.url);
        } else {
          setSelectedSubsCount(0);
          if (json["Notification-text"] !== "") {
            toast(
              JSON.stringify(json["Notification-text"]).replace(/"|'/g, "")
            );
          } else {
            toast("Transaction Failed, please try again!");
          }
          notsocket.send(
            JSON.stringify({
              user: curUser,
            })
          );
        }
        await queryClient.invalidateQueries("userDetails");
        await queryClient.invalidateQueries("spongeSubs");
        await queryClient.invalidateQueries("userBasketSubs");
        setSelectedSubs([]);
      }
    } catch (e) {
      console.log("err", e);
    }
  };

  if (isBasketSubsDataFetching) {
    return <p className="text-center p-2">Loading ...</p>;
  }

  if (isBasketSubsError) {
    return (
      <p className="text-center p-2">
        Something went wrong! Please try again later..
      </p>
    );
  }

  return (
    <div className="pt-32 font-bellota pb-20">
      <ToastContainer autoClose={2500} />
      <div className="flex flex-col md:flex-col-reverse max-w-2xl lg:max-w-3xl  mx-auto">
        <div className=" grid md:grid-cols-2 w-full  ">
          {basketSubs !== null && basketSubs.length > 0 ? (
            basketSubs.map((message) => (
              <div key={message.id}>
                <div className=" border bg-gradient-to-t from-white to-yellow-50 border-yellow-100 p-2 m-2 rounded-lg order shadow-md justify-items-center ">
                  <Cards tileData={message} />

                  <div className="flex gap-2 items-center justify-between px-10 ">
                    <button
                      className="px-6 py-1 rounded-full shadow-sm focus:outline-none cursor-pointer bg-gradient-to-r from-yellow-200 to-red-200  font-semibold "
                      onClick={() => handleRemove(message)}
                    >
                      Delete
                    </button>
                    <button
                      className="px-6 py-1 rounded-full shadow-sm focus:outline-none cursor-pointer bg-gradient-to-r from-yellow-200 to-red-200  font-semibold "
                      onClick={() => handleSelect(message)}
                    >
                      {selectedSubs.includes(message.SaturnTileRef)
                        ? "Remove"
                        : "Select"}
                    </button>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="border-b border-gray-200 mx-5">
              <h3 className="p-2 text-2xl ">You Cart is Empty!</h3>
            </div>
          )}
        </div>
        <div
          className={` ${
            selectedSubs?.length > 0
              ? "max-h-52 border-t border-gray-200"
              : "max-h-0"
          } overflow-hidden transition-[max-height] duration-500 ease-in bg-white rounded-t-lg  w-full md:max-w-xs md:mx-auto px-2 pt-5  md:border-none `}
        >
          <div className="flex justify-between items-center p-1">
            <p className=" text-gray-700">
              Subscription Price {"("} {selectedSubsCount} items {")"}
            </p>
            <p className="text-gray-800 ">{totalCost.toFixed(2)} GBP</p>
          </div>
          <div className="flex justify-between items-center p-1 leading-4">
            <div>
              <p className=" text-gray-700  ">Sponge Fees </p>
              <Tooltip
                enterTouchDelay={0}
                TransitionComponent={Zoom}
                placement="top"
                arrow
                leaveTouchDelay={5000}
                title="10% of subscription price."
              >
                <span className="text-xs text-yellow-500 cursor-pointer ">
                  How does fees work?
                </span>
              </Tooltip>
            </div>
            <p className="text-gray-800 ">{(totalCost * 0.1).toFixed(2)} GBP</p>
          </div>
          <div className="flex justify-between items-center p-1">
            <p className=" text-gray-700"> Total Cost</p>
            <p className="text-gray-800 text-sm">
              {(totalCost * 0.1 + totalCost).toFixed(2)} GBP/month
            </p>
          </div>
          <div className="flex  items-center justify-around">
            <button
              className="px-6 py-1 w-full my-5 rounded-full shadow-sm focus:outline-none cursor-pointer bg-gradient-to-r from-yellow-200 to-red-200  font-semibold "
              onClick={handlePayment}
            >
              Pay Now
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SavedMessages;
