import React, { useContext, useState } from "react";
import IconComponent from "../../../Utility/IconComponent";
import EditMessagePopup from "../../../EditMessagePopup";
import { GlobalContext } from "../../../GlobalContext";
import Modal from "react-modal";
import getToken from "../../../Token";
import styles from "../../../../styles/styles.css";
import Endpoints from "../../../../api/Endpoints";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "../../../../styles/styles.css";
import Cards from "../../../Cards";
import { useQueryClient } from "react-query";

function OpenCard({ message }) {
  const queryClient = useQueryClient();
  var link = `https://omnistack.org${process.env.REACT_APP_LINK}`;
  const { setOpenSubs } = useContext(GlobalContext);
  const [editModalIsOpen, setEditModalIsOpen] = useState(false);
  const setEditModalIsOpenToFalse = () => {
    setEditModalIsOpen(false);
  };

  // console.log(message);

  //global delete
  const handleDeleteglobal = async (e) => {
    let accesstoken = getToken();

    const savedcontent = {
      id: e.target.dataset.id,
      user: global.username,
      subject: e.target.dataset.subject,
      body: e.target.dataset.body,
      author: e.target.dataset.author,
      SaturnTileRef: e.target.dataset.saturntileref,
      created_at: e.target.dataset.created_at,
      currency: e.target.dataset.currency,
      tileusername: e.target.dataset.author,
      tilepassword: e.target.dataset.tilepassword,
    };

    fetch(link + "message-delete/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accesstoken,
      },
      body: JSON.stringify(savedcontent),
      tileusername: JSON.stringify(savedcontent),
      tilepassword: JSON.stringify(savedcontent),
    }).then(async () => {
      // console.log("message has been deleted");
      // console.log(savedcontent);
      //   refresh with openpost api
      await queryClient.invalidateQueries("openSubs");
    });
  };

  return (
    <div>
      <div className=" border bg-gradient-to-t from-white to-yellow-50 border-yellow-100 p-2 m-2 rounded-lg order shadow-md justify-items-center ">
        <Cards tileData={message} />

        <div className="flex gap-2 items-center justify-around  ">
          <button
            className="flex-nowrap flex-shrink px-1 py-2 button w-24  rounded-full shadow-sm focus:outline-none cursor-pointer bg-gradient-to-r from-yellow-200 to-red-200 text-xs font-semibold "
            onClick={() => setEditModalIsOpen(true)}
          >
            Update
          </button>

          <Modal
            isOpen={editModalIsOpen}
            className=" max-w-lg z-50 mx-5 sm:mx-auto my-10 md:my-16 rounded-lg shadow-md text-center"
            overlayClassName="bg-black z-50 bg-opacity-30 fixed inset-0 overflow-y-auto"
            onRequestClose={() => setEditModalIsOpen(false)}
          >
            <button
              onClick={setEditModalIsOpenToFalse}
              className="flex justify-start px-2 pb-1 button rounded-lg shadow-sm focus:outline-none cursor-pointer bg-gradient-to-r from-yellow-200 to-red-200 text-sm   flex-shrink"
            >
              x
            </button>
            <EditMessagePopup
              tileData={message}
              closeOnClick={setEditModalIsOpenToFalse}
            />
          </Modal>

          <button
            className="flex-nowrap flex-shrink px-1 py-2 button w-24  rounded-full shadow-sm focus:outline-none cursor-pointer bg-gradient-to-r from-yellow-200 to-red-200 text-xs font-semibold "
            data-id={message.id}
            data-author={message.author}
            data-subject={message.subject}
            data-body={message.body}
            data-created_at={message.created_at}
            data-currency={message.currency}
            data-saturntileref={message.SaturnTileRef}
            data-tileusername={message.tileusername}
            data-tilepassword={message.tilepassword}
            onClick={handleDeleteglobal}
          >
            Disable
          </button>
        </div>
      </div>
    </div>
  );
}

export default OpenCard;
