import React, { useState } from "react";
import IconComponent from "./Utility/IconComponent";
import Cards from "./Cards";

const ViewMessagePopup = ({ tileData, tileDetails, onClick }) => {
  const [isAccordianOpen, setIsAccordianOpen] = useState(false);
  const [accordianIndex, setAccordianIndex] = useState();

  const [viewPass, setViewPass] = useState(false);
  const handleSaveCredentials = (index) => {
    setViewPass(false);
    if (accordianIndex === index) {
      setIsAccordianOpen(false);
      setAccordianIndex(-1);
    } else {
      setIsAccordianOpen(false);
      setAccordianIndex(index);
      setIsAccordianOpen(true);
    }
  };

  console.log(tileDetails);

  return (
    <div className="bg-white  rounded-lg py-5 px-2">
      <div className=" font-bellota justify-items-center overflow-x-auto ">
        <Cards tileData={tileData} />
      </div>

      <div className="font-bellota">
        {tileData?.is_bundle && tileDetails !== null ? (
          Object.keys(tileDetails).map((item, index) => (
            <div
              key={index}
              className="border-b-2 border-[#DAF6FF] pb-2 text-xs md:text-sm"
            >
              <div className="flex items-center justify-between">
                <p className="font-semibold">{item}</p>
                <button
                  onClick={() => handleSaveCredentials(index)}
                  className="w-5 h-5"
                >
                  {isAccordianOpen && accordianIndex === index ? (
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <path d="M12 11.8284L9.17154 14.6569L7.75732 13.2426L12 9L16.2426 13.2426L14.8284 14.6569L12 11.8284Z"></path>
                    </svg>
                  ) : (
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <path d="M12 15.0006L7.75732 10.758L9.17154 9.34375L12 12.1722L14.8284 9.34375L16.2426 10.758L12 15.0006Z"></path>
                    </svg>
                  )}
                </button>
              </div>
              <div
                className={` overflow-hidden transition-[max-height] duration-500 ease-in pt-3 ${
                  isAccordianOpen && accordianIndex === index
                    ? "max-h-40"
                    : "max-h-0"
                }`}
              >
                <label
                  htmlFor="tileusername"
                  className="flex justify-start px-2 p-1 text-sm"
                >
                  Username
                </label>
                <input
                  id="tileusername"
                  name="tileusername"
                  className=" flex items-center justify-center mx-1 mb-2 align-center rounded-full   border-t border-b border-yellow-300 shadow-sm text-xs w-3/4 px-2 py-2"
                  value={tileDetails[item].tileusername}
                  required
                  // onChange={(event) => handleInputChange(event, item)}
                  type="text"
                  placeholder={` Username for ${item}`}
                />
                <label
                  htmlFor="tilepassword"
                  className="flex justify-start px-2 p-1 text-sm"
                >
                  Password
                </label>

                <div className=" flex items-center justify-between mx-1 mb-2 align-center rounded-full   border-t border-b border-yellow-300 shadow-sm text-xs w-3/4 px-2">
                  <input
                    id="tilepassword"
                    name="tilepassword"
                    className="w-full px-2 py-2 rounded-full "
                    value={tileDetails[item].tilepassword}
                    required
                    // onChange={(event) => handleInputChange(event, item)}
                    type={`${viewPass ? "text" : "password"}`}
                    placeholder={`Password for ${item}`}
                  />
                  <div
                    className="flex  items-center justify-end"
                    onClick={() => setViewPass(!viewPass)}
                  >
                    {!viewPass ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        className="w-4 "
                      >
                        <path d="M4.52047 5.93457L1.39366 2.80777L2.80788 1.39355L22.6069 21.1925L21.1927 22.6068L17.8827 19.2968C16.1814 20.3755 14.1638 21.0002 12.0003 21.0002C6.60812 21.0002 2.12215 17.1204 1.18164 12.0002C1.61832 9.62282 2.81932 7.5129 4.52047 5.93457ZM14.7577 16.1718L13.2937 14.7078C12.902 14.8952 12.4634 15.0002 12.0003 15.0002C10.3434 15.0002 9.00026 13.657 9.00026 12.0002C9.00026 11.537 9.10522 11.0984 9.29263 10.7067L7.82866 9.24277C7.30514 10.0332 7.00026 10.9811 7.00026 12.0002C7.00026 14.7616 9.23884 17.0002 12.0003 17.0002C13.0193 17.0002 13.9672 16.6953 14.7577 16.1718ZM7.97446 3.76015C9.22127 3.26959 10.5793 3.00016 12.0003 3.00016C17.3924 3.00016 21.8784 6.87992 22.8189 12.0002C22.5067 13.6998 21.8038 15.2628 20.8068 16.5925L16.947 12.7327C16.9821 12.4936 17.0003 12.249 17.0003 12.0002C17.0003 9.23873 14.7617 7.00016 12.0003 7.00016C11.7514 7.00016 11.5068 7.01833 11.2677 7.05343L7.97446 3.76015Z"></path>
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        className="w-4"
                      >
                        <path d="M1.18164 12C2.12215 6.87976 6.60812 3 12.0003 3C17.3924 3 21.8784 6.87976 22.8189 12C21.8784 17.1202 17.3924 21 12.0003 21C6.60812 21 2.12215 17.1202 1.18164 12ZM12.0003 17C14.7617 17 17.0003 14.7614 17.0003 12C17.0003 9.23858 14.7617 7 12.0003 7C9.23884 7 7.00026 9.23858 7.00026 12C7.00026 14.7614 9.23884 17 12.0003 17ZM12.0003 15C10.3434 15 9.00026 13.6569 9.00026 12C9.00026 10.3431 10.3434 9 12.0003 9C13.6571 9 15.0003 10.3431 15.0003 12C15.0003 13.6569 13.6571 15 12.0003 15Z"></path>
                      </svg>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className={`space-y-2`}>
            <label
              htmlFor="tileusername"
              className="flex justify-start px-2 p-1 text-sm"
            >
              Username
            </label>
            <input
              id="tileusername"
              name="tileusername"
              className=" flex items-center justify-center mx-1 mb-2 align-center rounded-full   border-t border-b border-yellow-300 shadow-sm text-xs w-3/4 px-2 py-2"
              value={tileDetails[tileData?.subject]?.tileusername || ""}
              required
              // onChange={(e) => setTileUsername(e.target.value)}
              type="text"
              placeholder={`Enter Username `}
            />
            <label
              htmlFor="tilepassword"
              className="flex justify-start px-2 p-1 text-sm"
            >
              Password
            </label>

            <div className="flex items-center justify-between mx-1 mb-2 align-center rounded-full   border-t border-b border-yellow-300 shadow-sm text-xs w-3/4 px-2 py-2">
              <input
                id="tilepassword"
                name="tilepassword"
                className=" "
                value={tileDetails[tileData?.subject]?.tilepassword || ""}
                required
                // onChange={(e) => setTilePassword(e.target.value)}
                type={`${viewPass ? "text" : "password"}`}
                placeholder={`Enter password`}
              />
              <div
                className="flex  items-center justify-end"
                onClick={() => setViewPass(!viewPass)}
              >
                {!viewPass ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    className="w-4 "
                  >
                    <path d="M4.52047 5.93457L1.39366 2.80777L2.80788 1.39355L22.6069 21.1925L21.1927 22.6068L17.8827 19.2968C16.1814 20.3755 14.1638 21.0002 12.0003 21.0002C6.60812 21.0002 2.12215 17.1204 1.18164 12.0002C1.61832 9.62282 2.81932 7.5129 4.52047 5.93457ZM14.7577 16.1718L13.2937 14.7078C12.902 14.8952 12.4634 15.0002 12.0003 15.0002C10.3434 15.0002 9.00026 13.657 9.00026 12.0002C9.00026 11.537 9.10522 11.0984 9.29263 10.7067L7.82866 9.24277C7.30514 10.0332 7.00026 10.9811 7.00026 12.0002C7.00026 14.7616 9.23884 17.0002 12.0003 17.0002C13.0193 17.0002 13.9672 16.6953 14.7577 16.1718ZM7.97446 3.76015C9.22127 3.26959 10.5793 3.00016 12.0003 3.00016C17.3924 3.00016 21.8784 6.87992 22.8189 12.0002C22.5067 13.6998 21.8038 15.2628 20.8068 16.5925L16.947 12.7327C16.9821 12.4936 17.0003 12.249 17.0003 12.0002C17.0003 9.23873 14.7617 7.00016 12.0003 7.00016C11.7514 7.00016 11.5068 7.01833 11.2677 7.05343L7.97446 3.76015Z"></path>
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    className="w-4"
                  >
                    <path d="M1.18164 12C2.12215 6.87976 6.60812 3 12.0003 3C17.3924 3 21.8784 6.87976 22.8189 12C21.8784 17.1202 17.3924 21 12.0003 21C6.60812 21 2.12215 17.1202 1.18164 12ZM12.0003 17C14.7617 17 17.0003 14.7614 17.0003 12C17.0003 9.23858 14.7617 7 12.0003 7C9.23884 7 7.00026 9.23858 7.00026 12C7.00026 14.7614 9.23884 17 12.0003 17ZM12.0003 15C10.3434 15 9.00026 13.6569 9.00026 12C9.00026 10.3431 10.3434 9 12.0003 9C13.6571 9 15.0003 10.3431 15.0003 12C15.0003 13.6569 13.6571 15 12.0003 15Z"></path>
                  </svg>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ViewMessagePopup;
