import { useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import * as AmazonCognitoIdentity from "amazon-cognito-identity-js";

const getUsername = () => {
  var base = "stupidbug";

  const poolData = {
    UserPoolId: "eu-west-2_YV0bvToRp",
    ClientId: "3i3qcs3app8m1t89m1iniavpbu",
    // UserPoolId: "eu-west-2_UXJYfjlKr",
    // ClientId: "5k305rgnr1t5g9btchop69qfv8",
  };
  const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);

  const cognitoUser = userPool.getCurrentUser();

  if (cognitoUser == null) {
    return base;
  }

  return cognitoUser.username;
};

export default getUsername;
