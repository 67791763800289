import React from "react";
import { useState, useCallback, useEffect } from "react";
import getToken from "../Token";
import getUsername from "../Account_Infomation/Username";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function Support(props) {
  var link = `https://omnistack.org${process.env.REACT_APP_LINK}`;
  const contactForm = document.querySelector("content-wrapper");
  const [posting, setPosting] = useState(false);
  const accesstoken = getToken();
  const username = getUsername();
  const [body, setBody] = useState("");
  const [message_name, setmessage_name] = useState("");
  const [message_email, setmessage_email] = useState("");
  const [message_subject, setmessage_subject] = useState("");
  const [message, setmessage] = useState("");
  const [mobile, setmobile] = useState("");

  const handleSumbit = (e) => {
    e.preventDefault(); //prevents refreshing page
    const content = {
      message_name,
      message_email,
      message_subject,
      message,
    };

    if (typeof props.tileDetails != "undefined") {
      content.SaturnTileRef = props.tileDetails.supportModelData.SaturnTileRef;
    } else {
      content.SaturnTileRef = 0;
    }

    global.messagelist.push(content);
    // document.getElementById("messagesubject").reset();
  };

  return (
    <div className="bg-white py-5 px-2 rounded-lg w-full">
      <form className="" onSubmit={handleSumbit}>
        <div className=" mb-6">
          {/* Name input */}

          <div className="flex flex-col items-start p-3">
            <label
              className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2"
              for="grid-first-name"
            >
              Full Name
            </label>
            <input
              className="focus:outline-none placeholder-gray-600 shadow-md text-sm p-2 cursor-pointer w-full rounded-lg bg-yellow-200"
              type="text"
              value={message_name}
              onChange={(e) => setmessage_name(e.target.value)}
              name="message-name"
              placeholder="Name"
              // required
            />
          </div>

          {/* Email Input */}

          <div className="flex flex-col items-start p-3">
            <label
              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="grid-password"
            >
              E-mail
            </label>
            <input
              className="focus:outline-none placeholder-gray-600 shadow-md text-sm p-2 cursor-pointer w-full rounded-lg bg-yellow-200"
              type="email"
              value={message_email}
              onChange={(e) => setmessage_email(e.target.value)}
              name="message-email"
              placeholder="Email"
              // required
            />
          </div>
          <div className="flex flex-col items-start p-3">
            <label
              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="grid-password"
            >
              Mobile
            </label>
            <input
              className="focus:outline-none placeholder-gray-600 shadow-md text-sm p-2 cursor-pointer w-full rounded-lg bg-yellow-200"
              type="mobile"
              value={mobile}
              onChange={(e) => setmobile(e.target.value)}
              name="message-email"
              placeholder="Mobile"
              // required
            />
          </div>

          {/* Subject Input */}

          <div className="flex flex-col items-start p-3">
            <label
              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="grid-last-name"
            >
              Subject
            </label>
            <input
              className="focus:outline-none placeholder-gray-600 shadow-md text-sm p-2 cursor-pointer w-full rounded-lg bg-yellow-200"
              type="text"
              value={message_subject}
              onChange={(e) => setmessage_subject(e.target.value)}
              name="message-subject"
              placeholder="Subject"
              // required
            />
          </div>
        </div>

        {/* Message Input */}

        <div className="flex flex-col items-start px-3">
          <label
            className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
            for="grid-password"
          >
            Message
          </label>
          <textarea
            className="focus:outline-none placeholder-gray-600 shadow-md text-sm p-2 cursor-pointer w-full rounded-lg bg-yellow-200 h-24 resize-none"
            id="message"
            name="message"
            value={message}
            onChange={(e) => setmessage(e.target.value)}
            placeholder="Message"
            // required
          ></textarea>
        </div>
        {/* <ToastContainer autoClose={2500} /> */}
        <div className="flex items-center py-5 justify-around">
          <button
            className="px-10 py-1  cursor-pointer hover:text-white  shadow-lg   hover:bg-red-800 focus:outline-none rounded-full  bg-gradient-to-r from-yellow-200 to-red-200"
            onClick={() => {
              fetch(link + "contact-email/", {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + accesstoken,
                },
                body: JSON.stringify({
                  message_name: username,
                  message_email: message_email,
                  message_mobile: mobile,
                  message_subject: message_subject,
                  message: message,
                }),
              })
                .then((response) => response.json())
                .then((json) => {
                  if (
                    message_email !== "" &&
                    mobile !== "" &&
                    message_subject !== "" &&
                    message !== ""
                  ) {
                    toast(
                      JSON.stringify(json["Notifications-text"]).replace(
                        /"|'/g,
                        ""
                      )
                    );
                  } else {
                    toast("Please fill all fields to create support request");
                  }
                });

              setmessage_name("");
              setmessage_email("");
              setmessage_subject("");
              setmessage("");
              setmobile("");
            }}
          >
            Submit
          </button>

          <div className="flex items-center ">
            {typeof props.tileDetails != "undefined" && (
              <span
                className="px-10 py-1  cursor-pointer hover:text-white  shadow-lg   hover:bg-red-800 focus:outline-none rounded-full  bg-gradient-to-r from-yellow-200 to-red-200"
                onClick={props.handleClose}
              >
                Close
              </span>
            )}
            {/* {typeof props.tileDetails == 'undefined' &&
                            <Link to="">
                                <button classNameName="px-10 md:w-40 sm:w-30 button cursor-pointer text-center hover:text-white flex items-center justify-center shadow-lg   hover:bg-red-800 focus:outline-none rounded-full h-8 bg-gradient-to-r from-yellow-200 to-red-200">
                                    Back
                                </button>
                            </Link>

                        } */}
          </div>
        </div>
      </form>
    </div>
  );
}
