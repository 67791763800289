import React from "react";

import { useState, useCallback } from "react";
import { Link } from "react-router-dom";
import { Auth } from "aws-amplify";
import getUsername from "./Account_Infomation/Username";
import getToken from "./Token";
import getEmail from "./Account_Infomation/RegEmail";

import { useEffect } from "react";

const VerifyMessagePopup2 = (props) => {
  var link = `https://omnistack.org${process.env.REACT_APP_LINK}`;
  //var link = "http://localhost:8000/api/";

  const alphabet = "abcdefghijklmnopqrstuvwxyz";

  const SaturnUser = getUsername();
  const SaturnEmail = getEmail();
  var ReferralCode = "";

  const [posting, setPosting] = useState(false);

  //const [isPending, setIsPending] = useState(false);

  const [subject, setSubject] = useState(null); //subject state for select

  //const {data: subjectlist} = useSubject(/*'https://omnistack.org/sponge/topic-list/'*/'http://localhost:8000/api/topic-list/', accesstoken).then(setSubject(Object.keys(subjectlist)[0]));

  const [body, setBody] = useState("");

  const [VerifyCode, setVerifyCode] = useState("");

  const [message, setMessage] = useState("Referral Code");
  const [ValidCodes, setValidCodes] = useState("Referral Code");

  const [chosencurrency, setChosencurrency] = useState("GBP");
  const accesstoken = getToken();

  var myHeaders = new Headers();

  const handleSumbit = (e) => {
    e.preventDefault(); //prevents refreshing page
    props.onClick3();
  };

  //need to make onclickfunction to update message board feed when submit in messageboard component

  return (
    <div className="bg-white rounded-md py-5">
    <p className=" text-center">Welcome to Sponge!</p>
    <p className=" text-center">Want to Know how it works?</p>
      <div className="grid grid-cols-2 flex sm:flex-row flex-col text-center pt-2">
        <p></p>
        <div className="col-span-2 flex-1 pl-2 text-center pt-5">
          <button
            className="col-span-2 focus:outline-none text-center"
            onClick={handleSumbit}
          >
            <div className="focus:border-0 flex-3 ">
              <div className=" flex-nowrap flex-shrink px-1 py-2 mx-1 button w-24  rounded-full shadow-sm focus:outline-none cursor-pointer bg-gradient-to-r from-yellow-200 to-red-200 text-xs font-bold">
                <Link to="/how-it-works">Yes</Link>{" "}
              </div>
            </div>
          </button>
          <span> </span>
          <button
            className="col-span-2 focus:outline-none text-center sm:ml-4"
            onClick={handleSumbit}
          >
            <div className="focus:border-0 flex-3 ">
              <div className=" flex-nowrap flex-shrink px-1 py-2 mx-1 button w-24  rounded-full shadow-sm focus:outline-none cursor-pointer bg-gradient-to-r from-yellow-200 to-red-200 text-xs font-bold">
                <Link to="/">No thanks</Link>{" "}
              </div>
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default VerifyMessagePopup2;
