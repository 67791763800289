import React from "react";
import { withAuthenticator, AmplifySignOut } from "@aws-amplify/ui-react";

import Notification_tab from "./Notification_tab";

class Card_popout3 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      opened: false,
      items: "",
      filterlist: null,
    };

    this.toggleBox = this.toggleBox.bind(this);
    this.componentDidMount = this.componentDidMount.bind(this);
    this.wrapperRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  toggleBox() {
    const { opened } = this.state;
    this.componentDidMount();
    this.setState({
      opened: !opened,
    });
  }
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }
  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      const { opened } = this.state;
      this.setState({
        opened: false,
      });
    }
  }
  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }
  render() {
    var { style, children } = this.props;
    const { opened } = this.state;

    const filterlist = this.state.filterlist;

    return (
      <div ref={this.wrapperRef}>
        <button class="focus:outline-none" onClick={this.toggleBox}>
          {children}
        </button>

        {opened && (
          <div class="z-50 absolute  right-0 bg-gradient-to-t from-white to-yellow-50 border w-60 border-yellow-100 p-2 m-2 rounded-lg order shadow-md justify-items-center overflow-x-auto  ">
            <Notification_tab
              filtervalue={(value) => this.setState({ filterlist: value })}
            />
          </div>
        )}
      </div>
    );
  }
}

export default withAuthenticator(Card_popout3, false);
