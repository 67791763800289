import React from "react";

import Your_Account from "./components/Pages/Your_Account.js";
import getUsername from "./components/Account_Infomation/Username.js";
import getReferralCode from "./components/Account_Infomation/referralCode.js";
import CashoutPage from "./components/Pages/CashoutPage.js";
import Support_Page from "./components/Pages/Support_Page.js";
import getIsVerified from "./components/Account_Infomation/isVerified.js";
import Modal from "react-modal";
import VerifyMessagePopup from "./components/VerifyMessagePopup.js";
import "./styles/output.css";
import { withAuthenticator } from "@aws-amplify/ui-react";
import { Auth } from "aws-amplify";
import How_It_Works from "./components/Pages/How_It_Works.js";
import { useState } from "react";
import { Route, Navigate, Routes } from "react-router-dom";
import "./components/burgercss.css";
import SavedMessages from "./components/Pages/SavedMessages.js";
import Sucess from "./components/Pages/payment/Sucess";
import UnderProcess from "./components/Pages/payment/UnderProcess";
import Payment from "./components/Payment.js";
import MessageBoard from "./components/Pages/MessageBoard.js";
import UserMessages from "./components/Pages/UserMessages.js";
import VerifyMessagePopup2 from "./components/VerifyMessagePopup2.js";
import UserSubscription from "./components/Pages/manage/subs/UserSubscription.js";
import SharedSubscription from "./components/Pages/manage/shared/SharedSubscription.js";
import ReddemCoupon from "./components/Pages/ReddemCoupon.js";
import OpenSubscription from "./components/Pages/manage/open/OpenSubscription.js";
import FilterSubscription from "./components/Pages/manage/FilterSubscription.js";
function Test() {
  const [feedelement, setFeedelement] = useState("messageboard");
  const [filterlist, setFilterlist] = useState(null);
  const [VerifyCode, setVerifyCode] = useState("");
  const [postedmessage, setPostedmessage] = useState([]);
  const [message, setmessage] = useState("");
  const verification = getIsVerified();
  const referralCode = getReferralCode();
  const [Verified, setVerified] = useState(getIsVerified());
  const [uname, setUname] = useState("");
  const [verifiedStatus, setVerifiedStatus] = useState(verification);

  global.username = Auth.currentUserInfo().username;

  document.title = "Sponge App";
  const [menuclick, setMenuclick] = useState(false);
  function handleClick() {
    setMenuclick(!menuclick);
  }
  /*in sidebar overflow-y-scroll*/
  const [modalIsOpen, setModalIsOpen] = useState(!Verified);
  const [modalIsOpen2, setModalIsOpen2] = useState(false);
  const setModalIsOpenToTrue = () => {
    setModalIsOpen(true);
  };
  const setModalIsOpenToFalse = () => {
    setModalIsOpen(false);
  };
  const setModalIsOpenToTrue2 = () => {
    setModalIsOpen2(true);
  };
  const setModalIsOpenToFalse2 = () => {
    setModalIsOpen2(false);
  };

  // console.log(Verified);
  return (
    <React.Fragment>
      <Modal
        isOpen={modalIsOpen}
        className="font-bellota  max-w-xl z-50 mx-5 sm:mx-auto my-10 md:my-16 rounded-md focus:outline-none shadow-lg text-center"
        overlayClassName="bg-black z-50 bg-opacity-60 fixed inset-0 overflow-y-auto"
      >
        <VerifyMessagePopup
          onClick={setModalIsOpenToFalse}
          onClick2={setModalIsOpenToTrue2}
        />
      </Modal>
      {modalIsOpen2 && (
        <Modal
          isOpen={modalIsOpen2}
          className="font-bellota  max-w-xl z-50 mx-5 sm:mx-auto my-10 md:my-16 rounded-md focus:outline-none shadow-lg text-center"
          overlayClassName="bg-black z-50 bg-opacity-60 fixed inset-0 overflow-y-auto"
        >
          <VerifyMessagePopup2 onClick3={setModalIsOpenToFalse2} />
        </Modal>
      )}
      <Routes>
        <Route
          path="/underprocess/:checkoutsession/:tile"
          element={<UnderProcess />}
        />

        <Route path="/sucess" element={<Sucess />} />

        <Route
          path="cashout"
          element={<CashoutPage currentusername={getUsername()} />}
        />

        <Route
          path="support"
          element={<Support_Page currentusername={getUsername()} />}
        />

        <Route
          path="payments"
          element={<Payment currentusername={getUsername()} />}
        />

        <Route path="how-it-works" element={<How_It_Works />} />

        <Route
          path="account"
          element={<Your_Account currentusername={getUsername()} />}
        />

        <Route path="how-it-works" element={<How_It_Works />} />

        <Route path="redeem-coupon" element={<ReddemCoupon />} />
        <Route
          path="basket"
          element={<SavedMessages currentusername={getUsername()} />}
        />

        <Route
          path="my-posts"
          element={<UserMessages currentusername={getUsername()} />}
        >
          <Route path="open" element={<OpenSubscription />} />
          <Route path="shared" element={<SharedSubscription />} />
          <Route path="subscriptions" element={<UserSubscription />} />
          <Route path="filter" element={<FilterSubscription />} />
        </Route>

        <Route
          path="home"
          element={
            <MessageBoard
              postedmessage={postedmessage}
              currentusername={getUsername()}
              filterlist={filterlist}
            />
          }
        />
        <Route path="*" element={<Navigate to="home" />} />
      </Routes>
    </React.Fragment>
  );
}

export default withAuthenticator(Test, false, (document.title = "Sponge App"));
