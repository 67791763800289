import React, { useState } from "react";
import { Link } from "react-router-dom";
import pic1 from "../../assets/3b72e9_d4f2f8d9c01046a8af6ff96c6156895b_mv2.png";
import pic2 from "../../assets/sponge-logo.svg";
import pic4 from "../../assets/undraw_people_re_8spw 1.png";
import pic5 from "../../assets/undraw_video_streaming_re_v3qg 1.png";
import { useNavigate } from "react-router";

function LandingPage() {
  const navigate = useNavigate();
  const [subEmail, setSubEmail] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isSubEmailValid, setIsSubEmailValid] = useState(true);

  const validateSubEmail = (email) => {
    setIsSubEmailValid(
      /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email) // regex for email validation
    );
  };

  const validateEmail = (email) => {
    setIsEmailValid(
      /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email) // regex for email validation
    );
  };

  const handleSubscribe = async (e) => {
    e.preventDefault();
    validateSubEmail(subEmail);
    var isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(subEmail);
    if (isValidEmail) {
      try {
        var data = {
          email: subEmail,
        };
        const response = await fetch(
          "https://7nv7ifa2kc.execute-api.eu-west-2.amazonaws.com/prod/register",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
          }
        );
        const json = await response.json();

        if (json) {
          setSubEmail("");
          navigate("/register", { state: { useremail: subEmail } });
        }
      } catch (error) {
        console.log("There was a SyntaxError", error);
      }
      const response = await fetch(
        "https://omnistack.org/sponge/" + "send-register-email/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log("test");
    validateEmail(email);
    var isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

    if (isValidEmail) {
      try {
        var data = {
          name: name,
          email: email,
          message: message,
        };
        const response = await fetch(
          "https://0ynr25jjcb.execute-api.eu-west-2.amazonaws.com/prod/contact",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
          }
        );
        const json = await response.json();

        if (json) {
          setName("");
          setEmail("");
          setMessage("");
        }
      } catch (error) {
        console.log("There was a SyntaxError", error);
      }
    }
  };

  return (
    <div className="space-y-8 md:space-y-10  bg-landingOne font-bellota">
      <div className="flex items-center justify-between mx-1 md:mx-10 pt-2">
        <img src={pic2} alt="" className="w-32 md:w-52 xl:w-60" />
        <div className="flex items-center justify-around gap-5">
          <Link to="/login">
            <button className=" p-1  rounded-full text-sm md:text-lg px-4 md:px-6 focus:outline-none text-center  cursor-pointer  bg-gradient-to-b from-yellow-200 to-red-200 to-20%   text-gray-600 ">
              Sign In
            </button>
          </Link>
          <Link to="/register">
            <button className=" p-1  rounded-full text-sm md:text-lg px-4 md:px-6 focus:outline-none text-center  cursor-pointer  bg-gradient-to-b from-yellow-200 to-red-200 to-20%   text-gray-600 ">
              Sign up
            </button>
          </Link>
        </div>
      </div>

      {/* hero section */}
      {/* hero section */}
      <div className=" ">
        <div className="container flex flex-col justify-center p-2 mx-auto sm:py-10 lg:flex-row lg:justify-between">
          <div className="flex flex-col justify-center px-6 text-center rounded-sm lg:max-w-md xl:max-w-lg lg:text-left">
            <p className="text-2xl text-left leading-none sm:text-4xl md:text-5xl md:text-center">
              <span className=" ">The</span>{" "}
              <span className="  text-landingFour">Subscription</span>{" "}
              <span className=" ">Revolution</span>
            </p>
            <p className="mt-2 mb-8 text-lg text-left md:text-center sm:mb-12">
              We are launching soon, please register your email to be notified!
            </p>
            <div className="flex flex-row">
              <div className="relative rounded-l-lg w-3/5 sm:w-2/3">
                <input
                  type="text"
                  value={subEmail}
                  placeholder="Enter your email"
                  className="rounded-l-lg p-2 w-full text-sm md:text-lg"
                  onChange={(e) => setSubEmail(e.target.value)}
                  onBlur={() => validateSubEmail(subEmail)}
                />
                {!isSubEmailValid && (
                  <p className="text-xs absolute -bottom-4 text-center text-red-500 ">
                    Invalid email address
                  </p>
                )}
              </div>
              <button
                type="button"
                onClick={handleSubscribe}
                className="w-2/5 p-2  rounded-r-lg sm:w-1/3 text-sm md:text-lg px-4 md:px-6 focus:outline-none text-center  cursor-pointer  bg-gradient-to-b from-yellow-200 to-red-200 to-20%   text-gray-600 "
              >
                Join now
              </button>
            </div>
          </div>
          <div className="flex items-center justify-center p-6 mt-8 lg:mt-0 h-72 sm:h-80 lg:h-96 xl:h-112 2xl:h-128">
            <video
              autoPlay
              loop
              muted
              className=" h-72 sm:h-80 lg:h-96 xl:h-112 2xl:h-128 bg--500"
            >
              <source
                src="https://s3.eu-west-2.amazonaws.com/www.go2sponge.com/assets/images/Copy+of+Intro+video+1.mp4+(Version+5).mp4"
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </div>

      <div className="bg-gradient-to-b from-landingTwo to-landingThree">
        <div className="container flex flex-col justify-center p-4 mx-auto md:p-8">
          <h2 className="mb-12 mt-10 text-4xl   leading-none text-center sm:text-5xl">
            ABOUT SPONGE
          </h2>
          <div className="flex flex-col md:flex-row gap-10 md:gap-8 sm:p-3  lg:px-12 xl:px-12">
            <div>
              <img src={pic1} alt="" className="md:w-96 " />
            </div>
            <div>
              <p className="mt-1 text-justify md:w-60 lg:w-80 ">
                Here at Sponge, we aim to make your favourite subscriptions more
                affordable and get you some new ones which you may not have
                considered before. Share with friends and family without hassle.
                Get the best out of your network. Connect, share, and grow.
              </p>
            </div>
            <div>
              <img src={pic4} alt="" className="md:w-96 " />
            </div>
          </div>
        </div>
        <div className="grid max-w-screen-xl grid-cols-1 gap-8 px-8 py-16 mx-auto rounded-lg md:grid-cols-2 md:px-12 lg:px-16 xl:px-32 dark:bg-gray-800 dark:text-gray-100">
          <p className="grid md:col-span-2 text-center   md:text-2xl">
            Stay up to date with the release of Sponge. Register your details
            below!
          </p>
          <div className="flex flex-col justify-between">
            <div className="space-y-2">
              <div className="flex justify-center pt-4 space-x-4 lg:pt-0 lg:col-end-13">
                <img src={pic5} alt="" />
              </div>
            </div>
          </div>
          <form
            novalidate=""
            onSubmit={(e) => handleSubmit(e)}
            className="space-y-6 ng-untouched ng-pristine ng-valid"
          >
            <div>
              <label for="name" className="text-sm">
                Full name
              </label>
              <input
                id="name"
                type="text"
                onChange={(e) => setName(e.target.value)}
                placeholder="Your name"
                className="w-full p-3 rounded dark:bg-gray-800"
              />
            </div>
            <div>
              <label for="email" className="text-sm">
                Email
              </label>
              <input
                id="email"
                type="email"
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Your email"
                className="w-full p-3 rounded dark:bg-gray-800"
                onBlur={() => validateEmail(email)}
              />

              {!isEmailValid && (
                <p className="text-xs text-red-500 ">Invalid email address</p>
              )}
            </div>
            <div>
              <label for="message" className="text-sm">
                Message
              </label>
              <textarea
                id="message"
                rows="3"
                onChange={(e) => setMessage(e.target.value)}
                placeholder="Your message"
                className="w-full p-3 rounded dark:bg-gray-800"
              ></textarea>
            </div>
            <button
              type="submit"
              className="w-full p-3 text-sm tracking-wide uppercase focus:outline-none text-center  cursor-pointer rounded-full bg-gradient-to-b from-yellow-200 to-red-200 to-20%   text-gray-600"
            >
              Send Message
            </button>
          </form>
        </div>
        <div className="py-6 bg--800 text--50">
          <div className="container px-6 mx-auto space-y-6 border-t border-gray-400 border-opacity-40 md:space-y-12 ">
            <div className="grid justify-center pt-6 lg:justify-between">
              <div className="flex flex-col self-center text-sm text-center md:block lg:col-start-1 md:space-x-6">
                <span>All rights reserved by General Enterprises 2023</span>
              </div>
              <div className="flex justify-center pt-4 space-x-4 lg:pt-0 lg:col-end-13">
                <img src={pic2} alt="" className="md:w-60 " />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LandingPage;
