import React, { createContext, useState, useEffect } from "react";

import { w3cwebsocket as W3CWebSocket } from "websocket";
import getUsername from "./Account_Infomation/Username";
import { useQuery } from "react-query";
import Endpoints from "../api/Endpoints";

// Create context
export const GlobalContext = createContext();

export const GlobalProvider = ({ children }) => {
  const [usermessagefeed, setUsermessagefeed] = useState("Open Posts");
  const [notsocket, setNotsocket] = useState([]);

  // homepage filter
  const [showFilteredData, setShowFilteredData] = useState(false);
  const [selectedConnectionOptions, setSelectedConnectionOptions] = useState(
    []
  );
  const [selectedHomeFilterSubsOptions, setSelectedHomeFilterSubsOptions] =
    useState([]);

  // manage page filter
  const [selectedCustomOptions, setSelectedCustomOptions] = useState([]);
  const [selecteSubDate, setSelectedSubDate] = useState("");
  const [selectedSubsOptions, setSelectedSubsOptions] = useState([]);

  // userAvatar
  const [userAvatarArray, setUserAvatarArray] = useState([
    {
      url: "https://zaplynimages.s3.eu-west-2.amazonaws.com/sponge_avatar_1.svg",
      id: 1,
    },
    {
      url: "https://zaplynimages.s3.eu-west-2.amazonaws.com/sponge_avatar_2.svg",
      id: 2,
    },
    {
      url: "https://zaplynimages.s3.eu-west-2.amazonaws.com/sponge_avatar_3.svg",
      id: 3,
    },
    {
      url: "https://zaplynimages.s3.eu-west-2.amazonaws.com/sponge_avatar_4.svg",
      id: 4,
    },
    {
      url: "https://zaplynimages.s3.eu-west-2.amazonaws.com/sponge_avatar_5.svg",
      id: 5,
    },
    {
      url: "https://zaplynimages.s3.eu-west-2.amazonaws.com/sponge_avatar_6.svg",
      id: 6,
    },
    {
      url: "https://zaplynimages.s3.eu-west-2.amazonaws.com/sponge_avatar_7.svg",
      id: 7,
    },
  ]);
  const [userAvatar, setUserAvatar] = useState(
    "https://zaplynimages.s3.eu-west-2.amazonaws.com/sponge_avatar_placeholder.svg"
  );

  // fetch user data
  const {
    isLoading: isUserDataLoading,
    data: userData,
    isError: isUserError,
    error: userErr,
  } = useQuery("userDetails", Endpoints.getUserData, {
    // refetchOnMount: false,
    // refetchOnWindowFocus: false,
  });

  const [chatRef, setChatRef] = useState([]);

  useEffect(() => {
    var ws_scheme =
      window.location.protocol == "https:"
        ? "wss://omnistack.org"
        : "wss://omnistack.org";
    const sock = new W3CWebSocket(ws_scheme + "/ws/fetch-notifications/");

    sock.onopen = () => {
      console.log("WebSocket Client Connected");
      sock.send(
        JSON.stringify({
          user: getUsername(),
        })
      );
    };

    setNotsocket(sock);
  }, []);

  return (
    <GlobalContext.Provider
      value={{
        // transactions: state.transactions,
        // deleteTransaction,
        // addTransaction
        // Shortlist,
        // Updatelist,
        userData,
        userAvatar,
        setUserAvatar,
        userAvatarArray,
        setUserAvatarArray,
        notsocket,
        chatRef,
        setChatRef,
        //  home
        showFilteredData,
        setShowFilteredData,
        selectedConnectionOptions,
        setSelectedConnectionOptions,
        selectedHomeFilterSubsOptions,
        setSelectedHomeFilterSubsOptions,
        // manage
        usermessagefeed,
        setUsermessagefeed,
        selectedCustomOptions,
        setSelectedCustomOptions,
        selecteSubDate,
        setSelectedSubDate,
        selectedSubsOptions,
        setSelectedSubsOptions,
      }}
    >
      {" "}
      {children}{" "}
    </GlobalContext.Provider>
  );
};
