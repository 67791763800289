import React, { useContext } from "react";

import { useState, useCallback } from "react";
import getToken from "./Token";
import getUsername from "./Account_Infomation/Username";

import IconComponent from "./Utility/IconComponent";
import Endpoints from "../api/Endpoints";
import { GlobalContext } from "./GlobalContext";
import { useQueryClient } from "react-query";
import Cards from "./Cards";

const EditMessagePopup = ({ tileData, closeOnClick }) => {
  const queryClient = useQueryClient();
  var link = `https://omnistack.org${process.env.REACT_APP_LINK}`;
  //var link = "http://localhost:8000/api/";

  const { setOpenSubs } = useContext(GlobalContext);
  const accesstoken = getToken();
  const username = getUsername();
  const [body, setBody] = useState(tileData.body);
  const [chosenCurrency, setChosenCurrency] = useState(tileData.currency);

  const handleEditMessage = (e) => {
    e.preventDefault(); //prevents refreshing page
    const content = {
      id: tileData.id,
      created_at: tileData.created_at,
      subject: tileData.subject,
      SaturnTileRef: tileData.SaturnTileRef,
      body: body,
      author: tileData.author,
      currency: chosenCurrency,
    };

    console.log(content);

    fetch(link + "message-edit/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accesstoken,
      },
      user: JSON.stringify(content),
      SaturnTileRef: JSON.stringify(content),
      body: JSON.stringify(content),
      tileusername: JSON.stringify(content),
    }).then(async () => {
      await queryClient.invalidateQueries("openSubs");
      //setIsPending(false);
      //history.push('/messages');
    });
    //global.messagelist.push(content);

    closeOnClick();
  };

  //need to make onclickfunction to update message board feed when submit in messageboard component

  return (
    <div className="bg-white rounded-lg pb-2 font-bellota">
      <div className="font-bellota bg-gradient-to-t from-white to-yellow-50 border border-yellow-100 p-2 m-2 rounded-lg shadow-sm justify-items-center overflow-x-auto">
        <Cards tileData={tileData} />
      </div>

      <form className="flex flex-col items-center px-10 space-y-4">
        {/* <div className="col-span-2 flex-1 pl-2 text-center">
            <input
              id={"messagecontent "}
              type="text"
              className=" border-b border-yellow-300  text-base cursor-pointer w-3/4 px-2 m-2"
              defaultValue={tileData.subject}
              value={tileData.subject}
              onChange={(e) => setBody(e.target.value)}
              readOnly
            />
          </div> */}

        <div className=" flex flex-col items-start w-full  ">
          <label
            htmlFor="messagecontent"
            className="text-left px-2 text-sm text-gray-500"
          >
            Price
          </label>
          <input
            id={"messagecontent "}
            name={"messagecontent "}
            className="rounded-full   border border-yellow-300 shadow-sm text-xs cursor-pointer w-full p-2 "
            type="number"
            value={body}
            onChange={(e) => setBody(e.target.value)}
            required
            placeholder="Price per month"
            rows="1"
          />
        </div>

        <select
          class="rounded-full   border border-yellow-300 shadow-sm text-xs cursor-pointer w-full p-2  bg-white"
          required
          value={tileData.currency}
          onChange={(e) => setChosenCurrency(e.target.value)}
        >
          <option value="GBP">GBP</option>
          <option disabled value="EUR">
            EUR
          </option>
          <option disabled value="USD">
            USD
          </option>
        </select>
        <button
          className=" px-6 py-2 button rounded-full shadow-sm focus:outline-none cursor-pointer bg-gradient-to-r from-yellow-200 to-red-200 text-xs font-bold"
          onClick={handleEditMessage}
        >
          Update Infomation
        </button>
      </form>
    </div>
  );
};

export default EditMessagePopup;
