import React, {
  createContext,
  useReducer,
  useState,
  useEffect,
  useContext,
} from "react";
import Select from "react-select";
import { GlobalContext } from "./GlobalContext.js";
import "../styles/output.css";

import getToken from "./Token.js";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router";

var link = `https://omnistack.org${process.env.REACT_APP_LINK}`;
//var link = "http://localhost:8000/api/";

// export function last(array) {
//     return ( array[array.length - 1]);
// }

const Destination_tab = () => {
  const navigate = useNavigate();
  const {
    showFilteredData,
    setShowFilteredData,
    selectedConnectionOptions,
    setSelectedConnectionOptions,
    selectedHomeFilterSubsOptions,
    setSelectedHomeFilterSubsOptions,
  } = useContext(GlobalContext);
  const [options, setOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState(
    selectedHomeFilterSubsOptions
  );
  const [connectionOptions, setConnectionOptions] = useState([]);
  const [selectedConnectionOptionsLocal, setSelectedConnectionOptionsLocal] =
    useState(selectedConnectionOptions);
  useEffect(() => {
    //let [options1, setOptions1] = useState([]);//save data
    let accesstoken = getToken();
    // console.log(accesstoken);
    //source label
    fetch(link + "subject-list/", {
      method: "GET",
      headers: {
        Authorization: "Bearer " + accesstoken,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((result) => {
        setOptions(result?.slice(1));
        // console.log("options", result);
      });

    //connection options
    const optiondata = [
      { id: 1, connection: "first", value: "1st" },
      { id: 2, connection: "second", value: "2nd" },
      { id: 3, connection: "third", value: "3rd+" },
    ];
    setConnectionOptions(optiondata);
  }, []);

  const handleOptionChange = (selectedOptions) => {
    setSelectedOptions(selectedOptions);
    // console.log("Selectedoption", selectedOptions);
  };

  const handleConnectionChange = (selectedConnectionOptions) => {
    setSelectedConnectionOptionsLocal(selectedConnectionOptions);
    // console.log("Selectedoption", selectedConnectionOptions);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Filter the data based on selected options and update the global state with the filtered data
    setSelectedHomeFilterSubsOptions(selectedOptions);
    setSelectedConnectionOptions(selectedConnectionOptionsLocal);

    // console.log("filteredData", filteredData);
    setShowFilteredData(true);
  };

  const handleReset = () => {
    setShowFilteredData(false);
    setSelectedConnectionOptions([]);
    setSelectedConnectionOptionsLocal([]);
    setSelectedHomeFilterSubsOptions([]);
    setSelectedOptions([]);
  };

  // console.log(selectedConnectionOptionsLocal);
  // console.log(selectedOptions);

  //custom message for unavailable opton
  const customNoOptionsMessage = () => {
    return (
      <p>
        Can't find the sub you are looking for? Write to us{" "}
        <Link to="/support" className="text-red-800 font-bold underline">
          here.
        </Link>
      </p>
    );
  };

  return (
    <div className="absolute z-10 right-0 bg-white rounded-lg ">
      <div className="font-bellota px-4 leading-tight mb-4 border border-red-100 rounded-lg p-2 text-xs justify-between items-center flex-col">
        <div class=" text-bold text-lg text-black pb-2 ">Search</div>

        <form onSubmit={handleSubmit} className="flex flex-col space-y-3">
          <div className="">
            <p className=" text-xs ml-1">Subscription(s)</p>
            <Select
              className="w-56 text-xs"
              value={selectedOptions}
              onChange={handleOptionChange}
              options={options}
              placeholder={"select"}
              isMulti
              closeMenuOnSelect={false}
              autosize={false}
              noOptionsMessage={customNoOptionsMessage}
              getOptionValue={(option) => option.subjectname} //value is what it is
              getOptionLabel={(option) => option.subjectname} //label is what you see
            />
          </div>

          <div className="">
            <p className=" ml-1 text-xs">Connection(s)</p>
            <Select
              className="w-56 text-xs"
              value={selectedConnectionOptionsLocal}
              onChange={handleConnectionChange}
              options={connectionOptions}
              placeholder={"Select"}
              isMulti
              closeMenuOnSelect={false}
              autosize={false}
              getOptionValue={(option) => option.connection} //value is what it is
              getOptionLabel={(option) => option.value} //label is what you see
            />
          </div>

          <div className="flex items-center justify-around gap-2">
            <p
              onClick={handleReset}
              className="  px-5 py-1 rounded-full shadow-sm focus:outline-none cursor-pointer bg-gradient-to-r from-yellow-200 to-red-200 text-xs font-bold "
            >
              Reset
            </p>
            <button
              type="submit"
              className=" px-5 py-1  rounded-full shadow-sm focus:outline-none cursor-pointer bg-gradient-to-r from-yellow-200 to-red-200 text-xs font-bold "
            >
              Filter
            </button>
          </div>
        </form>

        {/*
      <div class=" mt-6 overflow-x-visible leading-tight text-xs flex-col">
         <div class="text-lg text-black pb-1 ">Destination</div> 
         
          {<Select
           
            value={ selectedOption2 }
            onChange={this.handleChange2}
            options={this.state.options2}
            placeholder={"Location(s)"}
            isMulti
            closeMenuOnSelect={false}
            autosize={false}
            getOptionValue={(option) => option.destinationname}
            getOptionLabel={(option) => option.destinationid}
       
            
          />}
      */}

        {/*
      <Link to="/messages">
      <button class = "button w-48 h-8 rounded-sm shadow-lg focus:outline-none cursor-pointer hover:bg-red-800 hover:text-white bg-gradient-to-r from-yellow-200 to-red-200 text-sm   flex-shrink"> 
        Add a Message</button>
      </Link>
      */}
      </div>
    </div>
  );
};

export default Destination_tab;
