import { useState, useEffect } from "react";

const useSubject = (url, authheader) => {
  const [data, setData] = useState(null);

  //{ signal: abortCont.signal } in 2nd param of fetch
  useEffect(() => {
    const abortCont = new AbortController();
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + authheader);

    fetch(url, {
      signal: abortCont.signal,
      headers: myHeaders,
    })
      .then((res) => {
        if (!res.ok) {
          throw Error("could not fetch the data for that resource");
        }
        return res.json();
      })
      .then((data) => {
        setData(data);
      })
      .catch((err) => {
        if (err.name === "AbortError") {
          console.log("fetch aborted");
        } else {
        }
      });

    return () => abortCont.abort();
  }, [url, authheader]);

  return { data };
};

export default useSubject;
