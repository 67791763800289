import React from "react";
import Support from "../Support/Support";

export default function Support_Page() {
  return (
    <div className="">
      <div
        class="content-wrapper"
        className="flex flex-col items-center justify-center pt-32 mx-5 max-w-md sm:mx-auto"
      >
        <h2 class="block uppercase tracking-wide text-gray-700 text-xl font-bold mb-2">
          How can we help you?
        </h2>
        <p>
          If you have any questions or need assistance, please tell us by
          filling out the form below and we will get back to you!
        </p>
      </div>
      {/* <section> */}
      <div class="flex max-w-md  mx-auto">
        <Support />
      </div>
    </div>
  );
}
