import React, { useContext } from "react";
import OpenCard from "./OpenCard";
import { GlobalContext } from "../../../GlobalContext";
import getUsername from "../../../Account_Infomation/Username";
import { useQuery } from "react-query";
import Endpoints from "../../../../api/Endpoints";

function OpenSubscription() {
  const curUser = getUsername();
  // fetch opensubs
  const {
    isLoading: isOpenSubsDataLoading,
    data: openSubs,
    isError: isOpenSubsError,
    error: openSubsErr,
  } = useQuery("openSubs", Endpoints.GetOpenSubscription, {
    // refetchOnWindowFocus: false,
  });

  if (isOpenSubsDataLoading) {
    return <p className="text-center p-2">Loading ...</p>;
  }

  if (isOpenSubsError) {
    return (
      <p className="text-center p-2">
        Something went wrong! Please try again later..
      </p>
    );
  }

  return (
    <div>
      <div
        id="column layout"
        className="flex flex-row max-w-2xl lg:max-w-3xl  mx-auto"
      >
        <div className="grid md:grid-cols-2 w-full">
          {openSubs !== null ? (
            openSubs
              ?.filter((el) => {
                return el.author == curUser;
              })
              .map((message) => <OpenCard key={message.id} message={message} />)
          ) : (
            <p>Loading data Please wait...</p>
          )}
        </div>
      </div>
    </div>
  );
}

export default OpenSubscription;
