import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import SpongeLogo from "../../../assets/sponge-logo.svg";
import { Auth } from "aws-amplify";
import { ToastContainer, toast } from "react-toastify";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Tooltip } from "@mui/material";
import Zoom from "@mui/material/Zoom";
import Modal from "react-modal";
function Register() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [passwordType, setPasswordType] = useState("password");
  const [loading, setLoading] = useState(false);
  const [isStrong, setIsStrong] = useState(false);

  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isPhoneValid, setIsPhoneValid] = useState(true);

  const [isPolicyModalOpen, setIsPolicyModalOpen] = useState(false);

  const validateEmail = () => {
    setIsEmailValid(
      /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email) // regex for email validation
    );
  };

  const validatePhone = () => {
    setIsPhoneValid(
      /^[\d\s()+-]+$/.test(phone) && phone.length >= 10 // regex for phone validation
    );
  };

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  function handleUsernameChange(event) {
    const trimmedUsername = event.target.value.trim();
    setUsername(trimmedUsername);
  }

  const handlePasswordChange = (event) => {
    const value = event.target.value;
    setPassword(value);

    // Regular expression to check if the password meets the required criteria
    const strongRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/;

    setIsStrong(strongRegex.test(value));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (loading) {
      return;
    }

    if (username.includes(" ")) {
      toast("Username should not contain spaces.");
      return;
    }
    if (isEmailValid && isPhoneValid) {
      setLoading(true);
      try {
        await Auth.signUp({
          username,
          password,
          attributes: { email, phone_number: `+${phone}` },
        });
        navigate("/verifyemail", {
          state: { username: username, email: email },
        });
      } catch (error) {
        toast(error.message);
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    if (state?.useremail) {
      setEmail(state?.useremail);
    }
  }, []);

  return (
    <div className="flex bg-white h-screen  font-fredoka ">
      <ToastContainer autoClose={2500} />
      <div className="flex flex-col justify-center items-center w-full max-w-md  mx-auto p-2">
        <div className=" border border-red-100 bg-white p-5 w-full shadow-md rounded-md">
          <div className=" flex flex-col items-center mb-5">
            <img src={SpongeLogo} alt="" className="w-60" />
            {/* <h4 className="text-3xl font-bold">Hello Again!</h4>
            <span className="py-4 text-xl text-center text-gray-500">
              Explore More by connecting with us.
            </span> */}
          </div>

          <div className="py-2">
            <h4 className="text-2xl w-3/4 mx-auto  text-left">Sign Up</h4>
          </div>

          <form className="py-4" onSubmit={handleSubmit}>
            <div className=" flex flex-col items-center gap-4">
              <div className="w-3/4">
                <div className="flex items-center gap-2">
                  <p className="text-left text-sm">
                    Username <span className="text-red-500">*</span>
                  </p>
                  <Tooltip
                    enterTouchDelay={0}
                    TransitionComponent={Zoom}
                    placement="top-start"
                    leaveTouchDelay={5000}
                    arrow
                    componentsProps={{
                      tooltip: {
                        sx: {
                          bgcolor: "rgb(254 243 199)",
                          fontFamily: "bellota",
                          "& .MuiTooltip-arrow": {
                            color: "#bfdbfe",
                          },
                        },
                      },
                    }}
                    title={
                      <span className="text-black">
                        Username should not contain spaces.
                      </span>
                    }
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      className="w-4 h-4"
                    >
                      <path
                        d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM11 15V17H13V15H11ZM13 13.3551C14.4457 12.9248 15.5 11.5855 15.5 10C15.5 8.067 13.933 6.5 12 6.5C10.302 6.5 8.88637 7.70919 8.56731 9.31346L10.5288 9.70577C10.6656 9.01823 11.2723 8.5 12 8.5C12.8284 8.5 13.5 9.17157 13.5 10C13.5 10.8284 12.8284 11.5 12 11.5C11.4477 11.5 11 11.9477 11 12.5V14H13V13.3551Z"
                        fill="rgb(245 218 112)"
                      ></path>
                    </svg>
                  </Tooltip>
                </div>
                <input
                  className="border border-yellow-100  px-5 p-2 w-full rounded-xl shadow-sm text-sm focus:outline-none"
                  type="text"
                  required
                  value={username}
                  onChange={handleUsernameChange}
                  placeholder="Enter your username"
                />
              </div>
              <div className="w-3/4">
                <div className="flex items-center gap-2 text-left text-sm">
                  <p>
                    {" "}
                    Password <span className="text-red-500">*</span>
                  </p>
                  <Tooltip
                    enterTouchDelay={0}
                    TransitionComponent={Zoom}
                    placement="top-start"
                    leaveTouchDelay={5000}
                    arrow
                    componentsProps={{
                      tooltip: {
                        sx: {
                          bgcolor: "rgb(254 243 199)",
                          fontFamily: "bellota",
                          "& .MuiTooltip-arrow": {
                            color: "#bfdbfe",
                          },
                        },
                      },
                    }}
                    title={
                      <span className="text-black">
                        Should contain at least
                        <li>one lowercase letter</li>
                        <li> one uppercase letter</li>
                        <li> one digit </li>
                        <li>and one special character {` (@$!%*?&)`}</li>
                        <li>Minimum length - 8</li>
                      </span>
                    }
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      className="w-4 h-4"
                    >
                      <path
                        d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM11 15V17H13V15H11ZM13 13.3551C14.4457 12.9248 15.5 11.5855 15.5 10C15.5 8.067 13.933 6.5 12 6.5C10.302 6.5 8.88637 7.70919 8.56731 9.31346L10.5288 9.70577C10.6656 9.01823 11.2723 8.5 12 8.5C12.8284 8.5 13.5 9.17157 13.5 10C13.5 10.8284 12.8284 11.5 12 11.5C11.4477 11.5 11 11.9477 11 12.5V14H13V13.3551Z"
                        fill="rgb(245 218 112)"
                      ></path>
                    </svg>
                  </Tooltip>
                </div>
                <div className="flex items-center justify-between border border-yellow-100 pr-2  w-full rounded-xl shadow-sm text-sm">
                  <input
                    className="flex pl-5 p-2 flex-grow focus:outline-none bg-transparent bg-none"
                    type={passwordType}
                    value={password}
                    required
                    onChange={handlePasswordChange}
                    placeholder="Enter your password"
                  />

                  <div
                    className="flex  items-center justify-end"
                    onClick={togglePassword}
                  >
                    {passwordType === "password" ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        className="w-4 "
                      >
                        <path d="M4.52047 5.93457L1.39366 2.80777L2.80788 1.39355L22.6069 21.1925L21.1927 22.6068L17.8827 19.2968C16.1814 20.3755 14.1638 21.0002 12.0003 21.0002C6.60812 21.0002 2.12215 17.1204 1.18164 12.0002C1.61832 9.62282 2.81932 7.5129 4.52047 5.93457ZM14.7577 16.1718L13.2937 14.7078C12.902 14.8952 12.4634 15.0002 12.0003 15.0002C10.3434 15.0002 9.00026 13.657 9.00026 12.0002C9.00026 11.537 9.10522 11.0984 9.29263 10.7067L7.82866 9.24277C7.30514 10.0332 7.00026 10.9811 7.00026 12.0002C7.00026 14.7616 9.23884 17.0002 12.0003 17.0002C13.0193 17.0002 13.9672 16.6953 14.7577 16.1718ZM7.97446 3.76015C9.22127 3.26959 10.5793 3.00016 12.0003 3.00016C17.3924 3.00016 21.8784 6.87992 22.8189 12.0002C22.5067 13.6998 21.8038 15.2628 20.8068 16.5925L16.947 12.7327C16.9821 12.4936 17.0003 12.249 17.0003 12.0002C17.0003 9.23873 14.7617 7.00016 12.0003 7.00016C11.7514 7.00016 11.5068 7.01833 11.2677 7.05343L7.97446 3.76015Z"></path>
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        className="w-4"
                      >
                        <path d="M1.18164 12C2.12215 6.87976 6.60812 3 12.0003 3C17.3924 3 21.8784 6.87976 22.8189 12C21.8784 17.1202 17.3924 21 12.0003 21C6.60812 21 2.12215 17.1202 1.18164 12ZM12.0003 17C14.7617 17 17.0003 14.7614 17.0003 12C17.0003 9.23858 14.7617 7 12.0003 7C9.23884 7 7.00026 9.23858 7.00026 12C7.00026 14.7614 9.23884 17 12.0003 17ZM12.0003 15C10.3434 15 9.00026 13.6569 9.00026 12C9.00026 10.3431 10.3434 9 12.0003 9C13.6571 9 15.0003 10.3431 15.0003 12C15.0003 13.6569 13.6571 15 12.0003 15Z"></path>
                      </svg>
                    )}
                  </div>
                </div>
                {/* {password !== "" && (
                  <p className="text-xs ">
                    Password Strength:{" "}
                    {isStrong ? (
                      <span style={{ color: "green" }}>Strong</span>
                    ) : (
                      <span style={{ color: "red" }}>Weak</span>
                    )}
                  </p>
                )} */}
              </div>
              <div className=" w-3/4">
                <p className="text-left text-sm ">
                  Email <span className="text-red-500">*</span>
                </p>
                <input
                  className={`border-yellow-100
                   border   px-5 p-2 w-full rounded-xl shadow-sm text-sm focus:outline-none `}
                  type="text"
                  value={email}
                  required
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Enter your email"
                  onBlur={validateEmail}
                />
              </div>
              <div className="w-3/4">
                <p className="text-left text-sm ">
                  Phone Number <span className="text-red-500">*</span>{" "}
                </p>

                <PhoneInput
                  className="focus:outline-none"
                  // type="number"
                  // placeholder="(555) 555-1212"
                  inputStyle={{
                    width: "100%",

                    borderColor: "#bfdbfe",
                  }}
                  containerStyle={{
                    margin: "2px",
                    borderColor: "#bfdbfe",
                  }}
                  buttonStyle={{ borderColor: "#bfdbfe" }}
                  value={phone}
                  country={"gb"}
                  onChange={(e) => setPhone(e)}
                  onBlur={validatePhone}
                />
              </div>
              <button
                className="rounded-lg px-4 py-1 w-3/4 mt-2 bg-gradient-to-r from-yellow-200 to-red-200  transform active:scale-95 text-red-600 text-xl shadow-sm text-center"
                type="submit"
              >
                {loading ? "loading.." : "Sign Up"}
              </button>
            </div>

            <div className="flex flex-col items-center mt-3">
              <p className={"text-xs"}>
                By registering, you confirm that you accept our{" "}
                <span
                  className={"text-xs text-red-400 cursor-pointer"}
                  onClick={() => setIsPolicyModalOpen(!isPolicyModalOpen)}
                >
                  Privacy Policy.
                </span>
              </p>
            </div>

            <div className="text-center text-sm py-4">
              <span className="text-gray-500">
                Have an Account?{" "}
                <Link className="text-red-500" to="/login">
                  Sign In Now
                </Link>
              </span>
            </div>
          </form>
        </div>
      </div>

      {/* policy Modal */}
      <Modal
        isOpen={isPolicyModalOpen}
        className=" max-w-xl z-50  p-2  mx-5 sm:mx-auto mt-10 h-5/6 rounded-lg focus:outline-none  shadow-md text-center"
        overlayClassName="bg-black z-50 bg-opacity-30 fixed inset-0 overflow-y-auto"
        onRequestClose={() => setIsPolicyModalOpen(false)}
      >
        <button
          onClick={() => setIsPolicyModalOpen(false)}
          className="flex justify-start mb-1 px-2 pb-1 button shadow-md focus:outline-none cursor-pointer bg-[#00D32F] text-[#000F99] rounded-md text-sm  flex-shrink"
        >
          x
        </button>
        <div className="bg-white  py-2 px-5 flex flex-col items-start font-fredoka overflow-y-scroll h-full rounded-md text-xs md:text-sm ">
          <h1 className="font-semibold pt-5 pb-2 text-xl">Privacy Policy</h1>
          <p className="text-justify py-1">Last updated: June 26, 2023</p>
          <p className="text-justify py-1">
            This Privacy Policy describes Our policies and procedures on the
            collection, use and disclosure of Your information when You use the
            Service and tells You about Your privacy rights and how the law
            protects You.
          </p>
          <p className="text-justify py-1">
            We use Your Personal data to provide and improve the Service. By
            using the Service, You agree to the collection and use of
            information in accordance with this Privacy Policy. This Privacy
            Policy has been created with the help of the{" "}
            <a
              href="https://www.privacypolicies.com/privacy-policy-generator/"
              target="_blank"
            >
              Privacy Policy Generator
            </a>
            .
          </p>
          <h1 className="font-semibold pt-5 pb-2 text-xl">
            Interpretation and Definitions
          </h1>
          <h2 className="font-semibold text-sm ">Interpretation</h2>
          <p className="text-justify py-1">
            The words of which the initial letter is capitalized have meanings
            defined under the following conditions. The following definitions
            shall have the same meaning regardless of whether they appear in
            singular or in plural.
          </p>
          <h2 className="font-semibold text-sm ">Definitions</h2>
          <p className="text-justify py-1">
            For the purposes of this Privacy Policy:
          </p>
          <ul className="list-disc p-5">
            <li>
              <p className="text-justify py-1">
                <strong>Account</strong> means a unique account created for You
                to access our Service or parts of our Service.
              </p>
            </li>
            <li>
              <p className="text-justify py-1">
                <strong>Affiliate</strong> means an entity that controls, is
                controlled by or is under common control with a party, where
                &quot;control&quot; means ownership of 50% or more of the
                shares, equity interest or other securities entitled to vote for
                election of directors or other managing authority.
              </p>
            </li>
            <li>
              <p className="text-justify py-1">
                <strong>Application</strong> refers to Sponge, the software
                program provided by the Company.
              </p>
            </li>
            <li>
              <p className="text-justify py-1">
                <strong>Company</strong> (referred to as either &quot;the
                Company&quot;, &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot;
                in this Agreement) refers to General Enterprises , 65 Pendant
                Court, E16 2TG, London, UK.
              </p>
            </li>
            <li>
              <p className="text-justify py-1">
                <strong>Cookies</strong> are small files that are placed on Your
                computer, mobile device or any other device by a website,
                containing the details of Your browsing history on that website
                among its many uses.
              </p>
            </li>
            <li>
              <p className="text-justify py-1">
                <strong>Country</strong> refers to: United Kingdom
              </p>
            </li>
            <li>
              <p className="text-justify py-1">
                <strong>Device</strong> means any device that can access the
                Service such as a computer, a cellphone or a digital tablet.
              </p>
            </li>
            <li>
              <p className="text-justify py-1">
                <strong>Personal Data</strong> is any information that relates
                to an identified or identifiable individual.
              </p>
            </li>
            <li>
              <p className="text-justify py-1">
                <strong>Service</strong> refers to the Application or the
                Website or both.
              </p>
            </li>
            <li>
              <p className="text-justify py-1">
                <strong>Service Provider</strong> means any natural or legal
                person who processes the data on behalf of the Company. It
                refers to third-party companies or individuals employed by the
                Company to facilitate the Service, to provide the Service on
                behalf of the Company, to perform services related to the
                Service or to assist the Company in analyzing how the Service is
                used.
              </p>
            </li>
            <li>
              <p className="text-justify py-1">
                <strong>Usage Data</strong> refers to data collected
                automatically, either generated by the use of the Service or
                from the Service infrastructure itself (for example, the
                duration of a page visit).
              </p>
            </li>
            <li>
              <p className="text-justify py-1">
                <strong>Website</strong> refers to Sponge, accessible from{" "}
                <a
                  href="www.sponge-app.com"
                  rel="external nofollow noopener"
                  target="_blank"
                  className="text-blue-700"
                >
                  www.sponge-app.com
                </a>
              </p>
            </li>
            <li>
              <p className="text-justify py-1">
                <strong>You</strong> means the individual accessing or using the
                Service, or the company, or other legal entity on behalf of
                which such individual is accessing or using the Service, as
                applicable.
              </p>
            </li>
          </ul>
          <h1 className="font-semibold pt-5 pb-2  text-xl">
            Collecting and Using Your Personal Data
          </h1>
          <h2 className="font-semibold text-sm ">Types of Data Collected</h2>
          <h3 className="font-semibold">Personal Data</h3>
          <p className="text-justify py-1">
            While using Our Service, We may ask You to provide Us with certain
            personally identifiable information that can be used to contact or
            identify You. Personally identifiable information may include, but
            is not limited to:
          </p>
          <ul className="list-disc p-5">
            <li>
              <p className="text-justify">Email address</p>
            </li>
            <li>
              <p className="text-justify">First name and last name</p>
            </li>
            <li>
              <p className="text-justify">Usage Data</p>
            </li>
          </ul>
          <h3 className="font-semibold">Usage Data</h3>
          <p className="text-justify py-1">
            Usage Data is collected automatically when using the Service.
          </p>
          <p className="text-justify py-1">
            Usage Data may include information such as Your Device's Internet
            Protocol address (e.g. IP address), browser type, browser version,
            the pages of our Service that You visit, the time and date of Your
            visit, the time spent on those pages, unique device identifiers and
            other diagnostic data.
          </p>
          <p className="text-justify py-1">
            When You access the Service by or through a mobile device, We may
            collect certain information automatically, including, but not
            limited to, the type of mobile device You use, Your mobile device
            unique ID, the IP address of Your mobile device, Your mobile
            operating system, the type of mobile Internet browser You use,
            unique device identifiers and other diagnostic data.
          </p>
          <p className="text-justify py-1">
            We may also collect information that Your browser sends whenever You
            visit our Service or when You access the Service by or through a
            mobile device.
          </p>
          <h3 className="font-semibold">
            Information Collected while Using the Application
          </h3>
          <p className="text-justify py-1">
            While using Our Application, in order to provide features of Our
            Application, We may collect, with Your prior permission:
          </p>
          <ul className="list-disc p-5">
            <li>Information regarding your location</li>
          </ul>
          <p className="text-justify py-1">
            We use this information to provide features of Our Service, to
            improve and customize Our Service. The information may be uploaded
            to the Company's servers and/or a Service Provider's server or it
            may be simply stored on Your device.
          </p>
          <p className="text-justify py-1">
            You can enable or disable access to this information at any time,
            through Your Device settings.
          </p>
          <h3 className="font-semibold">Tracking Technologies and Cookies</h3>
          <p className="text-justify py-1">
            We use Cookies and similar tracking technologies to track the
            activity on Our Service and store certain information. Tracking
            technologies used are beacons, tags, and scripts to collect and
            track information and to improve and analyze Our Service. The
            technologies We use may include:
          </p>
          <ul className="list-disc p-5">
            <li className="text-justify py-1">
              <strong>Cookies or Browser Cookies.</strong> A cookie is a small
              file placed on Your Device. You can instruct Your browser to
              refuse all Cookies or to indicate when a Cookie is being sent.
              However, if You do not accept Cookies, You may not be able to use
              some parts of our Service. Unless you have adjusted Your browser
              setting so that it will refuse Cookies, our Service may use
              Cookies.
            </li>
            <li className="text-justify py-1">
              <strong>Web Beacons.</strong> Certain sections of our Service and
              our emails may contain small electronic files known as web beacons
              (also referred to as clear gifs, pixel tags, and single-pixel
              gifs) that permit the Company, for example, to count users who
              have visited those pages or opened an email and for other related
              website statistics (for example, recording the popularity of a
              certain section and verifying system and server integrity).
            </li>
          </ul>
          <p className="text-justify py-1">
            Cookies can be &quot;Persistent&quot; or &quot;Session&quot;
            Cookies. Persistent Cookies remain on Your personal computer or
            mobile device when You go offline, while Session Cookies are deleted
            as soon as You close Your web browser. Learn more about cookies on
            the{" "}
            <a
              href="https://www.privacypolicies.com/blog/privacy-policy-template/#Use_Of_Cookies_Log_Files_And_Tracking"
              target="_blank"
            >
              Privacy Policies website
            </a>{" "}
            article.
          </p>
          <p className="text-justify py-1">
            We use both Session and Persistent Cookies for the purposes set out
            below:
          </p>
          <ul className="list-disc p-5">
            <li>
              <p className="text-justify py-1">
                <strong>Necessary / Essential Cookies</strong>
              </p>
              <p className="text-justify py-1">Type: Session Cookies</p>
              <p className="text-justify py-1">Administered by: Us</p>
              <p className="text-justify py-1">
                Purpose: These Cookies are essential to provide You with
                services available through the Website and to enable You to use
                some of its features. They help to authenticate users and
                prevent fraudulent use of user accounts. Without these Cookies,
                the services that You have asked for cannot be provided, and We
                only use these Cookies to provide You with those services.
              </p>
            </li>
            <li>
              <p className="text-left py-1">
                <strong>Cookies Policy / Notice Acceptance Cookies</strong>
              </p>
              <p className="text-justify py-1">Type: Persistent Cookies</p>
              <p className="text-justify py-1">Administered by: Us</p>
              <p className="text-justify py-1">
                Purpose: These Cookies identify if users have accepted the use
                of cookies on the Website.
              </p>
            </li>
            <li>
              <p className="text-justify py-1">
                <strong>Functionality Cookies</strong>
              </p>
              <p className="text-justify py-1">Type: Persistent Cookies</p>
              <p className="text-justify py-1">Administered by: Us</p>
              <p className="text-justify py-1">
                Purpose: These Cookies allow us to remember choices You make
                when You use the Website, such as remembering your login details
                or language preference. The purpose of these Cookies is to
                provide You with a more personal experience and to avoid You
                having to re-enter your preferences every time You use the
                Website.
              </p>
            </li>
          </ul>
          <p className="text-justify py-1">
            For more information about the cookies we use and your choices
            regarding cookies, please visit our Cookies Policy or the Cookies
            section of our Privacy Policy.
          </p>
          <h2 className="font-semibold text-sm ">Use of Your Personal Data</h2>
          <p className="text-justify py-1">
            The Company may use Personal Data for the following purposes:
          </p>
          <ul className="list-disc p-5">
            <li>
              <p className="text-justify py-1">
                <strong>To provide and maintain our Service</strong>, including
                to monitor the usage of our Service.
              </p>
            </li>
            <li>
              <p className="text-justify py-1">
                <strong>To manage Your Account:</strong> to manage Your
                registration as a user of the Service. The Personal Data You
                provide can give You access to different functionalities of the
                Service that are available to You as a registered user.
              </p>
            </li>
            <li>
              <p className="text-justify py-1">
                <strong>For the performance of a contract:</strong> the
                development, compliance and undertaking of the purchase contract
                for the products, items or services You have purchased or of any
                other contract with Us through the Service.
              </p>
            </li>
            <li>
              <p className="text-justify py-1">
                <strong>To contact You:</strong> To contact You by email,
                telephone calls, SMS, or other equivalent forms of electronic
                communication, such as a mobile application's push notifications
                regarding updates or informative communications related to the
                functionalities, products or contracted services, including the
                security updates, when necessary or reasonable for their
                implementation.
              </p>
            </li>
            <li>
              <p className="text-justify py-1">
                <strong>To provide You</strong> with news, special offers and
                general information about other goods, services and events which
                we offer that are similar to those that you have already
                purchased or enquired about unless You have opted not to receive
                such information.
              </p>
            </li>
            <li>
              <p className="text-justify py-1">
                <strong>To manage Your requests:</strong> To attend and manage
                Your requests to Us.
              </p>
            </li>
            <li>
              <p className="text-justify py-1">
                <strong>For business transfers:</strong> We may use Your
                information to evaluate or conduct a merger, divestiture,
                restructuring, reorganization, dissolution, or other sale or
                transfer of some or all of Our assets, whether as a going
                concern or as part of bankruptcy, liquidation, or similar
                proceeding, in which Personal Data held by Us about our Service
                users is among the assets transferred.
              </p>
            </li>
            <li>
              <p className="text-justify py-1">
                <strong>For other purposes</strong>: We may use Your information
                for other purposes, such as data analysis, identifying usage
                trends, determining the effectiveness of our promotional
                campaigns and to evaluate and improve our Service, products,
                services, marketing and your experience.
              </p>
            </li>
          </ul>
          <p className="text-justify py-1">
            We may share Your personal information in the following situations:
          </p>
          <ul className="list-disc p-5">
            <li className="text-justify py-1">
              <strong>With Service Providers:</strong> We may share Your
              personal information with Service Providers to monitor and analyze
              the use of our Service, to contact You.
            </li>
            <li className="text-justify py-1">
              <strong>For business transfers:</strong> We may share or transfer
              Your personal information in connection with, or during
              negotiations of, any merger, sale of Company assets, financing, or
              acquisition of all or a portion of Our business to another
              company.
            </li>
            <li className="text-justify py-1">
              <strong>With Affiliates:</strong> We may share Your information
              with Our affiliates, in which case we will require those
              affiliates to honor this Privacy Policy. Affiliates include Our
              parent company and any other subsidiaries, joint venture partners
              or other companies that We control or that are under common
              control with Us.
            </li>
            <li className="text-justify py-1">
              <strong>With business partners:</strong> We may share Your
              information with Our business partners to offer You certain
              products, services or promotions.
            </li>
            <li className="text-justify py-1">
              <strong>With other users:</strong> when You share personal
              information or otherwise interact in the public areas with other
              users, such information may be viewed by all users and may be
              publicly distributed outside.
            </li>
            <li className="text-justify py-1">
              <strong>With Your consent</strong>: We may disclose Your personal
              information for any other purpose with Your consent.
            </li>
          </ul>
          <h2 className="font-semibold text-sm ">
            Retention of Your Personal Data
          </h2>
          <p className="text-justify py-1">
            The Company will retain Your Personal Data only for as long as is
            necessary for the purposes set out in this Privacy Policy. We will
            retain and use Your Personal Data to the extent necessary to comply
            with our legal obligations (for example, if we are required to
            retain your data to comply with applicable laws), resolve disputes,
            and enforce our legal agreements and policies.
          </p>
          <p className="text-justify py-1">
            The Company will also retain Usage Data for internal analysis
            purposes. Usage Data is generally retained for a shorter period of
            time, except when this data is used to strengthen the security or to
            improve the functionality of Our Service, or We are legally
            obligated to retain this data for longer time periods.
          </p>
          <h2 className="font-semibold text-sm ">
            Transfer of Your Personal Data
          </h2>
          <p className="text-justify py-1">
            Your information, including Personal Data, is processed at the
            Company's operating offices and in any other places where the
            parties involved in the processing are located. It means that this
            information may be transferred to — and maintained on — computers
            located outside of Your state, province, country or other
            governmental jurisdiction where the data protection laws may differ
            than those from Your jurisdiction.
          </p>
          <p className="text-justify py-1">
            Your consent to this Privacy Policy followed by Your submission of
            such information represents Your agreement to that transfer.
          </p>
          <p className="text-justify py-1">
            The Company will take all steps reasonably necessary to ensure that
            Your data is treated securely and in accordance with this Privacy
            Policy and no transfer of Your Personal Data will take place to an
            organization or a country unless there are adequate controls in
            place including the security of Your data and other personal
            information.
          </p>
          <h2 className="font-semibold text-sm ">Delete Your Personal Data</h2>
          <p className="text-justify py-1">
            You have the right to delete or request that We assist in deleting
            the Personal Data that We have collected about You.
          </p>
          <p className="text-justify py-1">
            Our Service may give You the ability to delete certain information
            about You from within the Service.
          </p>
          <p className="text-justify py-1">
            You may update, amend, or delete Your information at any time by
            signing in to Your Account, if you have one, and visiting the
            account settings section that allows you to manage Your personal
            information. You may also contact Us to request access to, correct,
            or delete any personal information that You have provided to Us.
          </p>
          <p className="text-justify py-1">
            Please note, however, that We may need to retain certain information
            when we have a legal obligation or lawful basis to do so.
          </p>
          <h2 className="font-semibold text-sm ">
            Disclosure of Your Personal Data
          </h2>
          <h3 className="font-semibold">Business Transactions</h3>
          <p className="text-justify py-1">
            If the Company is involved in a merger, acquisition or asset sale,
            Your Personal Data may be transferred. We will provide notice before
            Your Personal Data is transferred and becomes subject to a different
            Privacy Policy.
          </p>
          <h3 className="font-semibold">Law enforcement</h3>
          <p className="text-justify py-1">
            Under certain circumstances, the Company may be required to disclose
            Your Personal Data if required to do so by law or in response to
            valid requests by public authorities (e.g. a court or a government
            agency).
          </p>
          <h3 className="font-semibold">Other legal requirements</h3>
          <p className="text-justify py-1">
            The Company may disclose Your Personal Data in the good faith belief
            that such action is necessary to:
          </p>
          <ul className="list-disc p-5">
            <li className="text-justify py-1">
              Comply with a legal obligation
            </li>
            <li className="text-justify py-1">
              Protect and defend the rights or property of the Company
            </li>
            <li className="text-justify py-1">
              Prevent or investigate possible wrongdoing in connection with the
              Service
            </li>
            <li className="text-justify py-1">
              Protect the personal safety of Users of the Service or the public
            </li>
            <li className="text-justify py-1">
              Protect against legal liability
            </li>
          </ul>
          <h2 className="font-semibold text-sm ">
            Security of Your Personal Data
          </h2>
          <p className="text-justify py-1">
            The security of Your Personal Data is important to Us, but remember
            that no method of transmission over the Internet, or method of
            electronic storage is 100% secure. While We strive to use
            commercially acceptable means to protect Your Personal Data, We
            cannot guarantee its absolute security.
          </p>
          <h1 className="font-semibold pt-5 pb-2  text-xl">
            Children's Privacy
          </h1>
          <p className="text-justify py-1">
            Our Service does not address anyone under the age of 13. We do not
            knowingly collect personally identifiable information from anyone
            under the age of 13. If You are a parent or guardian and You are
            aware that Your child has provided Us with Personal Data, please
            contact Us. If We become aware that We have collected Personal Data
            from anyone under the age of 13 without verification of parental
            consent, We take steps to remove that information from Our servers.
          </p>
          <p className="text-justify py-1">
            If We need to rely on consent as a legal basis for processing Your
            information and Your country requires consent from a parent, We may
            require Your parent's consent before We collect and use that
            information.
          </p>
          <h1 className="font-semibold pt-5 pb-2  text-xl">
            Links to Other Websites
          </h1>
          <p className="text-justify py-1">
            Our Service may contain links to other websites that are not
            operated by Us. If You click on a third party link, You will be
            directed to that third party's site. We strongly advise You to
            review the Privacy Policy of every site You visit.
          </p>
          <p className="text-justify py-1">
            We have no control over and assume no responsibility for the
            content, privacy policies or practices of any third party sites or
            services.
          </p>
          <h1 className="font-semibold pt-5 pb-2 text-xl">
            Changes to this Privacy Policy
          </h1>
          <p className="text-justify py-1">
            We may update Our Privacy Policy from time to time. We will notify
            You of any changes by posting the new Privacy Policy on this page.
          </p>
          <p className="text-justify py-1">
            We will let You know via email and/or a prominent notice on Our
            Service, prior to the change becoming effective and update the
            &quot;Last updated&quot; date at the top of this Privacy Policy.
          </p>
          <p className="text-justify py-1">
            You are advised to review this Privacy Policy periodically for any
            changes. Changes to this Privacy Policy are effective when they are
            posted on this page.
          </p>
          <h1 className="font-semibold pt-5 pb-2 text-xl">Contact Us</h1>
          <p className="text-justify py-1">
            If you have any questions about this Privacy Policy, You can contact
            us:
          </p>
          <ul className="list-disc p-5">
            <li className="text-left py-1">
              By email:{" "}
              <a
                href="/cdn-cgi/l/email-protection"
                class="__cf_email__"
                data-cfemail="4f2c20213b2e2c3b0f282a212a3d2e232a213b2a3d3f3d263c2a3c612c20613a24"
              >
                contact@generalenterprises.co.uk
              </a>
            </li>
          </ul>
        </div>
      </Modal>
    </div>
  );
}

export default Register;
