import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import SpongeLogo from "../../../assets/sponge-logo.svg";
import { Auth } from "aws-amplify";
import { ToastContainer, toast } from "react-toastify";

function ResetPassword() {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (loading) {
      return;
    }
    setLoading(true);
    try {
      await Auth.forgotPassword(username);
      navigate("/newpassword", { state: { username: username } });
    } catch (error) {
      toast(error.message);
    }
    setLoading(false);
  };

  return (
    <div className="flex h-screen font-fredoka ">
      <ToastContainer autoClose={2500} />
      <div className="flex flex-col justify-center items-center w-full max-w-md  mx-auto p-2">
        <div className=" border border-red-100 bg-white p-5 w-full shadow-md rounded-md">
          <div className=" flex flex-col items-center mb-5">
            <img src={SpongeLogo} alt="" className="w-60" />
            {/* <h4 className="text-3xl font-bold">Hello Again!</h4>
            <span className="py-4 text-xl text-center text-gray-500">
              Explore More by connecting with us.
            </span> */}
          </div>
          <div className="py-2">
            <h4 className="text-2xl  text-left w-3/4 mx-auto">
              Reset Your Password
            </h4>
          </div>

          <form className="py-4" onSubmit={handleSubmit}>
            <div className=" flex flex-col items-center gap-5">
              <div className="w-3/4">
                <p className="text-left">Username *</p>
                <input
                  className="border border-yellow-100  px-5 p-2 w-full rounded-xl shadow-sm text-sm focus:outline-none"
                  type="text"
                  required
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  placeholder="Username"
                />
              </div>
              <button
                className="rounded-lg px-4 py-1 w-3/4 mt-2 bg-gradient-to-r from-yellow-200 to-red-200 transform active:scale-95 text-red-600 text-xl shadow-sm text-center"
                type="submit"
              >
                {loading ? "loading.." : "Send Code"}
              </button>
            </div>

            <div className="text-center py-4">
              <span className="text-gray-500">
                <Link className="" to="/login">
                  Back to sign In
                </Link>
              </span>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;
