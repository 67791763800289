import React, { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../GlobalContext";
import SharedCard from "./shared/SharedCard";
import SubscribedCard from "./subs/SubscribedCard";
import OpenCard from "./open/OpenCard";
import getToken from "../../Token";
import getUsername from "../../Account_Infomation/Username";
import { useQuery } from "react-query";

function FilterSubscription() {
  const accesstoken = getToken();
  const curUser = getUsername();

  const {
    setUsermessagefeed,
    selectedCustomOptions,
    setSelectedCustomOptions,
    selecteSubDate,
    setSelectedSubDate,
    selectedSubsOptions,
    setSelectedSubsOptions,
  } = useContext(GlobalContext);

  const fetchFilteredData = async (pageNo) => {
    try {
      const response = await fetch(
        `https://omnistack.org${process.env.REACT_APP_LINK}sponge-filter-list/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + accesstoken,
          },
          body: JSON.stringify({
            user: curUser,
            topics: selectedSubsOptions
              ?.map((item) => item.subjectname)
              .toString(),
            options: selectedCustomOptions
              ?.map((item) => item.value)
              .toString(),
            date: selecteSubDate || "",
          }),
        }
      );
      const json = await response.json();
      return json;
    } catch (error) {
      console.log("Error", error);
    }
  };

  // fetch filtered data
  const {
    isLoading: isFliteredSubsLoading,
    data: fliteredSubsData,
    isError: isFilteredSubsError,
    error: filteredSubsErr,
    isFetching: isFilteredSubsDataFetching,
    isPreviousData: isFilteredPrevSubsData,
    refetch: refetchFilteredSubsData,
  } = useQuery("filteredSubsData", fetchFilteredData, {
    keepPreviousData: true,
  });

  useEffect(() => {
    if (
      selectedSubsOptions?.length > 0 ||
      selectedCustomOptions?.length > 0 ||
      selecteSubDate !== ""
    ) {
      refetchFilteredSubsData();
    }
  }, [selectedSubsOptions, selectedCustomOptions, selecteSubDate]);

  return (
    <div className="flex max-w-2xl lg:max-w-3xl  mx-auto  ">
      <div className="grid md:grid-cols-2  w-full ">
        {fliteredSubsData?.length > 0 ? (
          fliteredSubsData
            .slice(0)
            .reverse()
            .map((message, index) => (
              <div key={message.id}>
                {/* Open-posts */}
                {message?.Tile_status === "Open" && (
                  <OpenCard message={message} index={index} />
                )}
                {/* Shared-posts */}
                {message?.Tile_status === "Subscribed" &&
                  message?.author === curUser && (
                    <SharedCard message={message} index={index} />
                  )}

                {/* Subscribed posts */}
                {message?.Tile_status === "Subscribed" &&
                  message?.author !== curUser && (
                    <SubscribedCard message={message} index={index} />
                  )}
              </div>
            ))
        ) : (
          <p className="mt-10">Oops No data available...</p>
        )}
      </div>
    </div>
  );
}

export default FilterSubscription;
