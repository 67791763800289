import React, { useContext } from "react";
import getUsername from "../../../Account_Infomation/Username";
import { GlobalContext } from "../../../GlobalContext";
import SharedCard from "./SharedCard";
import Endpoints from "../../../../api/Endpoints";
import { useQuery } from "react-query";

function SharedSubscription() {
  var link = `https://omnistack.org${process.env.REACT_APP_LINK}`;
  const CurUsername = getUsername();

  // fetch sharedsubs
  const {
    isLoading: isSharedSubsDataLoading,
    data: sharedSubs,
    isError: isSharedSubsError,
    error: sharedSubsErr,
  } = useQuery("sharedSubs", Endpoints.GetSharedSubscription, {
    // refetchOnWindowFocus: false,
  });

  if (isSharedSubsDataLoading) {
    return <p className="text-center p-2">Loading ...</p>;
  }

  if (isSharedSubsError) {
    return (
      <p className="text-center p-2">
        Something went wrong! Please try again later..
      </p>
    );
  }

  return (
    <div>
      <div
        id="column layout"
        className="flex flex-row  max-w-2xl lg:max-w-3xl  mx-auto"
      >
        <div className="grid md:grid-cols-2  w-full ">
          {sharedSubs !== null ? (
            sharedSubs
              .filter((el) => {
                return el.author == CurUsername;
              })
              .map((message) => (
                <SharedCard key={message.id} message={message} />
              ))
          ) : (
            <p>Loading data Please wait...</p>
          )}
        </div>
      </div>
    </div>
  );
}

export default SharedSubscription;
