import React from "react";
import { useState, useEffect, useContext } from "react";
import getToken from "../Token";
import getUsername from "../Account_Infomation/Username";
import { GlobalContext } from "../GlobalContext";
import fernet from "fernet";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const CashoutPage = () => {
  const { notsocket } = useContext(GlobalContext);
  const username = getUsername();
  const [cre, setCre] = useState("");
  const accesstoken = getToken();
  const [wise_exist, setWise_exist] = useState("");
  const [bank_details, setBank_details] = useState("");
  const [currency, setCurrency] = useState("GBP");
  const [acc_name, setAcc_name] = useState("");
  const [account_number, setAccount_number] = useState("");
  const [sortcode, setSortcode] = useState("");
  const [email, setEmail] = useState("");
  const [iban, setIban] = useState("");
  const [abartn, setAbartn] = useState("");
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [postcode, setPostcode] = useState("");
  const [address, setAddress] = useState("");
  const [transaction, setTransaction] = useState(null);

  //payment tab toggle
  const [isPaymentTabOpen, setIsPaymentTabOpen] = useState(true);
  const [credits, setCredits] = useState("");
  var link = `https://omnistack.org${process.env.REACT_APP_LINK}`;
  const getCredits = async () => {
    try {
      const response = await fetch(link + "credits/", {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: "Bearer " + accesstoken,
        },
        body: JSON.stringify({
          user: getUsername(),
        }),
      });
      const json = await response.json();
      if (json) {
        setCredits(json);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleSumbit = (e) => {
    e.preventDefault(); //prevents refreshing page
    setCre("");
  };
  const handleSumbit1 = (e) => {
    e.preventDefault(); //prevents refreshing page
    let accesstoken = getToken();
    var secret = new fernet.Secret(process.env.REACT_APP_FERNET_KEY);
    var token = new fernet.Token({
      secret: secret,
    });
    if (currency === "EUR") {
      fetch(link + "create-wise-account/", {
        method: "POST",
        body: JSON.stringify({
          user: token.encode(getUsername()),
          acc_name: token.encode(acc_name),
          email: token.encode(email),
          iban: token.encode(iban),
          currency: token.encode("EUR"),
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: "Bearer " + accesstoken,
        },
      })
        .then((response) => response.json())
        .then((json) => {
          if (json["Notification-text"] == "Account Added") {
            setWise_exist("yes");
          }
          toast(JSON.stringify(json["Notification-text"]).replace(/"|'/g, ""));
          notsocket.send(
            JSON.stringify({
              user: username,
            })
          );
        });
    }
    if (currency === "GBP") {
      fetch(link + "create-wise-account/", {
        method: "POST",
        body: JSON.stringify({
          user: token.encode(getUsername()),
          acc_name: token.encode(acc_name),
          email: token.encode(email),
          account_number: token.encode(account_number),
          sortcode: token.encode(sortcode),
          currency: token.encode("GBP"),
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: "Bearer " + accesstoken,
        },
      })
        .then((response) => response.json())
        .then((json) => {
          if (json["Notification-text"] == "Account Added") {
            setWise_exist("yes");
          }
          toast(JSON.stringify(json["Notification-text"]).replace(/"|'/g, ""));
          notsocket.send(
            JSON.stringify({
              user: username,
            })
          );
        });
    }
    if (currency === "USD") {
      fetch(link + "create-wise-account/", {
        method: "POST",
        body: JSON.stringify({
          user: token.encode(getUsername()),
          acc_name: token.encode(acc_name),
          email: token.encode(email),
          account_number: token.encode(account_number),
          abartn: token.encode(abartn),
          city: token.encode(city),
          country: token.encode(country),
          postcode: token.encode(postcode),
          firstLine: token.encode(address),
          currency: token.encode("USD"),
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: "Bearer " + accesstoken,
        },
      })
        .then((response) => response.json())
        .then((json) => {
          if (json["Notification-text"] == "Account Added") {
            setWise_exist("yes");
          }
          toast(JSON.stringify(json["Notification-text"]).replace(/"|'/g, ""));
          notsocket.send(
            JSON.stringify({
              user: username,
            })
          );
        });
    }
  };
  const getWiseAccountDetails = async () => {
    try {
      var secret = new fernet.Secret(process.env.REACT_APP_FERNET_KEY);
      var token = new fernet.Token({
        secret: secret,
      });
      const response = await fetch(link + "check-wise-account/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accesstoken,
        },
        body: JSON.stringify({
          user: getUsername(),
        }),
      });
      const json = await response.json();
      if (json) {
        if (json["exist"] == "yes") {
          setWise_exist("yes");
          setBank_details(JSON.parse(token.decode(json["details"])));
        } else {
          setWise_exist("no");
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getWiseTransaction = async () => {
    try {
      const response = await fetch(link + "check-wise-transactions/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accesstoken,
        },
        body: JSON.stringify({
          user: getUsername(),
        }),
      });
      const json = await response.json();
      if (json) {
        setTransaction(json);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getCredits();
    getWiseAccountDetails();
    getWiseTransaction();
  }, []);

  return (
    <section className="pt-32  pb-40 flex flex-col items-center justify-between max-w-2xl mx-auto">
      {credits.Credits !== "" && (
        <p className=" mb-1 rounded-sm text-center text-sm p-1 ">
          Available Credits: {credits.Credits}{" "}
        </p>
      )}
      <ToastContainer />
      <div className="text-center my-2 text-lg  block uppercase tracking-wide text-gray-700  font-bold mb-2">
        {credits?.credits}
      </div>
      {/* <div className="flex w-full items-center justify-around ">
        <button
          onClick={() => {setIsPaymentTabOpen(true);
            getWiseAccountDetails()}}
          className={`py-1 px-4 rounded-full text-xs    cursor-pointer  ${
            isPaymentTabOpen
              ? "  bg-gradient-to-b text-gray-900 from-yellow-200 to-red-200 to-20% shadow-md"
              : "bg-gradient-to-b text-gray-700 from-yellow-100 to-red-100 to-20% "
          }`}
        >
          <p>Payment Methods</p>
        </button>
        <button
          onClick={() => {setIsPaymentTabOpen(false);
            getWiseTransaction() }}
          className={`py-1 px-4 rounded-full text-xs    cursor-pointer  ${
            !isPaymentTabOpen
              ? "  bg-gradient-to-b text-gray-900 from-yellow-200 to-red-200 to-20% shadow-md"
              : "bg-gradient-to-b text-gray-700 from-yellow-100 to-red-100 to-20% "
          }`}
        >
          <p>List Payment</p>
        </button>
      </div> */}

      {isPaymentTabOpen ? (
        <>
          {wise_exist === "yes" && (
            <div className=" m-3 pb-10">
              <div className="text-xs md:text-sm">
                {bank_details !== "" &&
                Object.keys(bank_details).length == 2 ? (
                  <>
                    <p>
                      <span className="text-gray-600">
                        Account Holder Name :
                      </span>{" "}
                      {bank_details["Account Holder Name"]}
                    </p>
                    <p>
                      <span className="text-gray-600">IBAN :</span>{" "}
                      {bank_details["IBAN"]}
                    </p>
                  </>
                ) : (
                  <>
                    <p>
                      <span className="text-gray-600">
                        Account Holder Name :
                      </span>{" "}
                      {bank_details["Account Holder Name"]}
                    </p>
                    <div className="flex gap-4">
                      <p>
                        <span className="text-gray-600">Account Number :</span>{" "}
                        {bank_details["Account Number"]}
                      </p>
                      <br></br>
                      {bank_details["Sort Code"] && (
                        <p>
                          <span className="text-gray-600">Sort Code :</span>{" "}
                          {bank_details["Sort Code"]}
                        </p>
                      )}
                      {bank_details["ABA RTN"] && (
                        <p>
                          <span className="text-gray-600">ABA RTN :</span>{" "}
                          {bank_details["ABA RTN"]}
                        </p>
                      )}
                    </div>
                  </>
                )}
              </div>
              <form className="w-full max-w-lg" onSubmit={handleSumbit}>
                <div className="flex flex-wrap -mx-3 mb-2">
                  <div className="w-full p-3">
                    <label
                      className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2"
                      for="grid-first-name"
                    >
                      Enter number of credits you want to cash out
                    </label>
                    <input
                      className="focus:outline-none placeholder-gray-600 shadow-md text-sm p-2 cursor-pointer w-full rounded-lg bg-white border border-pink-300"
                      type="text"
                      value={cre}
                      onChange={(e) => setCre(e.target.value)}
                      placeholder="Credits"
                    />
                  </div>
                </div>
                <div className="flex justify-center">
                  <div className="flex items-center px-10">
                    <button
                      className="text-xs md:text-sm p-1 px-4 md:px-6 focus:outline-none text-center  cursor-pointer  bg-gradient-to-b from-yellow-200 to-red-200 to-20% rounded-full    text-gray-600 shadow-md"
                      onClick={() => {
                        toast("Processing, please wait!");
                        fetch(link + "create-quote/", {
                          method: "POST",
                          headers: {
                            "Content-Type": "application/json",
                            Authorization: "Bearer " + accesstoken,
                          },
                          body: JSON.stringify({
                            user: getUsername(),
                            amount: cre,
                          }),
                        })
                          .then((response) => response.json())
                          .then((json) => {
                            toast(
                              JSON.stringify(json["Notification-text"]).replace(
                                /"|'/g,
                                ""
                              )
                            );
                            notsocket.send(
                              JSON.stringify({
                                user: username,
                              })
                            );
                          });
                        setCre("");
                        getCredits();
                      }}
                    >
                      Cash out
                    </button>
                    <br></br>
                  </div>
                  <button
                    className="text-xs md:text-sm p-1 px-4 md:px-6 focus:outline-none text-center  cursor-pointer  bg-gradient-to-b from-yellow-200 to-red-200 to-20% rounded-full    text-gray-600 shadow-md"
                    onClick={() => {
                      fetch(link + "delete-wise-account/", {
                        method: "POST",
                        headers: {
                          "Content-Type": "application/json",
                          Authorization: "Bearer " + accesstoken,
                        },
                        body: JSON.stringify({
                          user: getUsername(),
                        }),
                      })
                        .then((response) => response.json())
                        .then((json) => {
                          toast(
                            JSON.stringify(json["Notification-text"]).replace(
                              /"|'/g,
                              ""
                            )
                          );
                          if (json["status"] == "success") {
                            setWise_exist("no");
                          }
                          notsocket.send(
                            JSON.stringify({
                              user: username,
                            })
                          );
                        });
                      setCre("");
                    }}
                  >
                    Delete Payment method
                  </button>
                  <div className=""></div>
                </div>
              </form>
            </div>
          )}

          {wise_exist === "no" && (
            <div className="">
              <div className=" flex flex-col items-center justify-center ">
                <p className="text-gray-700   text-xs m-1 ">
                  Please add bank account for cashout credits.
                </p>
                <div className="flex gap-2">
                  <p className="text-gray-700   text-xs m-1 ">
                    Choose currency
                  </p>
                  <select
                    className=" py-1 px-4 bg-gradient-to-b from-yellow-200 to-red-200 rounded-full text-xs text-gray-600 shadow-md"
                    onChange={(e) => {
                      setCurrency(e.target.value);
                    }}
                  >
                    <option value="GBP">GBP</option>
                    <option value="EUR">EUR</option>
                    <option value="USD">USD</option>
                  </select>
                </div>
              </div>
              <form className=" max-w-lg mx-auto" onSubmit={handleSumbit1}>
                {currency !== "" && currency === "EUR" && (
                  <div className="w-full p-3">
                    <label
                      className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2"
                      for="grid-first-name"
                    >
                      Account Holder Name
                    </label>
                    <input
                      className="focus:outline-none placeholder-gray-600 shadow-md text-sm p-2 cursor-pointer w-full rounded-lg bg-white border border-pink-300"
                      type="text"
                      value={acc_name}
                      onChange={(e) => setAcc_name(e.target.value)}
                      name="acc_name-name"
                      placeholder="Account Holder Name"
                    />
                    <br></br>
                    <label
                      className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2"
                      for="grid-first-name"
                    >
                      Email
                    </label>
                    <input
                      className="focus:outline-none placeholder-gray-600 shadow-md text-sm p-2 cursor-pointer w-full rounded-lg bg-white border border-pink-300"
                      type="text"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      name="email"
                      placeholder="E-mail"
                    />
                    <br></br>
                    <label
                      className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2"
                      for="grid-first-name"
                    >
                      Iban
                    </label>
                    <input
                      className="focus:outline-none placeholder-gray-600 shadow-md text-sm p-2 cursor-pointer w-full rounded-lg bg-white border border-pink-300"
                      type="text"
                      value={iban}
                      onChange={(e) => setIban(e.target.value)}
                      name="iban"
                      placeholder="Iban"
                    />
                  </div>
                )}
                {currency !== "" && currency === "GBP" && (
                  <div className="w-full p-3">
                    <label
                      className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2"
                      for="grid-first-name"
                    >
                      Account Holder Name
                    </label>
                    <input
                      className="focus:outline-none placeholder-gray-600 shadow-md text-sm p-2 cursor-pointer w-full rounded-lg bg-white border border-pink-300"
                      type="text"
                      value={acc_name}
                      onChange={(e) => setAcc_name(e.target.value)}
                      name="acc_name-name"
                      placeholder="Account Holder Name"
                    />
                    <br></br>
                    <label
                      className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2"
                      for="grid-first-name"
                    >
                      Email
                    </label>
                    <input
                      className="focus:outline-none placeholder-gray-600 shadow-md text-sm p-2 cursor-pointer w-full rounded-lg bg-white border border-pink-300"
                      type="text"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      name="email"
                      placeholder="E-mail"
                    />
                    <br></br>
                    <label
                      className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2"
                      for="grid-first-name"
                    >
                      Account-Number
                    </label>
                    <input
                      className="focus:outline-none placeholder-gray-600 shadow-md text-sm p-2 cursor-pointer w-full rounded-lg bg-white border border-pink-300"
                      type="text"
                      value={account_number}
                      onChange={(e) => setAccount_number(e.target.value)}
                      name="account_number"
                      placeholder="account-number"
                    />
                    <label
                      className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2"
                      for="grid-first-name"
                    >
                      Sort Code
                    </label>
                    <input
                      className="focus:outline-none placeholder-gray-600 shadow-md text-sm p-2 cursor-pointer w-full rounded-lg bg-white border border-pink-300"
                      type="text"
                      value={sortcode}
                      onChange={(e) => setSortcode(e.target.value)}
                      name="sortcode"
                      placeholder="sort-code"
                    />
                  </div>
                )}

                {currency !== "" && currency === "USD" && (
                  <div className="w-full p-3">
                    <label
                      className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2"
                      for="grid-first-name"
                    >
                      Account Holder Name
                    </label>
                    <input
                      className="focus:outline-none placeholder-gray-600 shadow-md text-sm p-2 cursor-pointer w-full rounded-lg bg-white border border-pink-300"
                      type="text"
                      value={acc_name}
                      onChange={(e) => setAcc_name(e.target.value)}
                      name="acc_name-name"
                      placeholder="Account Holder Name"
                    />
                    <br></br>
                    <label
                      className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2"
                      for="grid-first-name"
                    >
                      Email
                    </label>
                    <input
                      className="focus:outline-none placeholder-gray-600 shadow-md text-sm p-2 cursor-pointer w-full rounded-lg bg-white border border-pink-300"
                      type="text"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      name="email"
                      placeholder="E-mail"
                    />
                    <br></br>
                    <label
                      className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2"
                      for="grid-first-name"
                    >
                      Account-Number
                    </label>
                    <input
                      className="focus:outline-none placeholder-gray-600 shadow-md text-sm p-2 cursor-pointer w-full rounded-lg bg-white border border-pink-300"
                      type="text"
                      value={account_number}
                      onChange={(e) => setAccount_number(e.target.value)}
                      name="account_number"
                      placeholder="account-number"
                    />
                    <label
                      className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2"
                      for="grid-first-name"
                    >
                      ABA RTN
                    </label>
                    <input
                      className="focus:outline-none placeholder-gray-600 shadow-md text-sm p-2 cursor-pointer w-full rounded-lg bg-white border border-pink-300"
                      type="text"
                      value={abartn}
                      onChange={(e) => setAbartn(e.target.value)}
                      name="abartn"
                      placeholder="ABA RTN"
                    />
                    <label
                      className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2"
                      for="grid-first-name"
                    >
                      Country Code
                    </label>
                    <input
                      className="focus:outline-none placeholder-gray-600 shadow-md text-sm p-2 cursor-pointer w-full rounded-lg bg-white border border-pink-300"
                      type="text"
                      value={country}
                      onChange={(e) => setCountry(e.target.value)}
                      name="country"
                      placeholder="country-code"
                    />
                    <label
                      className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2"
                      for="grid-first-name"
                    >
                      City
                    </label>
                    <input
                      className="focus:outline-none placeholder-gray-600 shadow-md text-sm p-2 cursor-pointer w-full rounded-lg bg-white border border-pink-300"
                      type="text"
                      value={city}
                      onChange={(e) => setCity(e.target.value)}
                      name="city"
                      placeholder="city"
                    />
                    <label
                      className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2"
                      for="grid-first-name"
                    >
                      Post Code
                    </label>
                    <input
                      className="focus:outline-none placeholder-gray-600 shadow-md text-sm p-2 cursor-pointer w-full rounded-lg bg-white border border-pink-300"
                      type="text"
                      value={postcode}
                      onChange={(e) => setPostcode(e.target.value)}
                      name="postcode"
                      placeholder="postcode"
                    />
                    <label
                      className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2"
                      for="grid-first-name"
                    >
                      Address
                    </label>
                    <input
                      className="focus:outline-none placeholder-gray-600 shadow-md text-sm p-2 cursor-pointer w-full rounded-lg bg-white border border-pink-300"
                      type="text"
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                      name="address"
                      placeholder="address"
                    />
                  </div>
                )}

                {currency !== "" && (
                  <button
                    className="ml-3 focus:outline-none text-center  cursor-pointer py-1 px-6 bg-gradient-to-b from-yellow-200 to-red-200 to-20% rounded-full text-lg   text-gray-600 shadow-md"
                    onClick={() => handleSumbit1}
                  >
                    Submit
                  </button>
                )}
              </form>
            </div>
          )}
        </>
      ) : (
        <>
          {transaction !== null && Object.keys(transaction).length > 0 ? (
            <div className="overflow-x-auto mt-5 pl-2 pr-2">
              <table className="min-w-full text-xxs md:text-xs ">
                <thead className="">
                  <tr className="text-center md:text-left ">
                    <th className="p-1 md:p-3">Created at</th>
                    <th className="p-1 md:p-3">Transfer Id</th>
                    <th className="p-1 md:p-3 text-right">Amount</th>
                    <th className="p-1 md:p-3">Estimated Date</th>
                    <th className="p-1 md:p-3">Status</th>
                  </tr>
                </thead>
                {Object.keys(transaction)?.map((transferid) => (
                  <tbody key={transferid}>
                    <tr className="border-b border-opacity-20 border-gray-700 text-xxs md:text-xs ">
                      <td className="p-3">
                        <p>
                          {transaction[transferid].created_at?.slice(0, 10)}{" "}
                          {transaction[transferid].created_at?.slice(11, 19)}
                        </p>
                      </td>
                      <td className="p-3">
                        <p>{transferid}</p>
                      </td>
                      <td className="p-3 text-right">
                        <p>
                          {transaction[transferid].currency === "GBP" && (
                            <span className="mr-0.25">£</span>
                          )}
                          {transaction[transferid].currency === "EUR" && (
                            <span className="mr-0.25">€</span>
                          )}
                          {transaction[transferid].currency === "USD" && (
                            <span className="mr-0.25">$</span>
                          )}{" "}
                          {transaction[transferid].amount}
                        </p>
                      </td>
                      <td className="p-3">
                        <p>
                          {transaction[transferid].estimated_date?.slice(0, 10)}{" "}
                          {transaction[transferid].estimated_date?.slice(
                            11,
                            19
                          )}
                        </p>
                      </td>
                      <td className="p-3 text-right">
                        <p className="  ">{transaction[transferid].status}</p>
                      </td>
                    </tr>
                  </tbody>
                ))}
              </table>
            </div>
          ) : (
            <>No data Available</>
          )}
        </>
      )}
    </section>
  );
};

export default CashoutPage;
