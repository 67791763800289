import React, { Component } from "react";

const IconComponent = ({ subject }) => {
  return (
    <div>
      {subject === "Apple TV+" && (
        <img
          className="rounded-md "
          src={`https://zaplynimages.s3.eu-west-2.amazonaws.com/Apple+TV%2B.png`}
          alt=""
        />
      )}
      {subject === "Disney+" && (
        <img
          className="rounded-md "
          src={`https://zaplynimages.s3.eu-west-2.amazonaws.com/Disney%2B.png`}
          alt=""
        />
      )}
      {subject !== "Apple TV+" && subject !== "Disney+" && (
        <img
          className="rounded-md "
          src={`https://zaplynimages.s3.eu-west-2.amazonaws.com/${subject}.png`}
          alt=""
        />
      )}
      <p className="">{subject}</p>
    </div>
  );
};

export default IconComponent;
