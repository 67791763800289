import React, { useState, useEffect, Confirm, useContext } from "react";

import { Link } from "react-router-dom";
import { SafeAreaView, Text } from "react-native";
// import "react-tooltip/dist/react-tooltip.css";

// import { Tooltip as ReactTooltip } from "react-tooltip";
import { GlobalContext } from "../GlobalContext";
import DestinationTab from "../DestinationTab";
import Endpoints from "../../api/Endpoints";
import { Outlet } from "react-router";

const UserMessages = (props) => {
  var link = `https://omnistack.org${process.env.REACT_APP_LINK}`;
  //var link = "http://localhost:8000/api/";

  const { usermessagefeed, setUsermessagefeed } = useContext(GlobalContext);

  const [opentext, setOpenText] = useState(
    <Text style={{ fontSize: 12 }}>
      All the tiles that you created that are still {"\n"}available for others
      to subscribe to.{" "}
    </Text>
  );
  const [sharedtext, setSharedText] = useState(
    <Text style={{ fontSize: 12 }}>
      All the tiles that you created that are subscribed by{"\n"}others. You can
      change password and username {"\n"}details of subscriptions here.
    </Text>
  );
  const [substext, setSubsText] = useState(
    <Text style={{ fontSize: 12 }}>
      All the tiles that you have subscribed to. You can view{"\n"}password and
      username details of subscriptions{"\n"}here.
    </Text>
  );

  const handleOpenPostclick = () => {
    setOpenText("");
    setUsermessagefeed("Open Posts");
  };

  const handleSharedclick = () => {
    setSharedText("");
    setUsermessagefeed("Saved Posts");
  };

  const handleSubscribedclick = () => {
    setSubsText("");
    setUsermessagefeed("Subscribed Posts");
  };

  useEffect(() => {
    if (window.location.pathname === "/my-posts/open") {
      setUsermessagefeed("Open Posts");
    } else if (window.location.pathname === "/my-posts/shared") {
      setUsermessagefeed("Saved Posts");
    } else if (window.location.pathname === "/my-posts/subscriptions") {
      setUsermessagefeed("Subscribed Posts");
    }
  }, []);

  return (
    <>
      <div className="pt-32 md:pt-36">
        {/* <ReactTooltip
          style={{ backgroundColor: "#D7E3F0", borderRadius: 10 }}
          anchorId="open"
          place="bottom"
          variant="warning"
          content={opentext}
        />
        <ReactTooltip
          style={{ backgroundColor: "#D7E3F0", borderRadius: 10 }}
          anchorId="shared"
          place="bottom"
          variant="warning"
          content={sharedtext}
        />
        <ReactTooltip
          style={{ backgroundColor: "#D7E3F0", borderRadius: 10 }}
          anchorId="subs"
          place="bottom"
          variant="warning"
          content={substext}
        /> */}
        <div className="flex  items-center  justify-between md:justify-center md:space-x-20 xl:space-x-32 px-5 md:px-40 mb-5 focus:outline-none ">
          <div className="text-center">
            <Link to="/my-posts/open">
              <button
                id="open"
                className={
                  usermessagefeed === "Open Posts"
                    ? "w-20 text-center button h-8 rounded-full shadow-sm focus:outline-none cursor-pointer bg-gradient-to-t from-red-800 to-red-600 text-white text-sm   "
                    : "w-20 text-center button h-8 rounded-full shadow-sm focus:outline-none cursor-pointer bg-gradient-to-t from-yellow-400 to-yellow-200 text-sm   "
                }
                onClick={handleOpenPostclick}
              >
                Open
              </button>
            </Link>
          </div>
          <div className="text-center">
            <Link to="/my-posts/shared">
              <button
                id="shared"
                className={
                  usermessagefeed === "Saved Posts"
                    ? " w-20 text-center button h-8 rounded-full shadow-sm focus:outline-none cursor-pointer bg-gradient-to-t from-red-800 to-red-600 text-white text-sm  "
                    : " w-20 text-center button h-8 rounded-full shadow-sm focus:outline-none cursor-pointer bg-gradient-to-t from-yellow-400 to-yellow-200  text-sm   "
                }
                onClick={handleSharedclick}
              >
                Shared
              </button>
            </Link>
          </div>
          <div className="text-center">
            <Link to="/my-posts/subscriptions">
              <button
                id="subs"
                className={
                  usermessagefeed === "Subscribed Posts"
                    ? "  w-20 text-center button h-8 rounded-full shadow-sm focus:outline-none cursor-pointer bg-gradient-to-t from-red-800 to-red-600 text-white text-sm  "
                    : " w-20 text-center button h-8 rounded-full shadow-sm focus:outline-none cursor-pointer bg-gradient-to-t from-yellow-400 to-yellow-200 text-sm   "
                }
                onClick={handleSubscribedclick}
              >
                My Subs
              </button>
            </Link>
          </div>
          <div className="z-10 relative ">
            <DestinationTab>
              <button
                id="filter"
                className="border border-yellow-100 shadow-sm p-1 rounded-full  "
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  className="h-6 w-6 "
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path
                    d="M6.17 18a3.001 3.001 0 0 1 5.66 0H22v2H11.83a3.001 3.001 0 0 1-5.66 0H2v-2h4.17zm6-7a3.001 3.001 0 0 1 5.66 0H22v2h-4.17a3.001 3.001 0 0 1-5.66 0H2v-2h10.17zm-6-7a3.001 3.001 0 0 1 5.66 0H22v2H11.83a3.001 3.001 0 0 1-5.66 0H2V4h4.17zM9 6a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm6 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-6 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"
                    fill="rgba(74,69,69,1)"
                  />
                </svg>
              </button>
            </DestinationTab>
          </div>
        </div>
      </div>

      <Outlet />
    </>
  );
};

export default UserMessages;
