import React from "react";
import { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import getUsername from "../Account_Infomation/Username";
import getToken from "../Token";

function ReddemCoupon() {
  const [code, setCode] = useState("");
  const accesstoken = getToken();
  return (
    <div className=" pt-40 flex items-center justify-center  ">
      <ToastContainer autoClose={2500} />
      <div className="  bg-yellow-300 rounded-lg ">
        <div className="container flex flex-col flex-wrap content-center justify-center p-4 py-20 mx-auto md:p-10">
          <h1 className="text-5xl antialiased   leading-none text-center text-gray-100">
            Claim Your Free Credits!
          </h1>
          <p className="pt-2 pb-8 text-xl antialiased text-center text-gray-600">
            Enter You Coupon/Promo code here.
          </p>
          <div className="flex flex-row">
            <input
              type="text"
              placeholder="Enter..."
              className="w-3/5 p-3 rounded-l-lg sm:w-2/3 focus:outline-none"
              value={code}
              onChange={(e) => setCode(e.target.value)}
            />
            <button
              type="button"
              className="w-2/5 p-3   rounded-r-lg sm:w-1/3 focus:outline-none bg-yellow-500 text-gray-900"
              onClick={() => {
                fetch(
                  `https://omnistack.org${process.env.REACT_APP_LINK}` +
                    "use-sponge-promocode/",
                  {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: "Bearer " + accesstoken,
                    },
                    body: JSON.stringify({
                      user: getUsername(),
                      promocode: code,
                    }),
                  }
                )
                  .then((response) => response.json())
                  .then((json) =>
                    toast(
                      JSON.stringify(json["Notification_text"]).replace(
                        /"|'/g,
                        ""
                      )
                    )
                  );
                setCode("");
              }}
            >
              Claim
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReddemCoupon;
