import getToken from "../Token";
import { Auth } from "aws-amplify";
import React, { useState, useEffect, useContext } from "react";
import getUsername from "../Account_Infomation/Username";
import Modal from "react-modal";
import IconComponent from "../Utility/IconComponent";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { GlobalContext } from "../GlobalContext.js";
import QRCode from "qrcode";
import useSubject from "../Subject";
import Endpoints from "../../api/Endpoints";
import First from "../../assets/first.svg";
import Second from "../../assets/second.svg";
import Third from "../../assets/third.svg";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import "../../styles/styles.css";
import FilterIcon from "../../assets/icons/filter.png";
import Cards from "../Cards";
import { useQuery, useQueryClient } from "react-query";
import Destination_tab from "../Destination_tab";
import HomeFilterComponent from "./HomeFilterComponent";

const MessageBoard = (props) => {
  const queryClient = useQueryClient();
  var link = `https://omnistack.org${process.env.REACT_APP_LINK}`;
  const username = getUsername();
  const acstoken = getToken();
  const {
    showFilteredData,
    setShowFilteredData,
    selectedHomeFilterSubsOptions,
    setSelectedHomeFilterTopicOptions,
    selectedConnectionOptions,
  } = useContext(GlobalContext);
  const { notsocket } = useContext(GlobalContext);
  const { filteredData } = useContext(GlobalContext);

  const subjectData = useSubject(link + "subject-list/", acstoken);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [selectedTileref, setSelectedTileref] = useState("");
  // fetch spongSubsData
  const {
    isLoading: isSpongSubsDataLoading,
    data: spongSubsData,
    isError: isSpongSubsError,
    error: spongSubsErr,
    refetch: refetchSpongSubsData,
  } = useQuery("spongeSubs", Endpoints.GetSpongeData, {
    // refetchInterval: 15000, // Fetch data every 15 seconds (adjust the interval as needed)
  });

  // Link share - qr code Func
  const [qrCodeUrl, setQrCodeUrl] = useState("");
  const [linkModalIsOpen, setLinkModalIsOpen] = useState(false);

  const setLinkModalIsOpenToTrue = () => {
    setLinkModalIsOpen(true);
  };
  const setLinkModalIsOpenToFalse = () => {
    setLinkModalIsOpen(false);
  };

  const handleShareCardClick = (tileref) => {
    setSelectedTileref(tileref);
    QRCode.toDataURL("https://www.sponge-app.com/share/" + tileref)
      .then((url) => {
        setQrCodeUrl(url);
      })
      .catch((err) => console.error(err));
  };

  const handleDownloadQrCode = () => {
    const downloadLink = document.createElement("a");
    downloadLink.href = qrCodeUrl;
    downloadLink.download = "qr-code.png";
    downloadLink.click();
  };

  const handleClick = async (message) => {
    const savedcontent = {
      id: message.id,
      user: username,
      subject: message.subject,
      body: message.body,
      author: message.author,
      SaturnTileRef: message.SaturnTileRef,
      created_at: message.created_at,
      currency: message.currency,
      bundle_image: message.bundle_image,
    };

    const response = await fetch(link + "savedmessage-create/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + acstoken,
      },
      body: JSON.stringify(savedcontent),
    });
    const json = await response.json();
    if (json) {
      toast(JSON.stringify(json["Notification-text"]).replace(/"|'/g, ""));
      await queryClient.invalidateQueries("userDetails");
      await queryClient.invalidateQueries("spongeSubs");
      notsocket.send(
        JSON.stringify({
          user: username,
        })
      );
    }
  };

  return (
    <div className="  ">
      <ToastContainer autoClose={2500} />
      <div className="  pt-32  md:pt-40 pb-40 max-w-2xl lg:max-w-3xl  mx-auto">
        {spongSubsData && (
          <div className="flex items-center justify-end  mx-5 md:mx-auto mb-5">
            <div className=" relative ">
              <img
                src={FilterIcon}
                className={`${
                  selectedHomeFilterSubsOptions?.length > 0 || isFilterOpen
                    ? "bg-yellow-100"
                    : ""
                } h-8 w-8 z-50 rounded-full p-1 border border-red-100 shadow-md cursor-pointer
`}
                alt=""
                onClick={() => setIsFilterOpen(!isFilterOpen)}
              />
              {isFilterOpen && (
                <Destination_tab setIsFilterOpen={setIsFilterOpen} />
              )}
            </div>
          </div>
        )}
        {spongSubsData == null && (
          <div className="text-center  ">Loading...</div>
        )}
        <div className=" w-full grid md:grid-cols-2  ">
          {showFilteredData ? (
            <HomeFilterComponent />
          ) : (
            spongSubsData?.map((message) => (
              <div
                key={message.id}
                className=" bg-gradient-to-t from-white to-yellow-50 border border-yellow-100 p-2 m-2 rounded-lg order shadow-md justify-items-center overflow-x-auto"
              >
                <Cards tileData={message} />
                <div
                  id=" "
                  className=" flex items-center justify-center mx-2 mb-2 text-center p-1 align-center rounded-full   border-t border-b border-yellow-300 shadow-sm text-xs"
                >
                  You save{" "}
                  {message.is_bundle ? (
                    <>
                      {(
                        ((Number(
                          subjectData?.data
                            ?.filter((item) =>
                              message?.bundle_data[
                                message?.SaturnTileRef
                              ].includes(item?.subjectname)
                            )
                            .map((item) => Number(item.price))
                            .reduce(
                              (accumulator, currentValue) =>
                                accumulator + currentValue,
                              0
                            )
                            .toFixed(1)
                        ) -
                          message?.body) /
                          Number(
                            subjectData?.data
                              ?.filter((item) =>
                                message?.bundle_data[
                                  message?.SaturnTileRef
                                ].includes(item?.subjectname)
                              )
                              .map((item) => Number(item.price))
                              .reduce(
                                (accumulator, currentValue) =>
                                  accumulator + currentValue,
                                0
                              )
                              .toFixed(1)
                          )) *
                        100
                      ).toFixed(1)}

                      {" %"}
                    </>
                  ) : (
                    <>
                      {subjectData?.data
                        ?.filter((item) => item.subjectname === message.subject)
                        .map((item) => (
                          <p className="px-1">
                            {" "}
                            {(
                              ((Number(item.price) - message?.body) /
                                Number(item.price)) *
                              100
                            ).toFixed(1)}{" "}
                            {" %"}
                          </p>
                        ))}
                    </>
                  )}
                </div>
                <div className="flex flex-row mx-1 mb-1 items-center justify-between">
                  <div className="flex-shrink-0">
                    <button
                      className="flex-nowrap flex-shrink px-1 py-2 button w-24  rounded-full shadow-sm focus:outline-none cursor-pointer bg-gradient-to-r from-yellow-200 to-red-200 text-xs font-semibold "
                      onClick={() => handleClick(message)}
                    >
                      {" "}
                      Get This Now{" "}
                    </button>
                  </div>
                  {/* <div className="  flex-shrink    border  w-4/12 "></div> */}
                  {/* <div id="bottom col" className="flex flex-row justify-between border items-center"> */}
                  <div className="flex justify-center items-center">
                    {/* <CopyToClipboard text={window.location.href + 'share/' + message.SaturnTileRef}>

                    <button onClick={toast("link copied!")} className="flex-nowrap flex-shrink p-2 button w-16  rounded-full shadow-lg focus:outline-none cursor-pointer hover:bg-red-800 hover:text-white bg-gradient-to-r from-yellow-200 to-red-200 text-sm   " > Share </button>
                  </CopyToClipboard> */}
                    <button
                      className="flex-nowrap flex-shrink px-1 py-2 mx-1 button w-24  rounded-full shadow-sm focus:outline-none cursor-pointer bg-gradient-to-r from-yellow-200 to-red-200 text-xs font-semibold"
                      onClick={() => {
                        setLinkModalIsOpenToTrue();
                        handleShareCardClick(message.SaturnTileRef);
                      }}
                    >
                      Share Tile
                    </button>
                  </div>
                  <div className="flex gap-1 items-center p-2 text-xxs  rounded-full font-semibold">
                    {" "}
                    Connection{" "}
                    {message.connection === "1st" ? (
                      <img src={First} alt="" className="w-3 h-3" />
                    ) : (
                      <>
                        {message.connection === "1st" ? (
                          <img src={Second} alt="" className="w-3 h-3" />
                        ) : (
                          <img src={Third} alt="" className="w-3 h-3" />
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      </div>

      {/* share link modal */}
      <Modal
        isOpen={linkModalIsOpen}
        className=" max-w-xl z-50 mx-5 sm:mx-auto my-10 md:my-16 rounded-md shadow-lg text-center"
        overlayClassName="bg-black z-50  bg-opacity-10 fixed inset-0 overflow-y-auto"
        onRequestClose={() => setLinkModalIsOpen(false)}
      >
        <button
          onClick={setLinkModalIsOpenToFalse}
          className="flex justify-start mb-1 px-2 pb-1 button shadow-md focus:outline-none cursor-pointer bg-yellow-400 rounded-md text-sm   flex-shrink"
        >
          x
        </button>
        <div className="bg-white  p-6 rounded-md flex flex-col items-center">
          <div className="relative">
            <img src={qrCodeUrl} alt="QR code" className="w-72 h-72" />
            <button
              title="Download QR"
              className="absolute bottom-5 -right-3 shadow-md bg-yellow-300 rounded-b-md rounded-l-md"
              onClick={handleDownloadQrCode}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
                className="h-8 w-8"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path d="M13 10h5l-6 6-6-6h5V3h2v7zm-9 9h16v-7h2v8a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-8h2v7z" />
              </svg>
            </button>
          </div>
          <div className="w-full flex justify-center">
            <input
              type="text"
              className="border border-yellow-300 rounded-l-md text-sm p-2 md:w-80 text-gray-500"
              value={`https://www.sponge-app.com/share/${selectedTileref}`}
              readOnly
            />
            <button
              className="bg-white px-2 border border-yellow-300 rounded-r-md flex items-center space-x-1 text-gray-700 "
              onClick={() => {
                navigator.clipboard.writeText(
                  "https://www.sponge-app.com/share/" + selectedTileref
                );
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="20"
                height="20"
                className="w-4 h-4"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path d="M7 6V3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1h-3v3c0 .552-.45 1-1.007 1H4.007A1.001 1.001 0 0 1 3 21l.003-14c0-.552.45-1 1.007-1H7zM5.003 8L5 20h10V8H5.003zM9 6h8v10h2V4H9v2z" />
              </svg>
              <span className="text-xs">Copy</span>
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default MessageBoard;
