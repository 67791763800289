import React, { useContext, useEffect } from "react";

import { useState } from "react";
import getToken from "./Token";
import getUsername from "./Account_Infomation/Username";
import Select from "react-select";
import useSubject from "./Subject";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import IconComponent from "./Utility/IconComponent.js";
import { Buffer } from "buffer";
import { GlobalContext } from "./GlobalContext";
import Endpoints from "../api/Endpoints";
import { Tooltip } from "@mui/material";
import Zoom from "@mui/material/Zoom";
import { Carousel } from "react-responsive-carousel";
import "../styles/styles.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { useQueryClient } from "react-query";
window.Buffer = Buffer;

const imageURls = [
  "https://zaplynimages.s3.eu-west-2.amazonaws.com/bundle_image_1.svg",
  "https://zaplynimages.s3.eu-west-2.amazonaws.com/bundle_image_2.svg",
  "https://zaplynimages.s3.eu-west-2.amazonaws.com/bundle_image_3.svg",
  "https://zaplynimages.s3.eu-west-2.amazonaws.com/bundle_image_4.svg",
  "https://zaplynimages.s3.eu-west-2.amazonaws.com/bundle_image_5.svg",
  "https://zaplynimages.s3.eu-west-2.amazonaws.com/bundle_image_6.svg",
];

const PostMessagePopup = (props) => {
  const queryClient = useQueryClient();
  var link = `https://omnistack.org${process.env.REACT_APP_LINK}`;
  //var link = "http://localhost:8000/api/";
  const { setFetchedMessages, setOpenSubs } = useContext(GlobalContext);
  const alphabet = "abcdefghijklmnopqrstuvwxyz";
  const { notsocket } = useContext(GlobalContext);
  const accesstoken = getToken();
  const username = getUsername();

  const [subject, setSubject] = useState("Sponge"); //subject state for select

  const { data: subjectlist } = useSubject(link + "subject-list/", accesstoken);
  const [subscriptionOptions, setSubscriptionOptions] = useState([]);

  const [bundleName, setBundleName] = useState("");

  const [body, setBody] = useState("");
  const [bodyError, setBodyError] = useState("");

  const [SaturnTileRef, setSaturnTileRef] = useState(createTileRef());

  const [author, setAuthor] = useState(username);

  const [chosencurrency, setChosencurrency] = useState("GBP");

  const bundleImg = imageURls[Math.floor(Math.random() * imageURls.length)];

  const [isBundleChecked, setIsBundleChecked] = useState(false);
  const handleBundleCheck = () => {
    setIsBundleChecked(!isBundleChecked);
    setSelectedSubs([]);
  };

  function createTileRef() {
    var part1 = username.substring(0, 3);
    var part2 = Math.floor(Math.random() * (999 - 100 + 1) + 100);
    var part3 =
      alphabet[Math.floor(Math.random() * alphabet.length)] +
      alphabet[Math.floor(Math.random() * alphabet.length)] +
      alphabet[Math.floor(Math.random() * alphabet.length)];

    var tmp = part1 + part2 + part3;
    // console.log(tmp);

    return tmp;
  }

  const [selectedSubs, setSelectedSubs] = useState([]);
  const handleAddSubscriptions = (newSelectedSubs) => {
    setSelectedSubs(newSelectedSubs);
  };

  const handleRemoveSubscriptions = (name) => {
    // console.log(name);
    const newSelectedSubs = [...selectedSubs];

    if (newSelectedSubs.includes(name)) {
      newSelectedSubs.splice(newSelectedSubs.indexOf(name), 1);
    } else {
      newSelectedSubs.push(name);
    }

    setSelectedSubs(newSelectedSubs);
  };

  const handleSumbit = async (e) => {
    e.preventDefault(); //prevents refreshing page

    if (isBundleChecked) {
      if (selectedSubs.length < 2) {
        toast("Please Add atleast 2 subscription to create a bundle.");
        return;
      }
    }

    let content = {};
    if (isBundleChecked) {
      content = {
        subject: bundleName,
        bundle_image: bundleImg,
        body,
        author,
        SaturnTileRef,
        currency: chosencurrency,
        is_bundle: isBundleChecked,
        bundle_data: {
          [SaturnTileRef]: selectedSubs.map((item) => item.subjectname),
        },

        // bundle_data: bundleData,
      };
    } else {
      content = {
        subject,
        bundle_image: null,
        body,
        author,
        SaturnTileRef,
        currency: chosencurrency,
        is_bundle: isBundleChecked,
        bundle_data: {
          [SaturnTileRef]: null,
        },
      };
    }

    // console.log(content);
    if (isBundleChecked || (!isBundleChecked && subject !== "Sponge")) {
      try {
        const response = await fetch(link + "message-create/", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + accesstoken,
          },
          body: JSON.stringify(content),
        });

        const json = await response.json();
        if (json["Notification-text"]) {
          toast(json["Notification-text"]);
          await queryClient.invalidateQueries("spongeSubs");
          await queryClient.invalidateQueries("openSubs");
          notsocket.send(
            JSON.stringify({
              user: username,
            })
          );
          props.onClick();
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    if (subjectlist) {
      setSubscriptionOptions(subjectlist.slice(1));
    }
  }, [subjectlist]);

  useEffect(() => {
    if (!isBundleChecked) {
      setSelectedSubs([]);
      setSubject("Sponge");
    }
  }, [isBundleChecked]);

  return (
    <div className="bg-white font-bellota rounded-md p-3">
      <form onSubmit={handleSumbit}>
        <div className="flex flex-col w-fit mx-auto  ">
          {/* ************* */}
          <div className=" flex  justify-around space-y-5  p-1  ">
            <div className="w-32 h-32">
              {isBundleChecked ? (
                <div className="flex flex-col items-center">
                  <div className="relative ">
                    <img
                      className=" rounded-md "
                      src={`${bundleImg}`}
                      alt="err"
                    />
                    <p className="absolute inset-0 flex items-center justify-center  text-7xl text-white ">
                      {bundleName.charAt(0)}
                    </p>
                  </div>
                  <p className="">{bundleName}</p>
                </div>
              ) : (
                <IconComponent subject={subject} />
              )}
            </div>

            <div className="flex flex-col gap-2  ">
              <p className="  p-1 text-center text-xl">{username}</p>
              <p className="p-1 text-center  border-t border-yellow-300 text-4xl  ">
                {body}{" "}
              </p>
              <p className="p-1 text-right  ">
                {chosencurrency}
                {`/month`}
              </p>
            </div>
          </div>

          {/* selectedSubs */}
          <div className="flex gap-2 flex-wrap mt-5 p-1 mx-2 py-3 ">
            {selectedSubs.length > 0 &&
              selectedSubs.map((item, idx) => (
                <div className="flex items-center gap-1 justify-between rounded-full border border-gray-300 px-2 py-0.5">
                  <p key={idx} className="text-xxs md:text-xs  ">
                    {`${item.subjectname} `}
                  </p>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    className="w-3 h-3 cursor-pointer"
                    onClick={() => handleRemoveSubscriptions(item)}
                  >
                    <path d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 10.5858L9.17157 7.75736L7.75736 9.17157L10.5858 12L7.75736 14.8284L9.17157 16.2426L12 13.4142L14.8284 16.2426L16.2426 14.8284L13.4142 12L16.2426 9.17157L14.8284 7.75736L12 10.5858Z"></path>
                  </svg>
                </div>
              ))}
          </div>
          {/* ***************** */}

          <div className=" flex flex-col items-center  p-2 space-y-2 ">
            {/* bundle div */}

            <div className="flex gap-1 items-center">
              <p className="flex items-center text-sm text-gray-600 my-2">
                Bundle Subscriptions?
                <Tooltip
                  enterTouchDelay={0}
                  TransitionComponent={Zoom}
                  placement="top"
                  arrow
                  title="Bundles allow you to offer multiple subscriptions in a single package."
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    className="w-4 h-4"
                  >
                    <path
                      d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM11 15V17H13V15H11ZM13 13.3551C14.4457 12.9248 15.5 11.5855 15.5 10C15.5 8.067 13.933 6.5 12 6.5C10.302 6.5 8.88637 7.70919 8.56731 9.31346L10.5288 9.70577C10.6656 9.01823 11.2723 8.5 12 8.5C12.8284 8.5 13.5 9.17157 13.5 10C13.5 10.8284 12.8284 11.5 12 11.5C11.4477 11.5 11 11.9477 11 12.5V14H13V13.3551Z"
                      fill="#fddd9f"
                    ></path>
                  </svg>
                </Tooltip>
              </p>
              <input
                type="checkbox"
                id="bundle"
                name="bundle"
                className="h-4 w-4 bg-red-100 border-red-300 text-red-500 focus:ring-red-200"
                checked={isBundleChecked}
                onChange={handleBundleCheck}
              />
            </div>

            <div className="space-y-2">
              {isBundleChecked ? (
                <>
                  <input
                    id="messagecontent"
                    className={`w-full  px-3 py-2 my-2 rounded-full   border-t border-b border-yellow-300 shadow-sm text-xs 
                 `}
                    type="text"
                    value={bundleName}
                    onChange={(e) => setBundleName(e.target.value)}
                    required
                    placeholder="Enter bundle name"
                  />
                  <Select
                    className="w-56 text-xs "
                    value={selectedSubs}
                    onChange={handleAddSubscriptions}
                    options={subscriptionOptions}
                    placeholder={"Add Subscription"}
                    isMulti
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderColor: state.isSelected ? "#fdebb1" : "#fdebb1",
                        borderRadius: 50,
                      }),
                      option: (baseStyles, state) => ({
                        ...baseStyles,

                        backgroundColor: state.isSelected ? "#fdebb1" : "white",
                        color: state.isSelected ? "black" : "black",
                      }),
                    }}
                    blurInputOnSelect={false}
                    closeMenuOnSelect={false}
                    autosize={false}
                    controlShouldRenderValue={false}
                    hideSelectedOptions={false}
                    // isSearchable={false}
                    // noOptionsMessage={customNoOptionsMessage}
                    getOptionValue={(option) => option.subjectname} //value is what it is
                    getOptionLabel={(option) => option.subjectname} //label is what you see
                  />
                  {/* <select
                    class="w-full p-1 px-4 a rounded-full   border-t border-b border-yellow-300 shadow-sm text-xs bg-white"
                    value="Add Subscription"
                    required
                    multiple
                    defaultValue="Add Subscription"
                    onChange={(e) => handleAddSubscriptions(e.target.value)}
                  >
                    <option>Add Subscription</option>
                    {subjectlist == null && <div>loading</div>}
                    {subjectlist != null &&
                      subjectlist
                        .slice(1)
                        // .filter(
                        //   (item) => !selectedSubs.includes(item.subjectname)
                        // )
                        .map((subject) => (
                          <option
                            className={`${
                              selectedSubs.includes(subject.subjectname)
                                ? "bg-yellow-100 "
                                : ""
                            }`}
                            value={subject.subjectname}
                          >
                            {subject.subjectname}
                          </option>
                        ))}
                  </select> */}
                </>
              ) : (
                <select
                  class="w-full px-4 rounded-full   border-t border-b border-yellow-300 shadow-sm text-xs py-2 bg-white"
                  //value={Object.keys(subject)[0]}
                  required
                  defaultValue={subject}
                  onChange={(e) => {
                    if (e.target.value !== "Select Subscription") {
                      setSubject(e.target.value);
                    } else {
                      setSubject("Sponge");
                    }
                  }}
                >
                  {subjectlist == null && <div>loading</div>}
                  {subjectlist != null &&
                    subjectlist.map((subject) => (
                      <option value={subject.subjectname}>
                        {subject.subjectname}
                      </option>
                    ))}
                </select>
              )}
              <div className="flex flex-col md:flex-row gap-2">
                <input
                  id="messagecontent"
                  className={`w-full  px-3 py-2  rounded-full   border-t border-b border-yellow-300 shadow-sm text-xs 
                  ${bodyError ? "placeholder-red-700 " : ""}`}
                  type="number"
                  value={body}
                  onClick={() => setBodyError("")}
                  onChange={(e) => setBody(e.target.value)}
                  required
                  placeholder={bodyError ? `${bodyError}` : "Price per Month "}
                  rows="1"
                />
                <select
                  class=" px-5 py-2   rounded-full   border-t border-b border-yellow-300 shadow-sm text-xs  bg-white"
                  required
                  onChange={(e) => setChosencurrency(e.target.value)}
                >
                  <option value="GBP">GBP</option>
                  <option disabled value="EUR">
                    EUR
                  </option>
                  <option disabled value="USD">
                    USD
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <button
          type="submit"
          className="bg-gradient-to-r from-yellow-200 to-red-200 rounded-full w-full md:w-1/2 px-4 py-0.5"
        >
          Post
        </button>
      </form>
    </div>
  );
};

export default PostMessagePopup;
