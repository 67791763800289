import React from "react";
import ReactDOM from "react-dom";
import CheckoutForm from "./CheckoutForm";
import getToken from "./Token";
import { useState, useEffect, useContext } from "react";
import Api from "../config/Api.json";
import fernet from "fernet";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { SafeAreaView, Text } from "react-native";
import { GlobalContext } from "./GlobalContext";
import { Buffer } from "buffer";
import getUsername from "./Account_Infomation/Username";
import { useQuery, useQueryClient } from "react-query";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import tz from "dayjs/plugin/timezone";
import ReactPaginate from "react-paginate";
window.Buffer = Buffer;
dayjs.extend(utc);
dayjs.extend(tz);

const Payment = (props) => {
  var link = `https://omnistack.org${process.env.REACT_APP_LINK}`;
  //var link = 'http://localhost:8000/api/'

  const timeZone = dayjs.tz.guess();

  const [iscustomer, setIscustomer] = useState(null);
  const { notsocket } = useContext(GlobalContext);
  const [hassetupintent, setHassetupintent] = useState(null);
  const [pendingbecomecustomer, setPendingbecomecustomer] = useState(false);
  const [isPaymentTabOpen, setIsPaymentTabOpen] = useState(false);

  const [data, setData] = useState([]);
  const [pdata, setPdata] = useState([]);
  const [de, setDe] = useState("");
  const [type, setType] = useState("");
  const [last4, setLast4] = useState("");
  const [exp_month, setExp_month] = useState("");
  const [exp_year, setExp_year] = useState("");
  const [code, setCode] = useState("");
  const [de2, setDe2] = useState("");
  const [am, setAm] = useState("");
  const username = getUsername();

  const checkcustomer = () => {
    //function to check if customer exists on page load
    let accesstoken = getToken();
    fetch(link + "check-customer/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accesstoken,
      },
      body: JSON.stringify({ user: props.currentusername }),
    })
      .then((res) => {
        if (!res.ok) {
          throw Error("error when checking customer db");
        }
        return res.json();
      })
      .then((data) => {
        // console.log("checkcustomer is" + data);
        setIscustomer(data);
      })
      .catch((err) => {
        if (err.name === "AbortError") {
          console.log("fetch aborted");
        } else {
          console.log("");
        }
      });
  };

  const handleCreateButton = () => {
    let accesstoken = getToken();
    fetch(link + "save-card/", {
      method: "POST",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: "Bearer " + accesstoken,
      },
      body: JSON.stringify({ user: props.currentusername }),
    })
      .then((response) => response.json())
      .then((json) => (window.location.href = json["url"]));
  };

  const submitHandler2 = () => {
    setIsPaymentTabOpen(true);
    let accesstoken = getToken();
    fetch(link + "get-payment-methods/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accesstoken,
      },
      body: JSON.stringify({ user: props.currentusername }),
    })
      .then((response) => response.json())
      .then((json) => {
        // console.log(json);
        var secret = new fernet.Secret(process.env.REACT_APP_FERNET_KEY);
        var token = new fernet.Token({
          secret: secret,
          token: String(json),
          ttl: 0,
        });
        // console.log(token, "ss");
        var result = token.decode();
        json = JSON.parse(result);
        // console.log(json);
        var count = Object.keys(json).length;
        setData([]);
        for (let i = 1; i < count + 1; i++) {
          setData((data) => [
            ...data,
            {
              id: i,
              type: json[i]["type"],
              last4: String(json[i]["last4"]),
              exp_month: String(json[i]["exp_month"]),
              exp_year: String(json[i]["exp_year"]),
              sort_code: String(json[i]["sort_code"]),
              default: json[i]["default"],
            },
          ]);
        }
      });
  };
  const removeItem = (index) => {
    setData([...data.slice(0, index - 1), ...data.slice(index)]);
  };
  const [payments, setPayments] = useState(null);
  const [showModal2, setShowModal2] = useState(true);

  const handleListPaymentButton = () => {
    setIsPaymentTabOpen(false);
    // let accesstoken = getToken();
    // fetch(
    //   `https://omnistack.org${process.env.REACT_APP_LINK}` + "payment-records/",
    //   {
    //     method: "POST",
    //     headers: {
    //       "Content-Type": "application/json",
    //       Authorization: "Bearer " + accesstoken,
    //     },
    //     body: JSON.stringify({ user: props.currentusername }),
    //   }
    // )
    //   .then((res) => {
    //     return res.json();
    //   })
    //   .then((result) => {
    //     setPayments(result[0]);
    //     // return res.json();
    //   });
  };

  // transaction details

  const [page, setPage] = useState(0);
  const getTransactions = async (pageNo) => {
    const accesstoken = getToken();
    try {
      const response = await fetch(link + "payment-records/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accesstoken,
        },
        body: JSON.stringify({
          user: props.currentusername,
          page: pageNo + 1,
        }),
      });

      const json = await response.json();
      if (json) {
        return json;
      }
    } catch (error) {
      console.log("");
    }
  };

  const {
    isLoading: isTransactionsLoading,
    data: transactionsData,
    isError: isTransactionsError,
    error: transactionsErr,
    isFetching: isTransactionsFetching,
    isPreviousData: isTransactionsBets,
  } = useQuery(["transactionsData", { page }], () => getTransactions(page), {
    keepPreviousData: true,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  console.log(transactionsData);
  const handlePageChange = (e) => {
    setPage(e.selected);
  };

  useEffect(() => {
    let accesstoken = getToken();
    checkcustomer();
    setPendingbecomecustomer(false);
  }, [pendingbecomecustomer]);

  // console.log(transactionsData);

  return (
    <section className="pt-28 md:pt-32  pb-40 flex flex-col items-center justify-between max-w-2xl mx-auto">
      <ToastContainer />
      <div className="text-center my-2 text-lg  block uppercase tracking-wide text-gray-700  font-bold mb-2">
        Payments
      </div>
      <div className="flex w-full items-center justify-around ">
        <button
          onClick={handleListPaymentButton}
          className={`py-1 px-4 rounded-full text-xs    cursor-pointer  ${
            !isPaymentTabOpen
              ? " hover:bg-red-800 hover:text-white bg-gradient-to-r from-yellow-200 to-red-200 text-sm rounded-full shadow-lg"
              : " "
          }`}
        >
          <p>List Payment</p>
        </button>
        <button
          onClick={submitHandler2}
          className={`py-1 px-4 rounded-full text-xs    cursor-pointer  ${
            isPaymentTabOpen
              ? " hover:bg-red-800 hover:text-white bg-gradient-to-r from-yellow-200 to-red-200 text-sm rounded-full shadow-lg"
              : ""
          }`}
        >
          <p>Payment Methods</p>
        </button>
      </div>

      {isPaymentTabOpen ? (
        <div className="flex flex-col items-start p-5">
          <button
            onClick={handleCreateButton}
            className="flex-nowrap flex-shrink px-4 h-8 button  rounded-full shadow-lg focus:outline-none cursor-pointer hover:bg-red-800 hover:text-white bg-gradient-to-r from-yellow-200 to-red-200 text-sm   "
          >
            <p>Add Payment Method</p>
          </button>
          <div className="flex flex-col gap-2 ">
            {data.map((data) => (
              <div key={data.id} className="flex gap-4 mt-2">
                <p>
                  {data.type} ***{data.last4}{" "}
                  {data.exp_year === "undefined" ? "" : data.exp_year + "/"}
                  {data.exp_month === "undefined" ? "" : data.exp_month}{" "}
                  {data.sort_code === "undefined"
                    ? ""
                    : "Sort Code: " + data.sort_code + " "}{" "}
                </p>
                <button
                  className="flex-nowrap flex-shrink px-4 h-8 button  rounded-full shadow-lg focus:outline-none cursor-pointer hover:bg-red-800 hover:text-white bg-gradient-to-r from-yellow-200 to-red-200 text-lg   "
                  onClick={() => {
                    removeItem(data.id);

                    let accesstoken = getToken();
                    if (data.sort_code === "undefined") {
                      var secret = new fernet.Secret(
                        process.env.REACT_APP_FERNET_KEY
                      );
                      var token = new fernet.Token({
                        secret: secret,
                      });

                      fetch(link + "delete-payment-method/", {
                        method: "POST",
                        body: JSON.stringify({
                          user: token.encode(props.currentusername),
                          type: token.encode(data.type),
                          last4: token.encode(data.last4),
                          exp_month: token.encode(data.exp_month),
                          exp_year: token.encode(data.exp_year),
                          sort_code: token.encode("null"),
                        }),
                        headers: {
                          "Content-type": "application/json; charset=UTF-8",
                          Authorization: "Bearer " + accesstoken,
                        },
                      })
                        .then((response) => response.json())
                        .then((json) => {
                          toast(
                            JSON.stringify(json["Notification-text"]).replace(
                              /"|'/g,
                              ""
                            )
                          );
                          notsocket.send(
                            JSON.stringify({
                              user: username,
                            })
                          );
                        });
                    } else {
                      var secret = new fernet.Secret(
                        process.env.REACT_APP_FERNET_KEY
                      );
                      var token = new fernet.Token({
                        secret: secret,
                      });
                      fetch(link + "delete-payment-method/", {
                        method: "POST",
                        body: JSON.stringify({
                          user: token.encode(props.currentusername),
                          type: token.encode(data.type),
                          last4: token.encode(data.last4),
                          exp_month: token.encode("null"),
                          exp_year: token.encode("null"),
                          sort_code: token.encode(data.sort_code),
                        }),
                        headers: {
                          "Content-type": "application/json; charset=UTF-8",
                          Authorization: "Bearer " + accesstoken,
                        },
                      })
                        .then((response) => response.json())
                        .then((json) => {
                          toast(
                            JSON.stringify(json["Notification-text"]).replace(
                              /"|'/g,
                              ""
                            )
                          );
                          notsocket.send(
                            JSON.stringify({
                              user: username,
                            })
                          );
                        });
                    }
                  }}
                >
                  Remove
                </button>
                <button
                  className="flex-nowrap flex-shrink px-4 h-8 button  rounded-full shadow-lg focus:outline-none cursor-pointer hover:bg-red-800 hover:text-white bg-gradient-to-r from-yellow-200 to-red-200 text-lg   "
                  onClick={(e) => {
                    submitHandler2(e);
                    let accesstoken = getToken();
                    if (data.sort_code === "undefined") {
                      var secret = new fernet.Secret(
                        process.env.REACT_APP_FERNET_KEY
                      );
                      var token = new fernet.Token({
                        secret: secret,
                      });
                      fetch(link + "set-default-payment/", {
                        method: "POST",
                        body: JSON.stringify({
                          user: token.encode(props.currentusername),
                          type: token.encode(data.type),
                          last4: token.encode(data.last4),
                          exp_month: token.encode(data.exp_month),
                          exp_year: token.encode(data.exp_year),
                          sort_code: token.encode("null"),
                        }),
                        headers: {
                          "Content-type": "application/json; charset=UTF-8",
                          Authorization: "Bearer " + accesstoken,
                        },
                      })
                        .then((response) => response.json())
                        .then((json) => {
                          toast(
                            JSON.stringify(json["Notification-text"]).replace(
                              /"|'/g,
                              ""
                            )
                          );

                          notsocket.send(
                            JSON.stringify({
                              user: username,
                            })
                          );
                        });

                      setDe("Default");
                      setType(data.type);
                      setLast4(data.last4);
                      setCode("null");
                      setExp_month(data.exp_month);
                      setExp_year(data.exp_year);
                    } else {
                      var secret = new fernet.Secret(
                        process.env.REACT_APP_FERNET_KEY
                      );
                      var token = new fernet.Token({
                        secret: secret,
                      });
                      fetch(link + "set-default-payment/", {
                        method: "POST",
                        body: JSON.stringify({
                          user: token.encode(props.currentusername),
                          type: token.encode(data.type),
                          last4: token.encode(data.last4),
                          exp_month: token.encode("null"),
                          exp_year: token.encode("null"),
                          sort_code: token.encode(data.sort_code),
                        }),
                        headers: {
                          "Content-type": "application/json; charset=UTF-8",
                          Authorization: "Bearer " + accesstoken,
                        },
                      })
                        .then((response) => response.json())
                        .then((json) => {
                          toast(
                            JSON.stringify(json["Notification-text"]).replace(
                              /"|'/g,
                              ""
                            )
                          );
                          notsocket.send(
                            JSON.stringify({
                              user: username,
                            })
                          );
                        });
                      setDe("Default");
                      setType(data.type);
                      setLast4(data.last4);
                      setCode(data.sort_code);
                      setExp_month("null");
                      setExp_year("null");
                    }
                  }}
                >
                  {data.default === "Default" ? "Default" : "Set As Default"}
                </button>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <>
          <div id="column layout" className="flex ">
            {transactionsData != null && transactionsData.length > 1 ? (
              <div className="flex flex-col gap-5">
                <div className=" py-3 px-2 w-full ">
                  <ReactPaginate
                    previousLabel={
                      <span className="flex items-center justify-center w-6 h-6 border rounded-md shadow-md  border-yellow-300">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          className="w-4"
                        >
                          <path d="M10.8284 12.0007L15.7782 16.9504L14.364 18.3646L8 12.0007L14.364 5.63672L15.7782 7.05093L10.8284 12.0007Z"></path>
                        </svg>
                      </span>
                    }
                    nextLabel={
                      <span className="flex items-center justify-center w-6 h-6 border rounded-md shadow-md  border-yellow-300">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          className="w-4"
                        >
                          <path d="M13.1714 12.0007L8.22168 7.05093L9.63589 5.63672L15.9999 12.0007L9.63589 18.3646L8.22168 16.9504L13.1714 12.0007Z"></path>
                        </svg>
                      </span>
                    }
                    pageClassName=" flex items-center justify-center w-6 h-6 text-xs border rounded shadow-md  border-yellow-300 md:hover:bg-yellow-100 mx-1"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    breakLabel="..."
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    pageRangeDisplayed={3}
                    pageCount={
                      transactionsData?.find((item) =>
                        item?.hasOwnProperty("pages")
                      )?.pages
                    }
                    marginPagesDisplayed={1}
                    onPageChange={handlePageChange}
                    containerClassName="flex items-center justify-center mt-2"
                    activeClassName="bg-yellow-400 text-white"
                    forcePage={page}
                  />
                </div>
                <div className="container px-2 py-2 mx-auto sm:p-4 ">
                  <div className="overflow-x-auto">
                    <table className="min-w-full  text-xs   ">
                      <thead className=" ">
                        <tr className="text-center align-top ">
                          <th className="p-1 md:p-3  text-gray-600  ">
                            Payment Date
                          </th>
                          <th className="pl-0.5 py-1 pr-1 text-gray-600  md:p-3 ">
                            Transaction Id
                          </th>
                          <th className="p-1 md:p-3  text-gray-600  ">
                            Amount
                          </th>
                          <th className="p-1 md:p-3  text-gray-600  ">
                            Payment Mode
                          </th>
                          <th className="p-1 md:p-3  text-gray-600  ">
                            Bet Ref
                          </th>
                          <th className="p-1 md:p-3  text-gray-600 ">Status</th>
                        </tr>
                      </thead>
                      {transactionsData[0].map((item) => (
                        <tbody key={item.created_at}>
                          <tr className="border-b border-opacity-20 border-gray-700 text-xxs md:text-xs ">
                            <td className="p-1 md:p-3">
                              <p>
                                {dayjs
                                  .utc(item.created_at)
                                  .tz(timeZone)
                                  .format("YYYY-MM-DD HH:mm:ss A")}
                              </p>
                            </td>

                            <td className="p-1 md:p-3 text-center">
                              {item.transaction_id === null && (
                                <p className="text-center">-</p>
                              )}
                              {item.transaction_id && (
                                <p>{item.transaction_id}</p>
                              )}
                            </td>
                            <td className="p-1 md:p-3 text-center">
                              <p>
                                {item.currency === "null" && (
                                  <span className="mr-0.25"></span>
                                )}
                                {item.currency === "GBP" && (
                                  <span className="mr-0.25">£</span>
                                )}
                                {item.currency === "EUR" && (
                                  <span className="mr-0.25">€</span>
                                )}
                                {item.currency === "USD" && (
                                  <span className="mr-0.25">$</span>
                                )}{" "}
                                {item.amount.toFixed(1)}
                              </p>
                            </td>
                            <td className="p-1 md:p-5">
                              <p>{item.mode}</p>
                            </td>
                            <td className="p-1 md:p-5">
                              <p>{item.SpongeBetRef}</p>
                            </td>
                            <td className="p-2 md:p-3 text-right">
                              <p className="   text-gray-200">
                                {item.is_success ? (
                                  <span className="bg-green-400 rounded-full px-4 py-1 ">
                                    Success
                                  </span>
                                ) : (
                                  <span className="bg-red-400 rounded-full px-4 py-1 ">
                                    Failed
                                  </span>
                                )}
                              </p>
                            </td>
                          </tr>
                        </tbody>
                      ))}
                    </table>
                  </div>
                </div>
              </div>
            ) : (
              <p className="pt-10">No data available...</p>
            )}
          </div>
        </>
      )}
    </section>
  );
};

export default Payment;
