import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import basket from "./basket.svg";
import folder from "./folder.svg";
import home from "./home.svg";
import add from "./add.svg";
import search from "./search.svg";
import spongeIcon from "../assets/sponge-logo.svg";
import basket2 from "./basket2.svg";
import folder2 from "./folder2.svg";
import home2 from "./home2.svg";
import add2 from "./add2.svg";
import bellicon from "./bellicon.svg";
import belldark from "./belldark.svg";
import { Text } from "react-native";
import "../styles/output.css";
import Modal from "react-modal";
import getIsVerified from "./Account_Infomation/isVerified.js";
import PostMessagePopup from "./PostMessagePopup.js";
import Card_popout from "./Card_popout.js";
// import "react-tooltip/dist/react-tooltip.css";
import getToken from "./Token.js";
// import { Tooltip as ReactTooltip } from "react-tooltip";
import getUsername from "./Account_Infomation/Username.js";
import { GlobalContext } from "./GlobalContext.js";
import Card_popout3 from "./Card_popout3.js";

function TopBar(props) {
  const {
    setShowFilteredData,
    userAvatar,
    setUserAvatar,
    chatRef,
    setChatRef,
  } = useContext(GlobalContext);
  const { setUsermessagefeed, userData } = useContext(GlobalContext);
  const { notsocket } = useContext(GlobalContext);
  const [togglesavedmessages, setTogglesavedmessages] = useState(false);
  const [togglemymessages, setTogglemymessages] = useState(false);

  const isUserVerified = getIsVerified();

  const [basketexist, setBasketExist] = useState("No");
  const [homeimage, setHomeImage] = useState(home);
  const [basketimage, setBasketImage] = useState(basket);
  const [addimage, setAddImage] = useState(add);
  const [folderimage, setFolderImage] = useState(folder);
  const [searchimage, setSearchImage] = useState(search);
  const [bell_icon, setBell_icon] = useState(bellicon);
  var link = `https://omnistack.org${process.env.REACT_APP_LINK}`;
  const [hometext, setHomeText] = useState(
    <Text style={{ fontSize: 12 }}>
      Main feed to show you all the new {"\n"}subscriptions. Just click on the
      one
      {"\n"}you want and it will be added to {"\n"}your cart. Go to cart to
      complete
    </Text>
  );
  const [searchtext, setSearchText] = useState(
    <Text style={{ fontSize: 12 }}>
      Search for any subscription that{"\n"}may be of interest.
    </Text>
  );
  const [addtext, setAddText] = useState(
    <Text style={{ fontSize: 12 }}>
      Want to find people to share your{"\n"}subscription? Quickly create your
      {"\n"}tile here. You can also share with {"\n"}friends after creating the
      new tile.
    </Text>
  );
  const [managetext, setManageText] = useState(
    <Text style={{ fontSize: 12 }}>
      Manage all your subscriptions in {"\n"}one place. You can see the open
      {"\n"}tiles, your subscriptions taken by {"\n"}others and the
      subscriptions you {"\n"}have taken.
    </Text>
  );
  const [carttext, setCartText] = useState(
    <Text style={{ fontSize: 12 }}>
      Find your wishlist here! Complete{"\n"}the purchase within 24 hours or
      {"\n"}you risk losing your selection.
    </Text>
  );
  const [profiletext, setProfileText] = useState(
    <Text style={{ fontSize: 12 }}>
      All about your account in one{"\n"}place.
    </Text>
  );
  const customStyles = {
    overlay: {
      zIndex: "100",
      background: "rgba(0, 0, 0, 0.8)",
      overflow: "auto",
    },

    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-48%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "#FFFFFF",
      marginTop: "5%",
    },
  };

  //const [savedactive, setSavedactive] =useState(false);
  //const [myactive, setMyactive] =useState(false);
  const [currentfeed, setCurrentfeed] = useState(props.feedvalue);

  const handleMysavedmessagesclick = () => {
    setCartText("");
    setHomeImage(home);
    setSearchImage(search);
    setBasketImage(basket2);
    setFolderImage(folder);
    setAddImage(add);
    if (currentfeed !== "savedmessages") {
      setTogglesavedmessages(true);
      setCurrentfeed("savedmessages");
    } else {
      setTogglesavedmessages(false);
      setCurrentfeed("messageboard");
    }
  };

  const handleMymessagesclick = () => {
    setUsermessagefeed("Open Posts");
    setManageText("");
    setHomeImage(home);
    setSearchImage(search);
    setBasketImage(basket);
    setFolderImage(folder2);
    setAddImage(add);
    if (currentfeed !== "usermessages") {
      setTogglemymessages(true);
      setCurrentfeed("usermessages");
    } else {
      setTogglemymessages(false);
      setCurrentfeed("messageboard");
    }
  };

  const handlemessageboardsclick = () => {
    setShowFilteredData(false);
    setHomeText("");
    setHomeImage(home2);
    setSearchImage(search);
    setBasketImage(basket);
    setFolderImage(folder);
    setAddImage(add);
    if (currentfeed !== "messageboard") {
      setTogglemymessages(true);
      setCurrentfeed("messageboard");
    } else {
      setTogglemymessages(false);
      setCurrentfeed("messageboard");
    }
  };

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const setModalIsOpenToTrue = () => {
    setHomeImage(home);
    setSearchImage(search);
    setBasketImage(basket);
    setFolderImage(folder);
    setAddImage(add2);
    if (isUserVerified) {
      // console.log("d");
      setModalIsOpen(true);
    }
  };
  const setModalIsOpenToFalse = () => {
    setHomeImage(home);
    setSearchImage(search);
    setBasketImage(basket);
    setFolderImage(folder);
    setAddImage(add);
    setModalIsOpen(false);
  };
  notsocket.onmessage = (event) => {
    if (JSON.parse(event.data)["user"] === getUsername()) {
      // console.log(JSON.parse(event.data)["is_read"]);
      setBell_icon(belldark);
    }
    if (JSON.parse(event.data)["user"] == getUsername()) {
      if (JSON.parse(event.data)["tileref"] !== null) {
        // console.log(JSON.parse(event.data)["tileref"]);
        setChatRef((prev) => [...prev, JSON.parse(event.data)["tileref"]]);
      }
    }
  };

  useEffect(() => {
    if (userData) {
      setUserAvatar(userData?.logo);
    }
  }, [userData]);

  useEffect(() => {
    if (window.location.pathname === "/home") {
      setHomeImage(home2);
    } else if (
      window.location.pathname === "/my-posts/open" ||
      window.location.pathname === "/my-posts/shared" ||
      window.location.pathname === "/my-posts/subscriptions"
    ) {
      setFolderImage(folder2);
    }

    var accesskeytoken = getToken();
    fetch(link + "savedmessage-exist/", {
      method: "POST",
      body: JSON.stringify({
        user: getUsername(),
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: "Bearer " + accesskeytoken,
      },
    })
      .then((response) => response.json())
      .then((json) => setBasketExist(json["Exist"]));
  }, []);

  // console.log(chatRef);

  return (
    <header>
      <div className="z-30 fixed  w-full border-b border-gray-100 bg-gradient-to-b from-white to-yellow-100">
        <div className="max-w-7xl mx-auto">
          {/* Navbar Top-row */}
          <div className=" px-3 grid w-full grid-cols-3  shadow-sm border-b border-yellow-100  place-items-center">
            {/* Profile_Icon */}
            <div id="profile" className={"relative md:ml-4  w-full"}>
              <Card_popout>
                <button
                  onClick={() => {
                    setProfileText("");
                    setShowFilteredData(false);
                  }}
                  className={"  border:none  "}
                >
                  <img
                    src={
                      userAvatar ||
                      "https://zaplynimages.s3.eu-west-2.amazonaws.com/sponge_avatar_placeholder.svg"
                    }
                    alt="userimage"
                    className="h-10 w-10 lg:h-12 lg:w-12 my-1 rounded-full cursor-pointer"
                  />
                </button>
              </Card_popout>
            </div>
            {/* sponge logo*/}
            <div onClick={handlemessageboardsclick}>
              <Link to="/home">
                <img
                  src={spongeIcon}
                  alt="sponge"
                  className="h-12 md:h-16 cursor-pointer shdaow-sm"
                />
              </Link>
            </div>

            <div className="flex gap-5 w-full justify-end ">
              {/* notification icon */}
              <div id="search" className="relative my-2">
                <Card_popout3>
                  <button
                    onClick={() => {
                      setBell_icon(bellicon);
                      var accesskeytoken = getToken();
                      fetch(link + "read-notifications/", {
                        method: "POST",
                        body: JSON.stringify({
                          user: getUsername(),
                        }),
                        headers: {
                          "Content-type": "application/json; charset=UTF-8",
                          Authorization: "Bearer " + accesskeytoken,
                        },
                      })
                        .then((response) => response.json())
                        .then((json) => console.log(json["Notification-text"]));
                    }}
                  >
                    <img
                      src={bell_icon}
                      className="h-8 w-8 xl:h-10 xl:w-10 cursor-pointer"
                      alt=""
                    />
                  </button>
                </Card_popout3>
              </div>

              {/* Search_Icon */}
              {/* <div id="search" className="relative md:mr-4 my-2">
              <Card_popout2>
                <button
                  onClick={() => {
                    setShowFilteredData(false);
                    setHomeImage(home);
                    setSearchImage(search2);
                    setBasketImage(basket);
                    setFolderImage(folder);
                    setAddImage(add);
                    setSearchText("");
                  }}
                >
                  <img
                    src={searchimage}
                    className="h-8 w-8 xl:h-10 xl:w-10 cursor-pointer"
                    alt=""
                  />
                </button>
              </Card_popout2>
            </div> */}
            </div>
          </div>

          {/* Navbar Bottom-row */}
          <div className="flex items-center justify-around md:justify-center md:gap-20 pt-2 md:py-2  shadow-sm">
            {/* Home_Icon */}

            <div id="home" className=" ">
              <p
                className={
                  currentfeed == "messageboard"
                    ? " flex flex-col items-center cursor-pointer  text-center text-black text-xs  "
                    : " flex flex-col items-center  cursor-pointer text-center hover:text-black  text-xs   "
                }
                onClick={handlemessageboardsclick}
              >
                <Link to="/home">
                  <img
                    src={homeimage}
                    alt=""
                    className="h-6 w-6 xl:h-8 xl:w-8"
                  />
                  <p className="text-xs">Home</p>
                </Link>
              </p>
            </div>
            {/* Add_Icon */}
            <div id="add" className=" ">
              <p
                className="cursor-pointer text-center flex flex-col items-center hover:text-black  text-xs  "
                onClick={setModalIsOpenToTrue}
              >
                <img src={addimage} alt="" className="h-6 w-6 xl:h-8 xl:w-8" />
                <p className="text-xs ">Add New</p>
              </p>

              <Modal
                isOpen={modalIsOpen}
                onRequestClose={() => setModalIsOpen(false)}
                className="font-bellota  max-w-xl z-50 mx-5 sm:mx-auto my-10 md:my-16 rounded-md focus:outline-none shadow-lg text-center"
                overlayClassName="bg-black z-50 bg-opacity-60 fixed inset-0 overflow-y-auto"
              >
                <button
                  onClick={setModalIsOpenToFalse}
                  className="flex justify-start mb-1 px-2 button shadow-md focus:outline-none cursor-pointer bg-blue-200 text-red-600 rounded-md hover:bg-red-400 hover:text-gray-100 text-sm   flex-shrink"
                >
                  x
                </button>
                <PostMessagePopup
                  onClick={setModalIsOpenToFalse}
                  // onPost={
                  //   /*value=>setRefreshmessage((refreshmessage)=>[... refreshmessage, value])*/
                  //   (value) => props.postprop(value)
                  // }
                />
              </Modal>
            </div>
            {/* Manage_Icon */}
            <div className=" ">
              <Link to="/my-posts/open">
                <div
                  className={
                    currentfeed === "usermessages"
                      ? "  flex flex-col items-center justify-center cursor-pointer  text-black  text-xs   "
                      : "  flex flex-col items-center justify-center cursor-pointer  hover:text-black  text-xs   "
                  }
                  onClick={handleMymessagesclick}
                >
                  <img
                    src={folderimage}
                    alt=""
                    className="xl:h-8 xl:w-8 h-6 w-6"
                  />
                  <p className="text-xs ">Manage</p>
                </div>
              </Link>
            </div>
            {/* CArt */}
            <div className="">
              <div
                id="cart"
                className={
                  currentfeed === "savedmessages"
                    ? " flex flex-col items-center relative  cursor-pointer  text-black   text-xs   "
                    : " flex flex-col items-center relative  cursor-pointer  hover:text-black  text-xs   "
                }
                onClick={handleMysavedmessagesclick}
              >
                <Link to="/basket">
                  <img
                    src={basketimage}
                    alt=""
                    className="xl:h-8 xl:w-8 h-6 w-6"
                  />
                  <p className="text-xs">Cart</p>
                </Link>
                {userData?.item_count > 0 && (
                  <span className="absolute -top-3 -right-3 bg-red-700 rounded-full h-5 w-5   text-xs flex items-center justify-center text-white">
                    {userData?.item_count}
                  </span>
                )}
              </div>
            </div>

            {/* Search_Icon */}
            {/* <div
              id="search"
              className="flex flex-col items-center  cursor-pointer  text-black    "
            >
              <Card_popout2>
                <button
                  onClick={() => {
                    setShowFilteredData(false);
                    setHomeImage(home);
                    setSearchImage(search2);
                    setBasketImage(basket);
                    setFolderImage(folder);
                    setAddImage(add);
                    setSearchText("");
                  }}
                >
                  <img
                    src={searchimage}
                    className="xl:h-8 xl:w-8 h-6 w-6"
                    alt=""
                  />
                  <p className=" text-xs   ">Search</p>
                </button>
              </Card_popout2>
            </div> */}
          </div>
        </div>
      </div>

      {/* React tool-tip(s)  */}
      {/* <ReactTooltip
        style={{ backgroundColor: "#D7E3F0", borderRadius: 10 }}
        anchorId="home"
        place="bottom"
        variant="warning"
        content={hometext}
      />
      <ReactTooltip
        style={{ backgroundColor: "#D7E3F0", borderRadius: 10 }}
        anchorId="search"
        place="bottom"
        variant="warning"
        content={searchtext}
      />
      <ReactTooltip
        style={{ backgroundColor: "#D7E3F0", borderRadius: 10 }}
        anchorId="cart"
        place="bottom"
        variant="warning"
        content={carttext}
      />
      <ReactTooltip
        style={{ backgroundColor: "#D7E3F0", borderRadius: 10 }}
        anchorId="manage"
        place="bottom"
        variant="warning"
        content={managetext}
      />
      <ReactTooltip
        style={{ backgroundColor: "#D7E3F0", borderRadius: 10 }}
        anchorId="profile"
        place="bottom"
        variant="warning"
        content={profiletext}
      />
      <ReactTooltip
        style={{ backgroundColor: "#D7E3F0", borderRadius: 10 }}
        anchorId="add"
        place="bottom"
        variant="warning"
        content={addtext}
      /> */}
    </header>
  );
}

export default TopBar;
