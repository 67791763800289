import React, { useState, useContext, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Api from "../../../../config/Api.json";
import fernet from "fernet";
import Modal from "react-modal";
import IconComponent from "../../../Utility/IconComponent";
import ViewMessagePopup from "../../../ViewMessagePopup";
import Support from "../../../Support/Support";
import getToken from "../../../Token";
import getUsername from "../../../Account_Infomation/Username";
import AddChatMessagePopup from "../../../AddChatMessagePopup";
import { GlobalContext } from "../../../GlobalContext";
import First from "../../../../assets/first.svg";
import Second from "../../../../assets/second.svg";
import Third from "../../../../assets/third.svg";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "../../../../styles/styles.css";
import Cards from "../../../Cards";
function SubscribedCard({ message }) {
  const [subscriptionToggle, setSubscriptionToggle] = useState(
    message.Subscription_is_ended
  );
  const { notsocket, chatRef, setChatRef } = useContext(GlobalContext);
  const username = getUsername();
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const [supportModel, setsupportModel] = useState(false);
  const [supportModelData, setsupportModelData] = useState({});

  const [currentsubject, setcurrentsubject] = useState(null);
  const [currentbody, setcurrentbody] = useState(null);
  const [currentcurrency, setcurrentcurrency] = useState(null);
  const [currenttileusername, setcurrenttileusername] = useState(null);
  const [currenttilepassword, setcurrenttilepassword] = useState(null);
  const [curentTileDetails, setcurentTileDetails] = useState({});

  const curUser = getUsername();

  const customStyles = {
    overlay: {
      zIndex: "100",
      background: "rgba(52, 52, 52, 0.8)",
      overflow: "scroll",
    },

    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "#FFFFFF",
      marginTop: "20%",
    },
  };
  const [chatModalIsOpen, setChatModalIsOpen] = useState(false);

  const setChatModalIsOpenToFalse = () => {
    setChatModalIsOpen(false);
  };
  const setModalIsOpenToTrue = () => {
    setModalIsOpen(true);
  };

  const setModalIsOpenToFalse = () => {
    setModalIsOpen(false);
    setcurentTileDetails({});
  };

  const closeSupportModel = () => {
    setsupportModel(false);
  };

  const ViewSupportModel = (message) => {
    setsupportModel(true);
    setsupportModelData(message);
  };

  const ViewDetails = (e) => {
    let accesskeytoken = getToken();
    fetch(
      `https://omnistack.org${process.env.REACT_APP_LINK}` + "viewuser-cred/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accesskeytoken,
        },
        body: JSON.stringify({
          user: curUser,
          SaturnTileRef: message.SaturnTileRef,
        }),
      }
    )
      .then((response) => response.json())
      .then((json) => {
        var secret = new fernet.Secret(process.env.REACT_APP_FERNET_KEY);
        var token = new fernet.Token({
          secret: secret,
          token: String(json),
          ttl: 0,
        });
        var result = token.decode();
        json = JSON.parse(result);
        console.log(json);
        setcurentTileDetails(json);
        setModalIsOpenToTrue();
      });
  };

  function handleSubscription() {
    setSubscriptionToggle(!subscriptionToggle);
  }

  return (
    <div className="">
      <div className=" bg-gradient-to-t from-white to-yellow-50 border border-yellow-100 p-2 m-2 rounded-lg order shadow-md justify-items-center overflow-x-auto ">
        <Cards tileData={message} />

        <div className="flex mt-4 justify-between  ">
          <div className="flex gap-2">
            {" "}
            <button
              className=" px-4 py-1   rounded-full shadow-sm focus:outline-none cursor-pointer bg-gradient-to-r from-yellow-200 to-red-200 text-xs font-semibold"
              onClick={ViewDetails}
            >
              View Details
            </button>
            <Modal
              isOpen={modalIsOpen}
              className="font-fredoka  max-w-xl z-50 mx-5 sm:mx-auto my-10 md:my-16 rounded-md focus:outline-none shadow-lg text-center"
              overlayClassName="bg-black z-50 bg-opacity-60 fixed inset-0 overflow-y-auto"
              // onRequestClose={() => setModalIsOpen(false)}
            >
              <button
                // onClick={setModalIsOpenToFalse}
                className="flex justify-start mb-1 px-2 pb-1 button rounded-lg shadow-sm focus:outline-none cursor-pointer bg-gradient-to-r from-yellow-200 to-red-200 text-sm   flex-shrink"
                onClick={setModalIsOpenToFalse}
              >
                x
              </button>
              <ViewMessagePopup
                tileData={message}
                tileDetails={curentTileDetails}
                onClick={setModalIsOpenToFalse}
              />
            </Modal>
            <button
              className={` ${
                chatRef.includes(message.SaturnTileRef)
                  ? "bg-gradient-to-r from-yellow-500 to-red-500"
                  : "bg-gradient-to-r from-yellow-200 to-red-200"
              } px-4 py-1  rounded-full shadow-sm focus:outline-none cursor-pointer text-xs font-semibold `}
              onClick={() => {
                setChatModalIsOpen(true);
                let accesstoken = getToken();
                fetch(
                  `https://omnistack.org${process.env.REACT_APP_LINK}` +
                    "read-notifications-chat/",
                  {
                    method: "POST",
                    body: JSON.stringify({
                      user: username,
                      tileref: message.SaturnTileRef,
                    }),
                    headers: {
                      "Content-type": "application/json; charset=UTF-8",
                      Authorization: "Bearer " + accesstoken,
                    },
                  }
                )
                  .then((response) => response.json())
                  .then((json) => {
                    notsocket.send(
                      JSON.stringify({
                        user: username,
                      })
                    );
                    setChatRef(
                      chatRef.filter((item) => item !== message.SaturnTileRef)
                    );
                  });
              }}
            >
              Chat
            </button>
            <Modal
              isOpen={chatModalIsOpen}
              className=" max-w-lg z-50 mx-5 sm:mx-auto my-10 md:my-16 rounded-lg shadow-md text-center"
              overlayClassName="bg-black z-50 bg-opacity-30 fixed inset-0 overflow-y-auto"
              onRequestClose={() => setChatModalIsOpen(false)}
            >
              <button
                onClick={setChatModalIsOpenToFalse}
                className="flex justify-start px-2 pb-1 button rounded-lg shadow-sm focus:outline-none cursor-pointer bg-gradient-to-r from-yellow-200 to-red-200 text-sm   flex-shrink"
              >
                x
              </button>
              <AddChatMessagePopup
                tileData={message}
                closeOnClick={setChatModalIsOpenToFalse}
              />
            </Modal>
          </div>

          <div className="flex gap-1 items-center p-2 text-xxs  rounded-full font-semibold">
            {" "}
            Connection{" "}
            {message.connection === "1st" ? (
              <img src={First} alt="" className="w-3 h-3" />
            ) : (
              <>
                {message.connection === "1st" ? (
                  <img src={Second} alt="" className="w-5 h-5" />
                ) : (
                  <img src={Third} alt="" className="w-5 h-5" />
                )}
              </>
            )}
          </div>
        </div>
        <div className="flex flex-col">
          <div className="flex items-center justify-between mt-4">
            <div className="">
              <buttonupdatemess
                className="flex-nowrap flex-shrink px-2 py-1 button w-24  rounded-full shadow-sm focus:outline-none cursor-pointer bg-gradient-to-r from-yellow-200 to-red-200 text-xs font-semibold "
                onClick={(e) => ViewSupportModel(message)}
              >
                Report issue
              </buttonupdatemess>
            </div>
            {/* <div class="flex-shrink w-4/12"></div> */}
            <ToastContainer autoClose={2500} />
            <div className="">
              <button
                className="px-4 py-1  rounded-full shadow-sm focus:outline-none cursor-pointer bg-gradient-to-r from-yellow-200 to-red-200 text-xs font-semibold  "
                onClick={() => {
                  let accesstoken = getToken();
                  fetch(
                    `https://omnistack.org${process.env.REACT_APP_LINK}` +
                      "cancel-sub/",
                    {
                      method: "POST",
                      body: JSON.stringify({
                        user: curUser,
                        SaturnTileRef: message.SaturnTileRef,
                      }),
                      headers: {
                        "Content-type": "application/json; charset=UTF-8",
                        Authorization: "Bearer " + accesstoken,
                      },
                    }
                  )
                    .then((response) => response.json())
                    .then((json) => {
                      toast(
                        JSON.stringify(json["Notification-text"]).replace(
                          /"|'/g,
                          ""
                        )
                      );
                      notsocket.send(
                        JSON.stringify({
                          user: username,
                        })
                      );
                    });
                  handleSubscription();
                }}
              >
                {subscriptionToggle ? (
                  <p>Renew Subscription</p>
                ) : (
                  <p className="">End Subscription</p>
                )}
              </button>
            </div>
          </div>
          <div
            id="left contact"
            className="flex items-center justify-center mt-3 text-center p-1 align-center rounded-full   border-t border-b border-yellow-300 shadow-sm text-xs"
          >
            {"   "}
            {subscriptionToggle ? (
              <p>Subscription End on: {message.expiry_date}</p>
            ) : (
              <p>Next Billing Date : {message.next_billing_date}</p>
            )}
          </div>
        </div>
      </div>

      <Modal
        isOpen={supportModel}
        className="font-fredoka  max-w-xl z-50 mx-5 sm:mx-auto my-10 md:my-16 rounded-md focus:outline-none shadow-lg text-center"
        overlayClassName="bg-black z-50 bg-opacity-60 fixed inset-0 overflow-y-auto"
        onRequestClose={() => setsupportModel(false)}
      >
        <button
          onClick={() => setsupportModel(false)}
          className="flex justify-start mb-1 px-2 pb-1 button rounded-lg shadow-lg focus:outline-none cursor-pointer hover:bg-red-800 hover:text-white bg-gradient-to-r from-yellow-200 to-red-200 text-sm   flex-shrink"
        >
          x
        </button>
        <div>
          <Support
            tileDetails={{ supportModelData }}
            handleClose={closeSupportModel}
          />
        </div>
      </Modal>
    </div>
  );
}

export default SubscribedCard;
