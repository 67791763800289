import React, { useContext } from "react";
import { GlobalContext } from "../../../GlobalContext";
import SubscribedCard from "./SubscribedCard";
import { useQuery } from "react-query";
import Endpoints from "../../../../api/Endpoints";

function UserSubscription() {
  // globalcontext
  // fetch sharedsubs
  const {
    isLoading: isUserSubsDataLoading,
    data: userSubs,
    isError: isUserSubsError,
    error: userSubsErr,
  } = useQuery("userSubs", Endpoints.GetUserSubscription, {
    // refetchOnWindowFocus: false,
  });

  if (isUserSubsDataLoading) {
    return <p className="text-center p-2">Loading ...</p>;
  }

  if (isUserSubsError) {
    return (
      <p className="text-center p-2">
        Something went wrong! Please try again later..
      </p>
    );
  }

  return (
    <div className=" pb-40 ">
      <div
        id="column layout"
        className="flex flex-row max-w-2xl lg:max-w-3xl  mx-auto "
      >
        <div className=" grid md:grid-cols-2  w-full ">
          {userSubs !== null ? (
            userSubs?.map((message) => (
              <SubscribedCard key={message.id} message={message} />
            ))
          ) : (
            <p>Loading data Please wait ...</p>
          )}
        </div>
      </div>
    </div>
  );
}

export default UserSubscription;
