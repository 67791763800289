import React from "react";

import { Auth, Hub } from "aws-amplify";
import { useState, useEffect } from "react";
import { Route, useLocation, Routes, Navigate } from "react-router-dom";

import "./components/burgercss.css";
import Sucess from "./components/Pages/payment/Sucess";
import UnderProcess from "./components/Pages/payment/UnderProcess";
import ShareFeed from "./components/Pages/Share.js";
import Test from "./Test.js";
import LandingPage from "./components/Pages/LandingPage.js";
import AuthenticatedRoutes from "./routes/AuthenticatedRoutes.js";
import Login from "./components/Pages/auth/Login.js";
import Register from "./components/Pages/auth/Register.js";
import ResetPassword from "./components/Pages/auth/ResetPassword.js";
import ConfirmOtp from "./components/Pages/auth/ConfirmOtp.js";
import NewPassword from "./components/Pages/auth/NewPassword.js";
import { QueryClient, QueryClientProvider } from "react-query";
const queryClient = new QueryClient();
function App() {
  const location = useLocation();
  const [user, setUser] = useState(undefined);
  const [loading, setLoading] = useState(true);

  const checkUser = async () => {
    try {
      const authUser = await Auth.currentAuthenticatedUser({
        bypassCache: true,
      });
      setUser(authUser);
    } catch (error) {
      setUser(null);
    }
  };

  useEffect(() => {
    checkUser();
  }, []);

  useEffect(() => {
    const listener = (data) => {
      if (data.payload.event === "signIn" || data.payload.event === "signOut") {
        checkUser();
      }
    };
    Hub.listen("auth", listener);
    return () => Hub.remove("auth", listener);
  }, []);

  if (user === undefined) {
    return <p>...</p>;
  }

  return (
    <QueryClientProvider client={queryClient}>
      <Routes>
        <Route path="/share/:tile" element={<ShareFeed />} />
        <Route
          path="/underprocess/:checkoutsession/:tile"
          element={<UnderProcess />}
        />

        <Route path="/sucess" element={<Sucess />} />

        {user ? (
          <Route path="/" element={<AuthenticatedRoutes />}>
            <Route path="/" element={<Navigate to="/home" replace />} />
            <Route path="/*" element={<Test />} />
          </Route>
        ) : (
          <>
            <Route path="/" element={<LandingPage />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/resetpassword" element={<ResetPassword />} />
            <Route path="/verifyemail" element={<ConfirmOtp />} />
            <Route path="/newpassword" element={<NewPassword />} />
          </>
        )}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </QueryClientProvider>
  );
}

export default App;
// export default withAuthenticator(App, false, (document.title = "Sponge App"));
