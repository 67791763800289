import React, { useRef, useState, useContext, useEffect } from "react";
import { GlobalContext } from "./GlobalContext";
import Select from "react-select";

import getToken from "./Token";
import { useNavigate } from "react-router";

function DestinationTab({ children }) {
  var link = `https://omnistack.org${process.env.REACT_APP_LINK}`;
  const accesstoken = getToken();

  const [isOpen, setIsOpen] = useState(false);
  const wrapperRef = useRef(null);

  const navigate = useNavigate();
  const {
    setUsermessagefeed,
    selectedCustomOptions,
    setSelectedCustomOptions,
    selecteSubDate,
    setSelectedSubDate,
    selectedSubsOptions,
    setSelectedSubsOptions,
  } = useContext(GlobalContext);

  const [selectedDate, setSelectedDate] = useState(selecteSubDate);

  const [options, setOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState(selectedSubsOptions);

  const [customOptions, setCustomOptions] = useState([]);
  const [selectedCustomOptionsLocal, setSelectedCustomOptionsLocal] = useState(
    selectedCustomOptions
  );

  useEffect(() => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + accesstoken);

    fetch(link + "subject-list/", {
      headers: myHeaders,
    })
      .then((res) => {
        if (!res.ok) {
          throw Error("could not fetch the data for that resource");
        }
        return res.json();
      })
      .then((data) => {
        // console.log("resdata", data);
        setOptions(data?.slice(1));
      });

    // Custom option
    const customOptiondata = [
      { id: 1, value: "Open", data: "Open" },
      { id: 2, value: "Shared", data: "Shared" },
      { id: 3, value: "Subs", data: "My Subs" },
    ];
    setCustomOptions(customOptiondata);
  }, []);

  const handleOptionChange = (selectedOptions) => {
    setSelectedOptions(selectedOptions);
    // console.log("Selectedoption", selectedOptions);
  };
  const handleCustomOptionChange = (selectedcustomOptions) => {
    setSelectedCustomOptionsLocal(selectedcustomOptions);
    // console.log("Selectedcustomoption", selectedcustomOptions);
  };

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
    // console.log(selectedDate);
  };

  const formatDate = (date) => {
    // console.log(date);
    if (date) {
      const [year, month, day] = date?.split("-");
      return `${year}-${month}-${day}`;
    } else {
      return date;
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (
      selectedOptions.length === 0 &&
      selectedCustomOptionsLocal.length === 0 &&
      selectedDate === ""
    ) {
      return;
    } else {
      setSelectedSubsOptions(selectedOptions);
      setSelectedCustomOptions(selectedCustomOptionsLocal);
      setSelectedSubDate(formatDate(selectedDate));

      setUsermessagefeed("filter");
      navigate("/my-posts/filter");
    }
  };

  const handleReset = () => {
    setSelectedSubsOptions([]);
    setSelectedCustomOptions([]);
    setSelectedSubDate("");
    setSelectedCustomOptionsLocal([]);
    setSelectedOptions([]);
    setSelectedDate("");
    setUsermessagefeed("Shared Posts");
  };

  const toggleBox = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (wrapperRef && !wrapperRef.current.contains(event.target)) {
      setIsOpen(false);
      setSelectedDate(null);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div ref={wrapperRef}>
      <div>
        <button className="focus:outline-none" onClick={toggleBox}>
          {children}
        </button>
      </div>
      {isOpen && (
        <div className="z-10 absolute right-0 bg-gradient-to-t from-white to-yellow-50 border border-yellow-100 shadow-md">
          <div className="px-4 leading-tight mb-4   pt-2 text-xs justify-between items-center flex-col">
            <form onSubmit={handleSubmit} className="flex flex-col  space-y-3">
              <div className="">
                <p className=" ml-1 text-xs">Option(s)</p>
                <Select
                  className="w-48"
                  value={selectedCustomOptionsLocal}
                  onChange={handleCustomOptionChange}
                  options={customOptions}
                  placeholder={"Select"}
                  isMulti
                  autosize={false}
                  getOptionValue={(option) => option.value} //value is what it is
                  getOptionLabel={(option) => option.data} //label is what you see
                />
              </div>
              <div className="md:w-52">
                <p className=" text-xs ml-1">Topics(s)</p>
                <Select
                  className="w-48"
                  value={selectedOptions}
                  onChange={handleOptionChange}
                  options={options}
                  placeholder={"select"}
                  isMulti
                  autosize={false}
                  getOptionValue={(option) => option.subjectname} //value is what it is
                  getOptionLabel={(option) => option.subjectname} //label is what you see
                />
              </div>

              <div>
                <p className="text-xs ml-1">Subscribed Date</p>
                <input
                  type="date"
                  value={selectedDate}
                  onChange={handleDateChange}
                  className="bg-white border border-gray-300 rounded-md shadow-sm p-2 text-xs text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                />
              </div>

              <div className="flex items-center justify-around gap-4">
                <p
                  onClick={handleReset}
                  className=" px-4 py-2  rounded-full shadow-sm focus:outline-none cursor-pointer bg-gradient-to-r from-yellow-200 to-red-200 text-xs font-bold md"
                >
                  Reset
                </p>{" "}
                <button
                  type="submit"
                  className=" px-4 py-2  rounded-full shadow-sm focus:outline-none cursor-pointer bg-gradient-to-r from-yellow-200 to-red-200 text-xs font-bold md"
                >
                  Filter
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}

export default DestinationTab;
