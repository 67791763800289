import React, { useState } from "react";
import IconComponent from "./Utility/IconComponent";
import Modal from "react-modal";
import getToken from "./Token";
import getUsername from "./Account_Infomation/Username";
import fernet from "fernet";

import BundleIcon from "../assets/icons/7549101_user_interface_layer_layers_design_icon 1.svg";
export default function Cards({ tileData }) {
  const [isBundleModalOpen, setIsBundleModalOpen] = useState(false);

  const [bundleData, setBundleData] = useState(null);

  const ViewDetails = async (e) => {
    const accesskeytoken = getToken();
    const userName = getUsername();

    if (
      tileData?.Tile_status === "Open" ||
      tileData?.Tile_status === "Saved" ||
      tileData["tile-subscribed"] === "no"
    ) {
      return;
    } else {
      try {
        const response = await fetch(
          `https://omnistack.org${process.env.REACT_APP_LINK}` +
            "viewuser-cred/",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + accesskeytoken,
            },
            body: JSON.stringify({
              user: userName,
              SaturnTileRef: tileData.SaturnTileRef,
            }),
          }
        );
        const json = await response.json();

        if (json) {
          const secret = new fernet.Secret(process.env.REACT_APP_FERNET_KEY);
          const token = new fernet.Token({
            secret: secret,
            token: String(json),
            ttl: 0,
          });
          const result = token.decode();
          const jsonObj = JSON.parse(result);
          // console.log(json);
          setBundleData(jsonObj);
        }
      } catch (error) {}
    }
  };

  // console.log(credData);
  return (
    <>
      <div className=" relative flex items-center justify-between px-3 pt-1 pb-5">
        {tileData.is_bundle && (
          <div
            onClick={() => {
              ViewDetails();
              setIsBundleModalOpen(true);
            }}
            className="absolute -top-0.5 left-4 flex items-center gap-1 animate-pulse cursor-pointer "
          >
            <img src={BundleIcon} alt="" className=" w-4 h-4 " />
            <p className="text-xs">Bundle</p>
          </div>
        )}
        <div className=" w-28 h-28 text-xs text-center">
          {tileData?.is_bundle ? (
            <div
              className="cursor-pointer"
              onClick={() => {
                ViewDetails();
                setIsBundleModalOpen(true);
              }}
            >
              <div className="relative">
                <img
                  className=" rounded-md "
                  src={`${tileData?.bundle_image}`}
                  alt="err"
                />
                <p className="absolute inset-0 flex items-center justify-center  text-7xl text-white ">
                  {tileData?.subject.charAt(0)}
                </p>
              </div>
              <p className="">{tileData?.subject}</p>
            </div>
          ) : (
            <IconComponent
              subject={tileData.subject}
              bundle={tileData?.is_bundle}
            />
          )}
        </div>

        <div className="flex flex-col text-center">
          <p className="  py-2  text-xl">{tileData.author}</p>
          <p className="  pt-1  border-t border-yellow-300 text-6xl  ">
            {tileData.body}
          </p>
          <p className="text-xs  mb-6 ">
            {tileData.currency} {`/month`}
          </p>
        </div>
      </div>

      {/* subs modal */}
      <Modal
        isOpen={isBundleModalOpen}
        className=" max-w-xl z-50 mx-5 sm:mx-auto my-10 md:my-16 focus:outline-none rounded-md shadow-lg text-center"
        overlayClassName="bg-black z-50  bg-opacity-10 fixed focus:outline-none inset-0 overflow-y-auto"
        onRequestClose={() => setIsBundleModalOpen(false)}
      >
        <button
          onClick={() => setIsBundleModalOpen(false)}
          className="flex justify-start mb-1 px-2 pb-1 button shadow-md focus:outline-none cursor-pointer bg-yellow-400 rounded-md text-sm   flex-shrink"
        >
          x
        </button>
        <div className="bg-white pb-10 px-5 rounded-md font-bellota">
          <h2 className="text-center py-5 text-lg">Available Subscriptions</h2>
          <div className="grid grid-cols-2  md:grid-cols-4 gap-5">
            {tileData?.is_bundle &&
            (tileData?.Tile_status === "Open" ||
              tileData?.Tile_status === "Saved" ||
              tileData["tile-subscribed"] === "no")
              ? tileData?.bundle_data !== null &&
                Array.isArray(tileData?.bundle_data)
                ? tileData?.bundle_data?.map((item, idx) => (
                    <div key={idx} className="w-28 h-28  text-xs">
                      <IconComponent subject={item} bundle={false} />
                    </div>
                  ))
                : tileData?.bundle_data[tileData?.SaturnTileRef].map(
                    (item, idx) => (
                      <div key={idx} className="w-28 h-28  text-xs">
                        <IconComponent subject={item} bundle={false} />
                      </div>
                    )
                  )
              : bundleData !== null &&
                bundleData !== undefined &&
                Object.keys(bundleData).map((item, idx) => (
                  <div key={idx} className="w-28 h-28 text-xs ">
                    <IconComponent subject={item} bundle={false} />
                  </div>
                ))}
          </div>
        </div>
      </Modal>
    </>
  );
}
