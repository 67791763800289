import getUsername from "../components/Account_Infomation/Username";
import getToken from "../components/Token";

function checkConnection(value) {
  if (value === "first degree") {
    return "1st";
  } else if (value === "second degree") {
    return "2nd";
  } else if (value === "third degree") {
    return "3rd+";
  }
}

const getUserData = async () => {
  const accesskeytoken = getToken();
  const curUser = getUsername();
  var link = `https://omnistack.org${process.env.REACT_APP_LINK}`;

  try {
    const response = await fetch(link + "get-user-data/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accesskeytoken,
      },
      body: JSON.stringify({ user: curUser }),
    });

    const json = await response.json();
    return json;
  } catch (error) {
    console.log("Something went wrong", error);
  }
};

// MessageBoard data
const GetSpongeData = async () => {
  const accesskeytoken = getToken();
  const curUser = getUsername();
  var link = `https://omnistack.org${process.env.REACT_APP_LINK}`;
  try {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer " + accesskeytoken);
    const response = await fetch(link + "message-list/", {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        user: curUser,
        SaturnTileRef: "1",
      }),
    });
    const json = await response.json();

    if (json) {
      const modifieddata = json?.map((item) => ({
        ...item,
        connection: checkConnection(item.connection),
      }));
      return modifieddata.reverse();
    }
  } catch (error) {
    if (error instanceof SyntaxError) {
      // Unexpected token < in JSON
      console.log("There was a SyntaxError", error);
    } else {
      console.log("Oops Something went wrong!", error);
    }
  }
};

// open subs data
const GetOpenSubscription = async () => {
  var link = `https://omnistack.org${process.env.REACT_APP_LINK}`;
  const accesskeytoken = getToken();
  const curUser = getUsername();

  try {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer " + accesskeytoken);
    const response = await fetch(link + "message-list/", {
      headers: myHeaders,
    });
    const json = await response.json();

    if (json) {
      const modifieddata = json
        ?.filter((el) => el.author == curUser)
        .map((item) => ({
          ...item,
          subsType: "open",
        }));
      return modifieddata.reverse();
    }
  } catch (error) {
    if (error instanceof SyntaxError) {
      // Unexpected token < in JSON
      console.log("There was a SyntaxError", error);
    } else {
      console.log("Oops Something went wrong!", error);
    }
  }
};

// shared subs data
const GetSharedSubscription = async () => {
  var link = `https://omnistack.org${process.env.REACT_APP_LINK}`;
  const accesskeytoken = getToken();
  const curUser = getUsername();
  try {
    const response = await fetch(link + "savedmessage-list/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accesskeytoken,
      },
      body: JSON.stringify({ user: curUser }),
    });
    const json = await response.json();

    if (json) {
      const modifieddata = json?.map((item) => ({
        ...item,
        subsType: "shared",
      }));
      return modifieddata.reverse();
    }
  } catch (error) {
    if (error instanceof SyntaxError) {
      // Unexpected token < in JSON
      console.log("There was a SyntaxError", error);
    } else {
      console.log("Oops Something went wrong!", error);
    }
  }
};

// user subs data
const GetUserSubscription = async () => {
  const accesskeytoken = getToken();
  const curUser = getUsername();
  try {
    const response = await fetch(
      `https://omnistack.org${process.env.REACT_APP_LINK}` + "message-list/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accesskeytoken,
        },
        body: JSON.stringify({
          user: curUser,
          SaturnTileRef: "0",
        }),
      }
    );
    const json = await response.json();

    if (json) {
      const modifieddata = json
        ?.filter((item) => item.user === curUser)
        .map((item) => ({
          ...item,
          connection: checkConnection(item.connection),
          subsType: "mysubs",
        }));
      return modifieddata.reverse();
    }
  } catch (error) {
    if (error instanceof SyntaxError) {
      // Unexpected token < in JSON
      console.log("There was a SyntaxError", error);
    } else {
      console.log("Oops Something went wrong!", error);
    }
  }
};

// get user cartData
const fetchUserBasketSubs = async () => {
  var link = `https://omnistack.org${process.env.REACT_APP_LINK}`;
  const accesskeytoken = getToken();
  const curUser = getUsername();
  try {
    const response = await fetch(link + "savedmessageuser-list/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accesskeytoken,
      },
      body: JSON.stringify({ user: curUser }),
    });

    const json = await response.json();
    return json;

    // return data;
  } catch (error) {
    return error.message;
  }
};

const Endpoints = {
  getUserData,
  GetSpongeData,
  GetOpenSubscription,
  GetSharedSubscription,
  GetUserSubscription,
  fetchUserBasketSubs,
};

export default Endpoints;
