import React, { useState } from "react";
import { Auth } from "aws-amplify";
import { FormGroup, FormControl, FormLabel } from "react-bootstrap";

import { useFormFields } from "../../lib/hooksLib";
import { onError } from "../../lib/errorLib";
import getUsername from "../Account_Infomation/Username";
import "./ChangePassword.css";
import { useNavigate } from "react-router";

const ChangePassword = () => {
  const currentUser = getUsername();
  const navigate = useNavigate();
  const [fields, handleFieldChange] = useFormFields({
    password: "",
    oldPassword: "",
    confirmPassword: "",
  });
  const [isChanging, setIsChanging] = useState(false);

  function validateForm() {
    return (
      fields.oldPassword.length > 0 &&
      fields.password.length > 0 &&
      fields.password === fields.confirmPassword
    );
  }

  async function handleChangeClick(event) {
    // console.log("asdsad");
    event.preventDefault();

    setIsChanging(true);
    // console.log(currentUser, fields.oldPassword, fields.password);

    try {
      const currentUser = await Auth.currentAuthenticatedUser();
      await Auth.changePassword(
        currentUser,
        fields.oldPassword,
        fields.password
      );

      navigate("/settings");
    } catch (error) {
      onError(error);
      setIsChanging(false);
    }
  }

  return (
    <div className=" px-2  mx-2  rounded-lg  justify-items-center overflow-x-auto md:w-28 ">
      <div className="EditPassword">
        <form onSubmit={handleChangeClick}>
          <FormGroup bsSize="large" controlId="oldPassword">
            <FormLabel>Old Password</FormLabel>
            <FormControl
              type="password"
              onChange={handleFieldChange}
              value={fields.oldPassword}
              className="my-2 focus:outline-none placeholder-gray-600 shadow-lg text-sm p-2 cursor-pointer w-full rounded-lg bg-yellow-200"
            />
          </FormGroup>

          <FormGroup bsSize="large" controlId="password">
            <FormLabel>New Password</FormLabel>
            <FormControl
              type="password"
              onChange={handleFieldChange}
              value={fields.password}
              className="my-2 focus:outline-none placeholder-gray-600 shadow-lg text-sm p-2 cursor-pointer w-full rounded-lg bg-yellow-200"
            />
          </FormGroup>
          <FormGroup bsSize="large" controlId="confirmPassword">
            <FormLabel>Confirm Password</FormLabel>
            <FormControl
              type="password"
              onChange={handleFieldChange}
              value={fields.confirmPassword}
              className="my-2 focus:outline-none placeholder-gray-600 shadow-lg text-sm p-2 cursor-pointer w-full rounded-lg bg-yellow-200"
            />
            <div className="pb-2"></div>
          </FormGroup>
          {/* <LoaderButton
            block
            type="submit"
            bsSize="large"
            disabled={!validateForm()}
            isLoading={isChanging}
            className=" w-48 h-8 rounded-sm shadow-lg focus:outline-none cursor-pointer hover:bg-red-800 hover:text-white bg-gradient-to-r from-yellow-200 to-red-200 text-sm   flex-shrink"
          >
            Change Password
          </LoaderButton> */}
          <button
            onClick={handleChangeClick}
            className="mt-5 p-2 w-40 button cursor-pointer text-center hover:text-white flex items-center justify-center shadow-lg   hover:bg-red-800 focus:outline-none rounded-full h-8 bg-gradient-to-r from-yellow-200 to-red-200"
          >
            {" "}
            Change Password
          </button>
        </form>
      </div>
    </div>
  );
};

export default ChangePassword;
