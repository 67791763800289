import React, { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../../GlobalContext";
import IconComponent from "../../../Utility/IconComponent";
import Modal from "react-modal";
import AddDetailMessagePopup from "../../../AddDetailMessagePopup";
import AddChatMessagePopup from "../../../AddChatMessagePopup";
import getUsername from "../../../Account_Infomation/Username";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import getToken from "../../../Token";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "../../../../styles/styles.css";
import fernet from "fernet";
import Cards from "../../../Cards";
function SharedCard({ message }) {
  const { setSharedSubs } = useContext(GlobalContext);

  const { notsocket, chatRef, setChatRef } = useContext(GlobalContext);
  const username = getUsername();
  const [bundleData, setBundleData] = useState(null);
  const [subscriptionToggle, setSubscriptionToggle] = useState(
    message.Subscription_is_ended
  );
  function handleSubscription() {
    setSubscriptionToggle(!subscriptionToggle);
  }
  const [detailModalIsOpen, setDetailModalIsOpen] = useState(false);
  const [chatModalIsOpen, setChatModalIsOpen] = useState(false);
  const setDetailModalIsOpenToFalse = () => {
    setDetailModalIsOpen(false);
  };
  const setChatModalIsOpenToFalse = () => {
    setChatModalIsOpen(false);
  };

  const ViewDetails = (e) => {
    let accesskeytoken = getToken();
    const curUser = getUsername();
    fetch(
      `https://omnistack.org${process.env.REACT_APP_LINK}` + "viewuser-cred/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accesskeytoken,
        },
        body: JSON.stringify({
          user: curUser,
          SaturnTileRef: message.SaturnTileRef,
        }),
      }
    )
      .then((response) => response.json())
      .then((json) => {
        var secret = new fernet.Secret(process.env.REACT_APP_FERNET_KEY);
        var token = new fernet.Token({
          secret: secret,
          token: String(json),
          ttl: 0,
        });
        var result = token.decode();
        json = JSON.parse(result);

        setBundleData(json);
        setDetailModalIsOpen(true);
      });
  };

  return (
    <div>
      <div className=" border bg-gradient-to-t from-white to-yellow-50 border-yellow-100 p-2 m-2 rounded-lg order shadow-md justify-items-center ">
        <Cards tileData={message} credData={bundleData} />

        <div className="flex  gap-2 ">
          <div className="flex gap-2">
            <button
              className=" px-4 py-1  rounded-full shadow-sm focus:outline-none cursor-pointer bg-gradient-to-r from-yellow-200 to-red-200 text-xs font-semibold "
              onClick={ViewDetails}
            >
              Add Detail
            </button>
            <Modal
              isOpen={detailModalIsOpen}
              className=" max-w-lg z-50 mx-5 sm:mx-auto my-10 md:my-16 rounded-lg shadow-md text-center"
              overlayClassName="bg-black z-50 bg-opacity-30 fixed inset-0 overflow-y-auto"
              onRequestClose={() => setDetailModalIsOpen(false)}
            >
              <button
                onClick={setDetailModalIsOpenToFalse}
                className="flex justify-start px-2 pb-1 button rounded-lg shadow-sm focus:outline-none cursor-pointer bg-gradient-to-r from-yellow-200 to-red-200 text-sm   flex-shrink"
              >
                x
              </button>
              <AddDetailMessagePopup
                tileData={message}
                bundleDetails={bundleData}
                closeOnClick={setDetailModalIsOpenToFalse}
              />
            </Modal>

            <button
              className={` ${
                chatRef.includes(message.SaturnTileRef)
                  ? "bg-gradient-to-r from-yellow-500 to-red-500"
                  : "bg-gradient-to-r from-yellow-200 to-red-200"
              } px-4 py-1 button  rounded-full shadow-sm focus:outline-none cursor-pointer text-xs font-semibold `}
              onClick={() => {
                setChatModalIsOpen(true);
                let accesstoken = getToken();
                fetch(
                  `https://omnistack.org${process.env.REACT_APP_LINK}` +
                    "read-notifications-chat/",
                  {
                    method: "POST",
                    body: JSON.stringify({
                      user: username,
                      tileref: message.SaturnTileRef,
                    }),
                    headers: {
                      "Content-type": "application/json; charset=UTF-8",
                      Authorization: "Bearer " + accesstoken,
                    },
                  }
                )
                  .then((response) => response.json())
                  .then((json) => {
                    notsocket.send(
                      JSON.stringify({
                        user: username,
                      })
                    );
                    setChatRef(
                      chatRef.filter((item) => item !== message.SaturnTileRef)
                    );
                  });
              }}
            >
              Chat
            </button>
            <Modal
              isOpen={chatModalIsOpen}
              className=" max-w-lg z-50 mx-5 sm:mx-auto my-10 md:my-16 rounded-lg shadow-md text-center"
              overlayClassName="bg-black z-50 bg-opacity-30 fixed inset-0 overflow-y-auto"
              onRequestClose={() => setChatModalIsOpen(false)}
            >
              <button
                onClick={setChatModalIsOpenToFalse}
                className="flex justify-start px-2 pb-1 button rounded-lg shadow-sm focus:outline-none cursor-pointer bg-gradient-to-r from-yellow-200 to-red-200 text-sm   flex-shrink"
              >
                x
              </button>
              <AddChatMessagePopup
                tileData={message}
                closeOnClick={setChatModalIsOpenToFalse}
              />
            </Modal>
          </div>
          {!subscriptionToggle && (
            <button
              className=" px-4 py-1 button  rounded-full shadow-sm focus:outline-none cursor-pointer bg-gradient-to-r from-yellow-200 to-red-200 text-xs font-semibold "
              onClick={() => {
                let accesstoken = getToken();
                fetch(
                  `https://omnistack.org${process.env.REACT_APP_LINK}` +
                    "cancel-sub/",
                  {
                    method: "POST",
                    body: JSON.stringify({
                      user: getUsername(),
                      SaturnTileRef: message.SaturnTileRef,
                    }),
                    headers: {
                      "Content-type": "application/json; charset=UTF-8",
                      Authorization: "Bearer " + accesstoken,
                    },
                  }
                )
                  .then((response) => response.json())
                  .then((json) => {
                    toast(
                      JSON.stringify(json["Notification-text"]).replace(
                        /"|'/g,
                        ""
                      )
                    );
                    notsocket.send(
                      JSON.stringify({
                        user: username,
                      })
                    );
                  });
                handleSubscription();
              }}
            >
              <p className="">End Subscription</p>
            </button>
          )}
        </div>
        <div
          id="left contact"
          className="flex items-center justify-center mt-3 text-center p-1 align-center rounded-full   border-t border-b border-yellow-300 shadow-sm text-xs"
        >
          {"   "}
          {subscriptionToggle ? (
            <p>Subscription End on: {message.expiry_date}</p>
          ) : (
            <p>Next Billing Date : {message.next_billing_date}</p>
          )}
        </div>
      </div>
    </div>
  );
}

export default SharedCard;
