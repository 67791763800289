import React, { useEffect, useState } from "react";
import IconComponent from "../Utility/IconComponent";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import getUsername from "../Account_Infomation/Username";
import getToken from "../Token";
import basket from "../basket.svg";
import folder from "../folder.svg";
import home from "../home.svg";
import add from "../add.svg";
import search from "../search.svg";
import Cards from "../Cards";
const ShareFeed = (props) => {
  const [homeimage, setHomeImage] = useState(home);
  const [basketimage, setBasketImage] = useState(basket);
  const [addimage, setAddImage] = useState(add);
  const [folderimage, setFolderImage] = useState(folder);
  const [searchimage, setSearchImage] = useState(search);
  const [tilesubscribed, setTilesubscribed] = useState("no");
  let { tile } = useParams();
  const [tileData, setTileData] = useState({});
  const HandleClick = () => {
    window.location.href = "https://sponge-app.com/";
  };
  var link = `https://omnistack.org${process.env.REACT_APP_LINK}`;
  const HandleClick2 = () => {
    let accesstoken = getToken();
    fetch(link + "savedmessage-create2/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accesstoken,
      },
      body: JSON.stringify({
        user: getUsername(),
        subject: setTileData["subject"],
        body: setTileData["body"],
        author: setTileData["author"],
        currency: setTileData["currency"],
        SaturnTileRef: tile,
      }),
    });
  };

  useEffect(() => {
    fetch(link + "share-tile/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        SaturnTileRef: tile,
      }),
    })
      .then((res) => {
        return res.json();
      }) //res is url,id
      .then((res) => {
        setTileData(res);
        setTilesubscribed(res["tile-subscribed"]);
      });
  }, []);
  return (
    <React.Fragment>
      <div class="z-30 font-bellota w-full border-b border-gray-100 bg-gradient-to-b from-white to-yellow-100 ">
        <div class="md:mx-40 items-center  pt-2 flex flex-row justify-evenly">
          <div className="">
            <p
              className=" pt-2   align-center cursor-pointer  text-black  text-xs   "
              onClick={HandleClick}
            >
              <Link to="/home">
                {" "}
                <img src={homeimage} alt="my image" className="h-6 md:h-8 " />
                Home
              </Link>{" "}
            </p>
          </div>
          <div className="">
            <p
              className="pt-2 align-center cursor-pointer  hover:text-black  text-xs  "
              onClick={HandleClick}
            >
              <img src={addimage} alt="my image" className="h-6 md:h-8 " />
              Add
            </p>
          </div>
          {/*  pl-4 pr-4  mt-4 button h-8 rounded-full shadow-lg focus:outline-none cursor-pointer hover:bg-red-800 hover:text-white bg-gradient-to-r from-yellow-200 to-red-200 text-sm   flex-shrink */}
          {/* ml-56 focus:outline-none shadow-xl bg-gray-400 h-12 w-56    text-xl   rounded-full */}
          <div className="">
            <p className=" pt-2   align-center cursor-pointer  text-black   text-xs   ">
              <Link to="/my-posts">
                <img src={folderimage} alt="my image" className="h-6 md:h-8 " />
                Manage
              </Link>{" "}
            </p>
          </div>
          <div className="">
            <p className=" pt-2   align-center cursor-pointer  text-black   text-xs   ">
              <Link to="/basket">
                <img src={basketimage} alt="my image" className="h-6 md:h-8 " />
                Cart
              </Link>{" "}
            </p>
          </div>
        </div>
      </div>
      <h1 className="mt-5 px-8 py-3 text-2xl text-center bg-gradient-to-r from-yellow-200 to-red-200 rounded-full shadow-sm  font-bold max-w-md sm:mx-auto mx-2">
        Welcome to Sponge!
      </h1>

      <div className=" bg-gradient-to-t from-white to-yellow-50 border border-yellow-100 p-2 m-2 rounded-lg  shadow-md max-w-md sm:mx-auto mx-5 mt-10">
        {tilesubscribed === "deleted" ? (
          <div className="flex flex-col items-center justify-center">
            <p className="text-xs mx-2 p-2 text-center">
              We cannot seem to locate this subscription. It's probably because
              the author deleted it.
            </p>
            <button className="px-5 py-2   rounded-full shadow-lg focus:outline-none cursor-pointer bg-gradient-to-r from-yellow-200 to-red-200 text-sm   ">
              <Link to="/home">Find more subscription here</Link>!{" "}
            </button>
          </div>
        ) : (
          <>
            <div className="md:px-8">
              <Cards tileData={tileData} />
            </div>
            <div className="flex flex-row items-center justify-center ">
              <div className="py-2">
                {tilesubscribed === "no" && (
                  <button
                    onClick={HandleClick2}
                    className="  rounded-full shadow-lg focus:outline-none cursor-pointer bg-gradient-to-r from-yellow-200 to-red-200 text-sm  px-5 py-2 "
                  >
                    <Link to="/basket">Get this subscription</Link>!{" "}
                  </button>
                )}
                {tilesubscribed === "yes" && (
                  <div className="py-2">
                    <p className="text-lg py-3 text-center ">
                      Subscription already taken
                    </p>
                    <button className="  rounded-full shadow-lg focus:outline-none cursor-pointer  bg-gradient-to-r from-yellow-200 to-red-200 text-sm  px-5 py-1  ">
                      <Link to="/home">
                        You Can find new subscription from here!
                      </Link>
                      !{" "}
                    </button>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </React.Fragment>
  );
};
export default ShareFeed;
